import {
  EmptyPage,
  FilterResultsInfo,
  Filters,
  FiltersToggle,
  StyledChipHolder,
  StyledChip,
  StyledRadio
} from "pages/AudioBooks/style";
import React, { Component } from 'react';

import ApiService from '../../services/api.service';
import AudioBookListItem from '../../components/UI/AudioBook/AudioBookListItem';
import AudioBookSingleFilter from "../../components/UI/AudioBook/AudioBookSingleFilter";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import PageMeta from "shared/PageMeta/PageMeta";
import Pagination from '../../shared/Pagination/Pagination';
import SkeletonAudioBooks from "components/UI/Skeleton/SkeletonAudioBooks/SkeletonAudioBooks";
import TitleTextBlock from '../../shared/PageElements/TitleTextBlock';

export default class AudioBooks extends Component {
  constructor(props) {
    super(props);
    this.filtersWrapper = React.createRef();

    this.state = {
      audioBooks: [],
      styles: [],
      categories: [],
      locations: [],
      publishers: [],
      nationalities: [],
      activeStyles: [],
      activeCategories: [],
      activeLocations: [],
      activePublishers: [],
      activeNationalities: [],
      stylesFilter: [],
      categoriesFilter: [],
      locationsFilter: [],
      publishersFilter: [],
      nationalitiesFilter: [],
      lengthFilter: 0,
      itemsPerPage: 12,
      _page: new URLSearchParams(this.props.location.search).get('page') ? new URLSearchParams(this.props.location.search).get('page') : 1,
      numberOfBooks: 0,
      commonFilters: [],
      length: this.props.match.path.includes('audiobooks') ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : [100, 200, 300, 400, 500, 600, 700, 800, 900],
      filtersVisible: false,
      filtersHeight: null,
      isLoading: true,
      allFilters: [],
      type: '',
      isLoadingMore: false,
      isLoadMore: false,
      paginationMaxPages: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.getBooks();
    
    // Back Button scroll to top
    window.onpopstate = function(event) {
      setTimeout(() => {
        window.scrollTo(0,0);
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      if (!this.state.isLoadMore) {
        this.setState({
          allFilters: [],
        }, () => {
          this.getBooks();
        })
      }
    }
  }

  getBooks = () => {
    /**
     * Get all filters from URL
     */
     const searchParams = new URLSearchParams(this.props.history.location.search);
     const pageURLSearchParams = new URLSearchParams()
     pageURLSearchParams.append('itemsPerPage', 200);
     if (this.props.match.path.includes('audiobooks')) {
       this.setState({
         type: 'audiobooks'
       })
       pageURLSearchParams.append('related', 'audioBooks');
     } else {
       this.setState({
         type: 'ebooks'
       })
       pageURLSearchParams.append('related', 'eBooks');
     }
     /**
      * Set items per page for filters
      */
 
     let allFilters = [...this.state.allFilters]
     let hours = searchParams.get('lengthHours[gte]') ? parseInt(searchParams.get('lengthHours[gte]'), 10) : searchParams.get('lengthHours[lte]') ? parseInt(searchParams.get('lengthHours[lte]'), 10) : 0
     let pages = searchParams.get('pages[gte]') ? parseInt(searchParams.get('pages[gte]'), 10) : searchParams.get('pages[lte]') ? parseInt(searchParams.get('pages[lte]'), 10) : 0
     if (hours > 0) {
       allFilters.push(hours)
     }
 
     if (pages > 0) {
       allFilters.push(pages);
     }
     this.setState({
       _page: new URLSearchParams(this.props.location.search).get('page') ? new URLSearchParams(this.props.location.search).get('page') : 1,
       itemsPerPage: searchParams.get('itemsPerPage') ? searchParams.get('itemsPerPage') : 12,
       stylesFilter: searchParams.get('styles[]') ? searchParams.get('styles[]').split(',').map(item => {return parseInt(item, 10)}) : [],
       categoriesFilter: searchParams.get('categories[]') ? searchParams.get('categories[]').split(',').map(item => {return parseInt(item, 10)}) : [],
       locationsFilter: searchParams.get('locations[]') ? searchParams.get('locations[]').split(',').map(item => {return parseInt(item, 10)}) : [],
       publishersFilter: searchParams.get('publishers[]') ? searchParams.get('publishers[]').split(',').map(item => {return parseInt(item, 10)}) : [],
       nationalitiesFilter: searchParams.get('nationalities[]') ? searchParams.get('nationalities[]').split(',').map(item => {return parseInt(item, 10)}) : [],
       lengthFilter: searchParams.get('lengthHours[gte]') ? parseInt(searchParams.get('lengthHours[gte]'), 10) : searchParams.get('lengthHours[lte]') ? parseInt(searchParams.get('lengthHours[lte]'), 10) : searchParams.get('pages[gte]') ? searchParams.get('pages[gte]') : 0,
       allFilters: allFilters,
     }, () => {
       this.filterApi(true);
     })
     /**
      * Get styles filter
      */
     ApiService.styles(pageURLSearchParams).then((r) => {
       this.setState({
         styles: r.data["hydra:member"],
       }, () => {
         //fill state and active state with style 
         if (searchParams.get('styles[]')) {
           const categories = this.state.styles;
           const actCategories = [];
           searchParams.get('styles[]').split(',').forEach(id => {
             let filteredCat = categories.filter(cat => cat.id === parseInt(id))
             let index = categories.indexOf(filteredCat[0])
             let allFilt = this.state.allFilters;
             allFilt.push(filteredCat[0]);
             actCategories.push(index)
             this.setState({
               activeStyles: actCategories,
               allFilters: allFilt
             })
           })
         }
       });
     });
 
     /**
      * Get category filters
      */
     ApiService.categories(pageURLSearchParams).then((r) => {
       this.setState({
         categories: r.data["hydra:member"],
       }, () => {
         //fill state and active state with category 
         if (searchParams.get('categories[]')) {
           const categories = this.state.categories;
           const actCategories = [];
           searchParams.get('categories[]').split(',').forEach(id => {
             let filteredCat = categories.filter(cat => cat.id === parseInt(id))
             let index = categories.indexOf(filteredCat[0])
             let allFilt = this.state.allFilters;
             allFilt.push(filteredCat[0]);
             actCategories.push(index)
             this.setState({
               activeCategories: actCategories,
               allFilters: allFilt
             })
           })
         }
       });
     });
 
     /**
      * Get location filter
      */
     ApiService.locations(pageURLSearchParams).then((r) => {
       this.setState({
         locations: r.data["hydra:member"],
       }, () => {
         //fill state and active state with locations 
         if (searchParams.get('locations[]')) {
           const categories = this.state.locations;
           const actCategories = [];
           searchParams.get('locations[]').split(',').forEach(id => {
             let filteredCat = categories.filter(cat => cat.id === parseInt(id))
             let index = categories.indexOf(filteredCat[0])
             let allFilt = this.state.allFilters;
             allFilt.push(filteredCat[0]);
             actCategories.push(index)
             this.setState({
               activeLocations: actCategories,
               allFilters: allFilt
             })
           })
         }
       });
     });
 
     /**
      * Get publishers filter
      */
     ApiService.publishers(pageURLSearchParams).then((r) => {
       this.setState({
         publishers: r.data["hydra:member"],
       }, () => {
         //fill state and active state with publisher 
         if (searchParams.get('publishers[]')) {
           const categories = this.state.publishers;
           const actCategories = [];
           searchParams.get('publishers[]').split(',').forEach(id => {
             let filteredCat = categories.filter(cat => cat.id === parseInt(id))
             let index = categories.indexOf(filteredCat[0])
             let allFilt = this.state.allFilters;
             allFilt.push(filteredCat[0]);
             actCategories.push(index)
             this.setState({
               activePublishers: actCategories,
               allFilters: allFilt
             })
           })
         }
       });
     });
 
     /**
      * Get nationality filter - ONLY FOR AUDIOBOOKS
      */
     if (this.props.match.path.includes('audiobooks')) {
       if (this.props.match.path.includes('audiobooks')) {
         ApiService.nationalities(pageURLSearchParams).then((r) => {
           this.setState({
             nationalities: r.data["hydra:member"],
           }, () => {
             //fill state and active state with nationality 
             if (searchParams.get('nationalities[]')) {
               const categories = this.state.nationalities;
               const actCategories = [];
               searchParams.get('nationalities[]').split(',').forEach(id => {
                 let filteredCat = categories.filter(cat => cat.id === parseInt(id))
                 let index = categories.indexOf(filteredCat[0])
                 let allFilt = this.state.allFilters;
                 allFilt.push(filteredCat[0]);
                 actCategories.push(index)
                 this.setState({
                   activeNationalities: actCategories,
                   allFilters: allFilt
                 })
               })
             }
           });
         });  
       }
     }
  }


  /**
   * API call for audiobooks with filters
   */  
  filterApi = (pagination) => {
    this.setState({
      // isLoading: true,
      isLoadingMore: true
    }, () => {
      const searchParams = new URLSearchParams();

      let page = 1;

      if (pagination) {
        page = this.state._page
      }

      searchParams.append("page", page);
      searchParams.append("itemsPerPage", this.state.itemsPerPage);
      if (this.state.stylesFilter.length) {
        searchParams.append("styles[]", this.state.stylesFilter);
      }
      if (this.state.categoriesFilter.length) {
        searchParams.append("categories[]", this.state.categoriesFilter);
      }
      if (this.state.locationsFilter.length) {
        searchParams.append("locations[]", this.state.locationsFilter);
      }
      if (this.state.publishersFilter.length) {
        searchParams.append("publishers[]", this.state.publishersFilter);
      }
      if (this.state.nationalitiesFilter.length) {
        searchParams.append("nationalities[]", this.state.nationalitiesFilter);
      }
      if (this.state.lengthFilter !== 0) {
        if (this.state.type === "audiobooks") {
          if (this.state.lengthFilter === 9) {
            searchParams.append("lengthHours[lte]", this.state.lengthFilter);
          } else {
            searchParams.append("lengthHours[gte]", this.state.lengthFilter);
          }
        } else {
          searchParams.append("pages[gte]", this.state.lengthFilter);
        }
      }
      this.props.history.replace(`/${this.state.type}?${searchParams.toString()}`)
      ApiService.audioBooks(searchParams, this.state.type).then((r) => {
          this.setState(
            {
              audioBooks: r.data["hydra:member"],
              numberOfBooks: r.data["hydra:totalItems"],
              isLoading: false,
              isLoadingMore: false,
              isLoadMore: false,
              paginationMaxPages: Math.ceil(r.data["hydra:totalItems"] / this.state.itemsPerPage)
            }, () => {
              if (window.dataLayer) {
                const items = [];
                this.state.audioBooks.map((book, index) => {
                  const locations = []
                  if (book.locations && book.locations.length) {
                    book.locations.map((location) => {
                      return locations.push(location.title)
                    })
                  }
                  const tags = []
                  if (book.tags && book.tags.length) {
                    book.tags.map((tag) => {
                      return tags.push(tag.title)
                    })
                  }
                  const authors = []
                  if (book.authors && book.authors.length) {
                    book.authors.map((author) => {
                      return authors.push(author.fullName)
                    })
                  }
                  const narrators = []
                  if (book.narrators && book.narrators.length) {
                    book.narrators.map((narrator) => {
                      return narrators.push(`${narrator.firstName} ${narrator.lastName}`)
                    })
                  }

                  return items.push({
                    item_id: book.slug,
                    item_name: book.title,
                    item_brand: book.publisher ? book.publisher?.name : '',
                    item_category: book.category ? book.category?.name : '',
                    item_locations: locations?.length ? locations.join(', ') : '',
                    item_tags: tags?.length ? tags.join(', ') : '',
                    item_price: book.price,
                    item_author: authors?.length ? authors.join(', ') : '',
                    item_narrator: narrators?.length ? narrators.join(', ') : '',
                    item_translator: book.translator ? `${book.translator.firstName} ${book.translator.lastName}` : '',
                    item_length: `${book.lengthHours}:${book.lengthMin}`,
                  })
                })

                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push({
                    event: "view_item_list",
                    ecommerce: {
                        item_list_id: "spiracle_collection",
                        item_list_name: "Spiracle Collection",
                        items: items
                    }
                });
              }
            }
          );
      });
    })
  };


  /**
   * Load more books
   */  
  loadMore = (data) => {
    this.setState({
      _page: data.selected + 1,
      isLoadingMore: true,
      isLoadMore: true,
    }, () => {
      this.filterApi(true);
    });
  };

  /**
   * Handle click on filter options
   */  
  handleFilter = (type, i, filterOption) => {
    this.setState({
      isLoadMore: true
    }, () => {
      let typeArr = [];
      let filtArr = [];
      let allFilters = this.state.allFilters;
      
      //set typeArr and filtArr depending on type
      if (type === "style") {
        typeArr = this.state.activeStyles;
        filtArr = this.state.stylesFilter;
      } else if (type === "categorie") {
        typeArr = this.state.activeCategories;
        filtArr = this.state.categoriesFilter;
      } else if (type === "location") {
        typeArr = this.state.activeLocations;
        filtArr = this.state.locationsFilter;
      } else if (type === "publisher") {
        typeArr = this.state.activePublishers;
        filtArr = this.state.publishersFilter;
      } else if (type === "nationalitie") {
        typeArr = this.state.activeNationalities;
        filtArr = this.state.nationalitiesFilter;
      }
  
      //if options already exists then remove it
      if (filtArr.includes(filterOption.id)) {
        const index = filtArr.indexOf(filterOption.id);
        const newArr = [...filtArr];
        const allFiltersIndex = allFilters.indexOf(filterOption);
        newArr.splice(index, 1);
        allFilters.splice(allFiltersIndex, 1);
        this.setState({
          allFilters: allFilters
        })
        if (type === "style") {
          this.setState({ stylesFilter: newArr }, () => {
            this.filterApi();
          });
        } else if (type === "categorie") {
          this.setState({ categoriesFilter: newArr }, () => {
            this.filterApi();
          });
        } else if (type === "location") {
          this.setState({ locationsFilter: newArr }, () => {
            this.filterApi();
          });
        } else if (type === "publisher") {
          this.setState({ publishersFilter: newArr }, () => {
            this.filterApi();
          });
        } else if (type === "nationalitie") {
          this.setState({ nationalitiesFilter: newArr }, () => {
            this.filterApi();
          });
        }
      } else {
        //if options doesn't exist, push to all filters and typeFilter
        const newArr = [...filtArr];
        newArr.push(filterOption.id);
        allFilters.push(filterOption);
        this.setState({
          allFilters: allFilters
        })
  
        if (type === "style") {
          this.setState({ stylesFilter: newArr }, () => {
            this.filterApi();
          });
        } else if (type === "categorie") {
          this.setState({ categoriesFilter: newArr }, () => {
            this.filterApi();
          });
        } else if (type === "location") {
          this.setState({ locationsFilter: newArr }, () => {
            this.filterApi();
          });
        } else if (type === "publisher") {
          this.setState({ publishersFilter: newArr }, () => {
            this.filterApi();
          });
        } else if (type === "nationalitie") {
          this.setState({ nationalitiesFilter: newArr }, () => {
            this.filterApi();
          });
        }
      }
      if (typeArr.includes(i)) {
        //active state push if it doesn't exist
        const index = typeArr.indexOf(i);
        const newArr = [...typeArr];
        newArr.splice(index, 1);
        if (type === "style") {
          this.setState({ activeStyles: newArr, _page: 1 });
        } else if (type === "categorie") {
          this.setState({ activeCategories: newArr, _page: 1 });
        } else if (type === "location") {
          this.setState({ activeLocations: newArr, _page: 1 });
        } else if (type === "publisher") {
          this.setState({ activePublishers: newArr, _page: 1 });
        } else if (type === "nationalitie") {
          this.setState({ activeNationalities: newArr, _page: 1 });
        }
      } else {
        //active state push if it doesn't exist
        const newArr = [...typeArr];
        newArr.push(i);
        if (type === "style") {
          this.setState({ activeStyles: newArr, _page: 1 });
        } else if (type === "categorie") {
          this.setState({ activeCategories: newArr, _page: 1 });
        } else if (type === "location") {
          this.setState({ activeLocations: newArr, _page: 1 });
        } else if (type === "publisher") {
          this.setState({ activePublishers: newArr, _page: 1 });
        } else if (type === "nationalitie") {
          this.setState({ activeNationalities: newArr, _page: 1 });
        }
      }
    })
  };

  /**
   * Remove filter clicking on list of filters
   */  
  handleRemoveFilter = item => {
    this.setState({
      isLoadMore: true,
      _page: 1,
    }, () => {
      const type = item['@type'];
      let typeArr = [];
      let filtArr = [];
      let allArr = [];
      let allFilters = this.state.allFilters;
      let filteredArr;
      if (type === 'Category') {
        typeArr = this.state.activeCategories;
        filtArr = this.state.categoriesFilter;
        allArr = this.state.categories;
      } else if (type === 'Style') {
        typeArr = this.state.activeStyles;
        filtArr = this.state.stylesFilter;
        allArr = this.state.styles;
      } else if (type === 'Nationality') {
        typeArr = this.state.activeNationalities;
        filtArr = this.state.nationalitiesFilter;
        allArr = this.state.nationalities;
      } else if (type === 'Publisher') {
        typeArr = this.state.activePublishers;
        filtArr = this.state.publishersFilter;
        allArr = this.state.publishers;
      } else if (type === 'Location') {
        typeArr = this.state.activeLocations;
        filtArr = this.state.locationsFilter;
        allArr = this.state.locations
      }
  
      filteredArr = allArr.filter(filteredItem => filteredItem.id === item.id)
      const index = allArr.indexOf(filteredArr[0])
      const index1 = filtArr.indexOf(item.id)
      const index2 = allFilters.indexOf(item);
      const typeArrIndex = typeArr.indexOf(index)
      typeArr.splice(typeArrIndex, 1);
      filtArr.splice(index1, 1);
      allFilters.splice(index2, 1);
  
  
      if (type === 'Category') {
        this.setState({
          activeCategories: typeArr,
          categoriesFilter: filtArr,
          allFilters: allFilters
        }, () => {
          this.filterApi()
        })
        
      } else if (type === 'Style') {
        this.setState({
          activeStyles: typeArr,
          stylesFilter: filtArr,
          allFilters: allFilters
        }, () => {
          this.filterApi()
        })
        
      } else if (type === 'Nationality') {
        this.setState({
          activeNationalities: typeArr,
          nationalitiesFilter: filtArr,
          allFilters: allFilters
        }, () => {
          this.filterApi()
        })
        
      } else if (type === 'Publisher') {
        this.setState({
          activePublishers: typeArr,
          publishersFilter: filtArr,
          allFilters: allFilters
        }, () => {
          this.filterApi()
        })
        
      } else if (type === 'Location') {
        this.setState({
          activeLocations: typeArr,
          locationsFilter: filtArr,
          allFilters: allFilters
        }, () => {
          this.filterApi()
        })
      } else {
        this.setState({
          allFilters: allFilters,
          lengthFilter: 0
        }, () => {
          this.filterApi()
        })
      }
    })
  }

  /**
   * Reset all filters
   */  
  handleClearAllFilters = () => {
    this.setState({
      locationsFilter: [],
      activeLocations: [],
      publishersFilter: [],
      activePublishers: [],
      activeNationalities: [],
      nationalitiesFilter: [],
      stylesFilter: [],
      categoriesFilter: [],
      activeCategories: [],
      activeStyles: [],
      allFilters: [],
      lengthFilter: 0
    }, () => {
      this.filterApi();
    })
  }

  /**
   * Handle click on style filter
   */  
  handleStyleFilter = (style, i) => {
    this.handleFilter("style", i, style);
  };

  /**
   * Handle click on category filter
   */  
  handleCategorieFilter = (categorie, i) => {
    this.handleFilter("categorie", i, categorie);
  };

  /**
   * Handle click on location filter
   */  
  handleLocationsFilter = (location, i) => {
    this.handleFilter("location", i, location);
  };

  /**
   * Handle click on publisher filter
   */  
  handlePublishersFilter = (publisher, i) => {
    this.handleFilter("publisher", i, publisher);
  };

  /**
   * Handle click on author nationality filter
   */  
  handleNationalitiesFilter = (nationalitie, i) => {
    this.handleFilter("nationalitie", i, nationalitie);
  };

  /**
   * Handle radio change on length filter
   */  
  handleRadioChange = (length) => {
    this.setState({ 
      lengthFilter: this.state.lengthFilter === length ? 0 : length 
    }, () => {
      const allFilters = [...this.state.allFilters]
      const index = allFilters.indexOf(this.state.lengthFilter);
      let newArr = []
      if (index !== -1) {
        newArr = allFilters.filter( x => isNaN(x) );
      } else {
        newArr = allFilters.filter( x => isNaN(x) );
        if (this.state.lengthFilter !== 0) {
          newArr.push(this.state.lengthFilter);
        }
      }
      this.setState({
        allFilters: newArr
      }, () => {
        this.filterApi();
      })
    });
  };

  /**
   * Handle toggle filters
   */  
  toggleFilters = () => {
    this.setState({ filtersVisible: !this.state.filtersVisible });
  };

  
  render() {
    let audioBooks;
    let styles;
    let categories;
    let locations;
    let publishers;
    let nationalities;
    let length;
    let allFilters;

    if (this.state.styles.length) {
      styles = this.state.styles.map((style, index) => {
        return (
            <StyledChipHolder key={`style-${index}`}>
                <StyledChip                    
                    className={this.state.activeStyles.includes(index) ? "active" : ""}            
                    onClick={() => this.handleStyleFilter(style, index, style.id)}
                >
                    {style.title}
                </StyledChip>
            </StyledChipHolder>
        );
      });
    }

    if (this.state.categories.length) {
      categories = this.state.categories.map((categorie, index) => {
        return (
            <StyledChipHolder key={`categorie-${index}`}>
                <StyledChip                   
                    className={
                        this.state.activeCategories.includes(index) ? "active" : ""
                    }            
                    onClick={() =>
                        this.handleCategorieFilter(categorie, index, categorie.id)
                    }
                >
                    {categorie.name}
                </StyledChip>
          </StyledChipHolder>
        );
      });
    }

    if (this.state.locations.length) {
      locations = this.state.locations.map((locations, index) => {
        return (
            <StyledChipHolder key={`location-${index}`}>
                <StyledChip                   
                    className={
                        this.state.activeLocations.includes(index) ? "active" : ""
                    }            
                    onClick={() =>
                        this.handleLocationsFilter(locations, index, locations.id)
                    }
                >
                    {locations.title}
                </StyledChip>
          </StyledChipHolder>
        );
      });
    }

    if (this.state.publishers.length) {
      publishers = this.state.publishers.map((publishers, index) => {
        return (
            <StyledChipHolder key={`publisher-${index}`}>
                <StyledChip                    
                    className={
                        this.state.activePublishers.includes(index) ? "active" : ""
                    }            
                    onClick={() =>
                        this.handlePublishersFilter(publishers, index, publishers.id)
                    }
                >
                    {publishers.name}
                </StyledChip>
          </StyledChipHolder>
        );
      });
    }

    if (this.state.nationalities.length) {
      nationalities = this.state.nationalities.map((nationalities, index) => {
        return (
            <StyledChipHolder key={`nationalitie-${index}`}>
                <StyledChip
                    className={
                        this.state.activeNationalities.includes(index) ? "active" : ""
                    }           
                    onClick={() =>
                        this.handleNationalitiesFilter(
                            nationalities,
                            index,
                            nationalities.id
                        )
                    }
                >
                    {nationalities.title}
                </StyledChip>
            </StyledChipHolder>
        );
      });
    }

    if (this.state.length.length) {
      length = this.state.length.map((length, index) => {
        return (
            <StyledChipHolder key={`length-${index}`}>
                <StyledRadio>
                    <input
                        id={`length-${length}`}
                        type="checkbox"
                        name="length"
                        value={length}
                        checked={this.state.lengthFilter === length}
                        onChange={(e) => this.handleRadioChange(length)}
                    />

                    <label htmlFor={`length-${length}`}>
                        {length === 9 ? `<` : `>`} {length} {length === 1 ? "hr" : this.state.type === "audiobooks" ? "hrs" : "pages"}
                    </label>
                </StyledRadio>
            </StyledChipHolder>
        );
      });
    }

    if (this.state.audioBooks.length) {
      audioBooks = this.state.audioBooks.map((book, index) => {
        return (
          <Grid item xs={6} md={4} lg={3} key={`audioBook-${index}`}>
            <AudioBookListItem
              title={book.title}
              imgSrc={book.cover}
              imgAlt={book.coverAlt}
              price={book.price}
              subscriptionPrice={book.subscriptionPrice}
              authors={book.authors}
              type={this.state.type}
              audioBook={book.audioBook}
              eBook={book.eBook}
              link={`/${this.state.type === 'audiobooks' ? 'audiobooks' : 'ebooks'}/${book.slug}`}
              backButton
              availableAsCard={book.availableAsCard}
            />
          </Grid>
        );
      });
    }

    if (this.state.allFilters.length) {
      allFilters = this.state.allFilters.map((item, index) => {
        if (item) {
          return (
            <StyledChip
              labelWithIcon
              detached
              key={`filter-${index}`}
              onClick={() => this.handleRemoveFilter(item)}
            >
              {item.title ? item.title : item.name ? item.name : `${item === 9 ? '<' : '>'} ${item} hrs `}
              <i className="icon-close-small no-animation" />
            </StyledChip>
          );
        } else {
          return null
        }
      })
    }

    return (
      <div className="container container__big container__big--withPadding">
        <PageMeta>
            <title>{this.state.type === "audiobooks" ? 'Audiobooks' : 'Ebooks'} | Spiracle</title>
            <link rel="canonical" href={window.location.href} />
        </PageMeta>
        {this.state.isLoading ? (
          <>
            <TitleTextBlock 
              audiobooks
              murmurations
              fontItalic  
              title="Collection"
              text={<>Modern and contemporary audiobooks; selected <span className="font-italic">Readymades</span> from different publishers; updated and refreshed regularly. </>}
              subtext={<><span className="font-italic">Books always speak of other books &#8212;</span> Umberto Eco</>}
            /> 
            <SkeletonAudioBooks />
          </>
        ) : (
          <>
            {this.state.styles.length || this.state.categories.length || this.state.locations.length || this.state.nationalities.length || this.state.publishers.length > 0 ?
                <>
                    <FiltersToggle>
                        {!this.state.filtersVisible ? (
                            <StyledChip small detached onClick={() => this.toggleFilters()}>
                            Filter
                            </StyledChip>
                        ) : (
                            <>
                                <Hidden mdUp>
                                    <span className="pl-42">Filter</span>
                                </Hidden>
                                <i
                                className="icon-close mr-24"
                                style={{ verticalAlign: "bottom" }}
                                onClick={() => this.toggleFilters()}
                                />
                            </>
                        )}
                    </FiltersToggle>
                    <Filters
                        ref={this.filtersWrapper}
                        className={this.state.filtersVisible ? "visible" : "hidden"}
                        height={this.state.filtersHeight}                
                    >
                        <div className="filters-wrapper">                    
                            {styles && (
                                <>
                                    <h2>Style</h2>
                                    <AudioBookSingleFilter
                                        filtersId={'stylesContainer'}
                                        filters={styles}                    
                                    />
                                </>
                            )}

                            
                            {categories && (
                                <>
                                    <h2>Category</h2>
                                    <AudioBookSingleFilter
                                        filtersId={'categoriesContainer'}
                                        filters={categories}                    
                                    />
                                </>
                            )}

                            
                            {locations && (
                                <>
                                    <h2>Geographical setting</h2>
                                    <AudioBookSingleFilter
                                        filtersId={'locationsContainer'}
                                        filters={locations}                    
                                    />
                                </>
                            )}

                            
                            {length && (
                                <>
                                    <h2>{this.state.type === "ebooks" ? 'PAGES' : 'LENGTH'}</h2>
                                    <AudioBookSingleFilter
                                        filtersId={'lengthsContainer'}
                                        filters={length}                    
                                    />
                                </>
                            )}

                            {this.state.type === "audiobooks" ? 
                            <>
                                
                                {nationalities  && (
                                    <>
                                        <h2>Author nationality</h2>
                                        <AudioBookSingleFilter
                                            filtersId={'nationalitiesContainer'}
                                            filters={nationalities}                        
                                        />
                                    </>
                                )} 
                            </>
                            :
                            null }

                            
                            {publishers && (
                                <>
                                    <h2>Publisher</h2>
                                    <AudioBookSingleFilter
                                        filtersId={'publishersContainer'}
                                        filters={publishers}                   
                                    />
                                </>
                            )}
                        </div>
                    </Filters>
                </>
                :
                null            
            }
            {this.state.allFilters.length > 0 && this.state.allFilters[0] !== undefined && <FilterResultsInfo>
                
                    <>
                        <span className={`font-13 font-uppercase ${(this.state.numberOfBooks > 0 || window.innerWidth < 768) && 'mr-25'} font-secondary letter-spacing-15`}>
                        {this.state.isLoadingMore ? <span style={{display: 'inline-block', width: '55px'}} className="c-skeleton__audio-book--listing--bookNumber"></span> : 
                            this.state.audioBooks?.length > 0 ? (this.state.numberOfBooks === 1
                                ? this.state.numberOfBooks + " Book"
                                : this.state.numberOfBooks + " Books"
                              )
                            : null
                        }
                        </span>
                        
                        {this.state.allFilters.length > 0 &&
                        <StyledChip onClick={() => this.handleClearAllFilters()} circle detached className="mr-12" style={{marginLeft: '0'}}> 
                            <i className="icon-close-small no-animation" />
                        </StyledChip>
                        }
                    
                        {allFilters}
                    </>
            </FilterResultsInfo>}

            <TitleTextBlock 
              audiobooks
              murmurations
              fontItalic  
              title="Collection"
              text={<>Modern and contemporary audiobooks; selected <span className="font-italic">Readymades</span> from different publishers; updated and refreshed regularly. </>}
              subtext={<><span className="font-italic">Books always speak of other books &#8212;</span> Umberto Eco</>}
            /> 

            {this.state.isLoadingMore ? <SkeletonAudioBooks /> :

            this.state.numberOfBooks && this.state.audioBooks?.length > 0 ? 
              <Grid container spacing={2}>
                {audioBooks}
              </Grid> 
              : 
              <EmptyPage>
                <p>Sorry, no results.</p>
                <p>
                  Try again or visit our{" "}
                  <Link id='gtm-audiobooks-visit-collection' to="/audiobooks" className="font-secondary">
                    collection
                  </Link>
                </p>
              </EmptyPage>
            }

            {this.state.paginationMaxPages > 1 && this.state.audioBooks?.length > 0 ? 
              <Pagination
                page={parseInt(this.state._page)} 
                onPageChange={(data) => this.loadMore(data)} 
                pageCount={this.state.paginationMaxPages}
             /> 
              : null
            }
          </>
        )}
      </div>
    );
  }
}
