import * as Yup from "yup";
import * as actionTypes from "../../store/actions/userActions";
import * as cartActionTypes from '../../store/actions/cartActions';
import * as libraryActionTypes from '../../store/actions/libraryActions';

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";

import ApiService from "../../services/api.service";
import ReactFlagsSelect from 'react-flags-select';
import { ReactComponent as SpiracleLogoSmall } from "../../images/Spiral.svg";
import { connect } from "react-redux";
import cookie from 'react-cookies';
import { withRouter } from "react-router-dom";

class Auth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginOpen: false,
            registerOpen: false,
            forgotPasswordOpen: false,
            registrationSuccessful: false,
            recoverPassword: false,
            loginError: false,
            passwordEye: true,
            passwordLoginEye: true,
            registerError: false,
            forgotPasswordError: false,
            countryCode: '',
        };
    }

    /**
     * Handle toggle password eye
     */
    togglePassword = () => {
        this.setState({
            passwordEye: !this.state.passwordEye,
        });
    };

    /**
     * Handle toggle login password eye
     */
    toggleLoginPassword = () => {
        this.setState({
            passwordLoginEye: !this.state.passwordLoginEye,
        });
    };

    /**
     * Handle disable scroll on mobile
     */
    noMobileScroll = () => {
        if (window.innerWidth <= 786) {
            document.body.classList.add("noScroll");
        }
    };

    /**
     * click outside popups
     */
    componentDidMount() {
        document.addEventListener('click', e => {
            if (!e.target.classList.contains('icon-login') && !e.target.closest('.c-authentication')) {
                this.setState({
                    forgotPasswordOpen: false,
                    registerOpen: false,
                    loginOpen: false,
                    recoverPassword: false,
                })
            }
        })
    }

    componentDidUpdate = (prevProps, previousState) => {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.handleCloseModal();
        }
        if (prevProps !== this.props) {
            if (this.props.openAuth) {
                this.handleOpenLogin();
                /**
                 * Disable scroll on mobile
                 */
                this.noMobileScroll();
            }            
        }
    };

    /**
     * Handle login modal
     */
    handleOpenLogin = (resetForm) => {
        this.setState(
            {
                ...this.state,
                loginOpen: true,
                registerOpen: false,
                forgotPasswordOpen: false,
            },
            () => {
                if (resetForm) {
                    resetForm();
                }
                this.noMobileScroll();
            }
        );
    };

    /**
     * Handle open register modal
     */
    handleOpenRegister = (resetForm) => {
        this.setState(
            {
                ...this.state,
                registerOpen: true,
                loginOpen: false,
                forgotPasswordOpen: false,
            },
            () => {
                if (resetForm) {
                    resetForm();
                }
                this.props.closeLoginModal();
                this.noMobileScroll();
            }
        );
    };

    /**
     * Handle open forgot password modal
     */
    handleOpenForgotPassword = (resetForm) => {
        this.setState(
            {
                ...this.state,
                forgotPasswordOpen: true,
                registerOpen: false,
                loginOpen: false,
            },
            () => {
                if (resetForm) {
                    resetForm();
                }
                this.props.closeLoginModal();
                this.noMobileScroll();
            }
        );
    };

    /**
     * Handle close modal
     */
    handleCloseModal = (resetForm) => {
        this.setState(
            {
                ...this.state,
                forgotPasswordOpen: false,
                registerOpen: false,
                loginOpen: false,
                loginError: false,
                passwordEye: true,
                passwordLoginEye: true,
                recoverPassword: false,
            },
            () => {
                if (resetForm) {
                    resetForm();
                }
                this.props.closeLoginModal();
                document.body.classList.remove("noScroll");
            }
        );
    };

    /**
     * Handle login form submit
     */
    submitLoginForm = (values, callbacks) => {
        this.setState({ loginError: false }, () => {
            const data = {
                email: values.loginEmail,
                password: values.loginPassword,
            };
            const { setSubmitting } = callbacks;

            ApiService.login(data)
                .then((r) => {
                    localStorage.setItem("token", r.data.token);
                    localStorage.setItem("refresh_token", r.data.refresh_token);
                    ApiService.getUser().then((r) => {
                        const isOutlet = r.data.outlet;

                        if (isOutlet) {
                            this.props.onSetUser(r.data);
                            this.props.history.push('/activation');
                    } else {
                        let cartId = cookie.load('cartId')
                        if (cartId) {
                            const data = {
                                cartUuid: cartId
                            }
                            ApiService.updateCartId(r.data.uuid, data).then(
                                r => {
                                    this.props.onSetUser(r.data);
                                    this.handleCloseModal();

                                    ApiService.getCartItems(cartId).then(
                                        cart => {
                                          this.props.onGetCartItems(cart.data['hydra:member'])
                                          ApiService.getLibrary().then(
                                            library => {
                                                this.setState({
                                                    isLoading: false
                                                }, () => {
                                                  this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                                  const href = window.location.href
                                                  if (href.includes('sign-up') || href.includes('login') || href.includes('forgot-password') || href.includes('reset-password') || href.includes('confirm')) {
                                                    //push to settings page if user
                                                    //logs in on any of the auth pages  
                                                    this.props.history.push('/settings')
                                                  }
                                              })
                                            }
                                        )
                                        }
                                    )
                                }
                            )
                        } else {
                            if (r.data.cart && r.data.cart.uuid) {
                                ApiService.getCartItems(r.data.cart.uuid).then(
                                    cart => {
                                        this.props.onGetCartItems(cart.data['hydra:member'])
                                        ApiService.getLibrary().then(
                                            library => {
                                              this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                              this.setState({
                                                isLoading: false
                                              }, () => {
                                                this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                                const href = window.location.href
                                                if (href.includes('sign-up') || href.includes('login') || href.includes('forgot-password') || href.includes('reset-password') || href.includes('confirm')) {
                                                    //push to settings page if user
                                                    //logs in on any of the auth pages  
                                                    this.props.history.push('/settings')
                                                  }
                                              })
                                            }
                                        )
                                    }
                                )
                            } else {
                                ApiService.getLibrary().then(
                                    library => {
                                      this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                      this.setState({
                                        isLoading: false
                                      }, () => {
                                        this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                        const href = window.location.href
                                        if (href.includes('sign-up') || href.includes('login') || href.includes('forgot-password') || href.includes('reset-password') || href.includes('confirm')) {
                                            //push to settings page if user
                                            //logs in on any of the auth pages  
                                            this.props.history.push('/settings')
                                          }
                                      })
                                    }
                                )
                            }
                        }
                        this.handleCloseModal();
                        this.props.onSetUser(r.data);
                    }
                    });
                    setSubmitting(false);
                })
                .catch((e) => {
                    setSubmitting(false);
                    this.setState({
                        loginError: true,
                    });
                });
        });
    };

    /**
     * Handle register form submit
     */
    submitRegisterForm = (values, callbacks) => {
        this.setState(
            {
                registerError: false,
            },
            () => {
                const data = {
                    email: values.registerEmail,
                    password: values.registerPassword,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    newsletter: values.newsletter,
                    countryCode: this.state.countryCode,
                };

                const { setSubmitting } = callbacks;

                ApiService.registerUser(data)
                    .then((r) => {
                        this.setState(
                            {
                                registrationSuccessful: true,
                            },
                            () => {
                                setSubmitting(false);
                            }
                        );
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        this.setState({
                            registerError: true,
                        });
                    });
            }
        );
    };

    /**
     * Handle forgot password form submti
     */
    submitForgotPasswordForm = (values, callbacks) => {
        this.setState(
            {
                forgotPasswordError: false,
            },
            () => {
                const data = {
                    email: values.forgotPasswordEmail,
                };

                const { setSubmitting } = callbacks;

                ApiService.forgotPassword(data)
                    .then((r) => {
                        this.setState(
                            {
                                recoverPassword: true,
                            },
                            () => {
                                setSubmitting(false);
                            }
                        );
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        this.setState({
                            forgotPasswordError: true,
                        });
                    });
            }
        );
    };

    /**
     * Handle set flag
     */
    handleSetFlag = countryCode => {
        this.setState({
            countryCode: countryCode
        })
    }

    render() {
        /** FORMIK LOGIN */
        const loginValidationSchema = Yup.object().shape({
            loginEmail: Yup.string().email("Email must be valid").required("Email is required"),
            loginPassword: Yup.string().required("Password is required"),
        });

        const loginInitialValues = {
            loginEmail: "",
            loginPassword: "",
        };
        //** FORMIK LOGIN END */

        /** FORMIK REGISTER */
        const registerValidationSchema = Yup.object().shape({
            firstName: Yup.string().required("First name is required"),
            lastName: Yup.string().required("Last name is required"),
            registerEmail: Yup.string().email("Email must be valid").required("Email is required"),
            registerPassword: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters long").max(60, "Password must be less than 60 characters long"),
            newsletter: Yup.bool(),
        });

        const registerInitialValues = {
            firstName: "",
            lastName: "",
            registerEmail: "",
            registerPassword: "",
            newsletter: true,
        };
        //** FORMIK REGISTER END */

        //** FORMIK FORGOT PASSWORD */
        const forgotPasswordValidationSchema = Yup.object().shape({
            forgotPasswordEmail: Yup.string().email("Email must be valid").required("Email is required"),
        });

        const forgotPasswordInitialValues = {
            forgotPasswordEmail: "",
        };
        //** FORMIK FORGOT PASSWORD END */

        return (
            <>
                <div className={`c-authentication c-authentication__login ${this.state.loginOpen ? "visible" : ""}`}>
                    <div className="c-authentication__wrapper">
                        <div className="c-authentication__form-wrapper">
                            <h3 className="font-secondary font-12 font-400 mb-30 text-center letter-spacing-15">
                                LOG IN FOR THE FULL <br></br> SPIRACLE EXPERIENCE
                            </h3>
                            <Formik initialValues={loginInitialValues} validateOnMount={true} validationSchema={loginValidationSchema} onSubmit={this.submitLoginForm}>
                                {(form) => {
                                    return (
                                        <Form className="c-form">
                                            <div>
                                                <Field
                                                    className={`bg-white mb-11 ${form.errors && form.errors.loginEmail && form.touched && form.touched.loginEmail ? "invalid" : ""}`}
                                                    placeholder="Email"
                                                    type="email"
                                                    name="loginEmail"
                                                    required
                                                    id="loginEmail"
                                                />
                                            </div>

                                            <div className="password-field">
                                                <Field
                                                    className={`bg-white ${form.errors && form.errors.loginPassword && form.touched && form.touched.loginPassword ? "invalid" : ""}`}
                                                    placeholder="Password"
                                                    type={this.state.passwordLoginEye ? "password" : "text"}
                                                    name="loginPassword"
                                                    required
                                                    id="loginPassword"
                                                />
                                                <i onClick={() => this.toggleLoginPassword()} className={`icon-eye password-field--eye ${this.state.passwordLoginEye ? "" : "show"}`}></i>
                                            </div>
                                            <div className="c-error-message mt-5">
                                                <ErrorMessage name="loginEmail" component="div" />
                                                <ErrorMessage name="loginPassword" component="div" />
                                                {this.state.loginError ? <div>Invalid credentials</div> : null}
                                            </div>
                                            <div className="mt-25">
                                                <button
                                                    id='gtm-login-in'
                                                    className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase mb-28 ${form.isSubmitting ? "submitting" : ""}`}
                                                    type="submit"
                                                    disabled={form.isSubmitting || !form.isValid}
                                                >
                                                    Log in
                                                    <SpiracleLogoSmall />
                                                </button>
                                            </div>
                                            <i onClick={() => this.handleCloseModal(form.resetForm)} className="c-authentication__wrapper--close-modal icon-close-small font-12"></i>
                                            <div className="text-center">
                                                <button id='gtm-forgot-password' className="c-btn c-btn__link c-btn__link--forgot-password font-12 margin-center font-secondary letter-spacing-15" onClick={() => this.handleOpenForgotPassword(form.resetForm)}>
                                                    FORGOT PASSWORD?
                                                </button>
                                            </div>
                                            <div className="text-center">
                                                <button id='gtm-create-account' className="c-btn c-btn__link font-12 margin-center font-secondary letter-spacing-15" onClick={() => this.handleOpenRegister(form.resetForm)}>
                                                    CREATE ACCOUNT
                                                </button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div className={`c-authentication c-authentication__register ${this.state.registerOpen ? "visible" : ""}`}>
                    <div className="c-authentication__wrapper">
                        <div className="c-authentication__form-wrapper">
                            {!this.state.registrationSuccessful ? (
                                <>
                                    {/* <h3 className="c-authentication__sign-up-h3 font-secondary font-12 font-400 text-center letter-spacing-15">
                                        SIGN UP FOR THE FULL <span className="c-authentication__sign-up-span">SPIRACLE EXPERIENCE</span>
                                    </h3> */}
                                    <h4 className="c-authentication__sign-up-h4 font-italic text-center font-400 margin-reset">
                                        Create a User Account to add free audiobooks to your dedicated Library and browse many articles and podcasts in Murmurations.
                                    </h4>
                                    <Formik initialValues={registerInitialValues} validateOnMount={true} validationSchema={registerValidationSchema} onSubmit={this.submitRegisterForm}>
                                        {(form) => (
                                            <Form className="c-form">
                                                <div>
                                                    <Field
                                                        className={`bg-white mb-11 ${form.errors && form.errors.firstName && form.touched && form.touched.firstName ? "invalid" : ""}`}
                                                        placeholder="First name"
                                                        type="text"
                                                        name="firstName"
                                                        required
                                                        id="firstName"
                                                        onFocus={() => this.setState({registerError: false})}
                                                    />
                                                </div>

                                                <div>
                                                    <Field
                                                        className={`bg-white mb-11 ${form.errors && form.errors.lastName && form.touched && form.touched.lastName ? "invalid" : ""}`}
                                                        placeholder="Last name"
                                                        type="text"
                                                        name="lastName"
                                                        required
                                                        id="lastName"
                                                        onFocus={() => this.setState({registerError: false})}
                                                    />
                                                </div>
                                                <div className="country-flags country-flags--settings mb-11">
                                                    <ReactFlagsSelect
                                                        selected={this.state.countryCode ? this.state.countryCode : null}
                                                        onSelect={code => {this.handleSetFlag(code)}}
                                                        placeholder="Your country"
                                                        searchable
                                                        searchPlaceholder="Search country"
                                                        selectedSize={16}
                                                    />
                                                </div>
                                                <div>
                                                    <Field
                                                        className={`bg-white mb-11 ${form.errors && form.errors.registerEmail && form.touched && form.touched.registerEmail ? "invalid" : ""}`}
                                                        placeholder="Email"
                                                        type="email"
                                                        name="registerEmail"
                                                        required
                                                        id="registerEmail"
                                                        onFocus={() => this.setState({registerError: false})}
                                                    />
                                                </div>
                                                <div className="password-field">
                                                    <Field
                                                        className={`bg-white ${form.errors && form.errors.registerPassword && form.touched && form.touched.registerPassword ? "invalid" : ""}`}
                                                        placeholder="Choose password"
                                                        type={this.state.passwordEye ? "password" : "text"}
                                                        name="registerPassword"
                                                        required
                                                        id="registerPassword"
                                                        onFocus={() => this.setState({registerError: false})}
                                                    />
                                                    <i onClick={() => this.togglePassword()} className={`icon-eye password-field--eye ${this.state.passwordEye ? "" : "show"}`}></i>
                                                </div>
                                                <div className="c-error-message mt-3">
                                                    {(form.errors && !form.errors.registerPassword) || (form.touched && !form.touched.registerPassword) ? (
                                                        <span className="font-12 font-gray font-italic font-secondary">Passwords should be at least 8 characters long</span>
                                                    ) : null}
                                                    <ErrorMessage name="firstName" component="div" />
                                                    <ErrorMessage name="lastName" component="div" />
                                                    <ErrorMessage name="registerEmail" component="div" />
                                                    <ErrorMessage name="registerPassword" component="div" />
                                                    {this.state.registerError ? <div>This email already exists with a user account, please sign in</div> : null}
                                                </div>
                                                <div className="c-field-checkbox position-relative">
                                                    <Field className="c-field-checkbox__input position-absolute z-index-1 position-alignment margin-reset" type="checkbox" name="newsletter" id="newsletter" />
                                                    <label id='gtm-sign-up-newsletter' className="c-field-checkbox__label position-relative font-16 font-secondary" htmlFor="newsletter">
                                                        Yes, I would like newsletters
                                                    </label>
                                                </div>
                                                <div className="mt-25 mb-3">
                                                    <button
                                                        id='gtm-create-account'
                                                        className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase mb-8 ${form.isSubmitting ? "submitting" : ""}`}
                                                        type="submit"
                                                        disabled={form.isSubmitting || !form.isValid || !this.state.countryCode}
                                                    >
                                                        Create account
                                                        <SpiracleLogoSmall />
                                                    </button>
                                                </div>
                                                <i onClick={() => this.handleCloseModal(form.resetForm)} className="c-authentication__wrapper--close-modal icon-close-small font-12"></i>
                                                <div className="text-center">
                                                    <button id='gtm-already-have-an-account' className="c-btn c-btn__link font-12 font-italic margin-center font-secondary letter-spacing-02" onClick={() => this.handleOpenLogin(form.resetForm)}>
                                                        Already have an account?
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            ) : (
                                <>
                                    <p className="text-center font-italic font-18">Thank you for signing into Spiracle. <br /> Please check your email for verification link to complete sign up.</p>
                                    <i onClick={() => this.handleCloseModal()} className="c-authentication__wrapper--close-modal icon-close-small font-12"></i>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`c-authentication c-authentication__forgot-password ${this.state.forgotPasswordOpen ? "visible" : ""}`}>
                    <div className="c-authentication__wrapper">
                        <div className="c-authentication__form-wrapper">
                            {!this.state.recoverPassword ? (
                                <>
                                    <h3 className="font-secondary font-12 font-400 mb-30 text-center letter-spacing-15">RECOVER YOUR PASSWORD</h3>
                                    <h4 className="font-italic text-center font-18 font-400 margin-reset mb-35">Don’t worry, it happens to the best of us</h4>
                                    <Formik initialValues={forgotPasswordInitialValues} validateOnMount={true} validationSchema={forgotPasswordValidationSchema} onSubmit={this.submitForgotPasswordForm}>
                                        {(form) => {
                                            return (
                                                <Form className="c-form">
                                                    <div>
                                                        <Field
                                                            className={`bg-white ${form.errors && form.errors.forgotPasswordEmail && form.touched && form.touched.forgotPasswordEmail ? "invalid" : ""}`}
                                                            placeholder="Your email"
                                                            type="email"
                                                            name="forgotPasswordEmail"
                                                            required
                                                            id="forgotPasswordEmail"
                                                            onFocus={() => this.setState({ forgotPasswordError: false })}
                                                        />
                                                    </div>
                                                    <div className="c-error-message mt-5">
                                                        <ErrorMessage name="forgotPasswordEmail" component="div" />
                                                        {this.state.forgotPasswordError ? <div>Invalid email</div> : null}
                                                    </div>
                                                    <div className="mt-25">
                                                        <button
                                                            id='gtm-send-me-a-recovery-link'
                                                            className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase mb-28 ${form.isSubmitting ? "submitting" : ""}`}
                                                            type="submit"
                                                            disabled={form.isSubmitting || !form.isValid}
                                                        >
                                                            Send me a recovery link
                                                            <SpiracleLogoSmall />
                                                        </button>
                                                    </div>
                                                    <i onClick={() => this.handleCloseModal(form.resetForm)} className="c-authentication__wrapper--close-modal icon-close-small font-12"></i>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </>
                            ) : (
                                <>
                                    <p className="text-center font-italic font-18">Please check your email for recovery password link.</p>
                                    <i onClick={() => this.handleCloseModal()} className="c-authentication__wrapper--close-modal icon-close-small font-12"></i>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUser: (user) => dispatch(actionTypes.onSetUser(user)),
        onGetCartItems: (cart) => dispatch(cartActionTypes.onGetCartItems(cart)),
        onGetLibraryItems: (lib, total) => dispatch(libraryActionTypes.onGetLibraryItems(lib, total)),
        onLogout: () => dispatch(actionTypes.onLogout()),
    };
};

const authWithRouter = withRouter(Auth);

export default connect(mapStateToProps, mapDispatchToProps)(authWithRouter);
