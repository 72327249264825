import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import AudioBook from 'components/UI/Redemption/AudioBook/AudioBook';
import { Buttons, List, Paragraph, ParagraphSmall,  StyledContent, StyledRedemptionContainer, Title } from './style';

import img from '../../../images/audiobook-in-a-card-big.png'
import { useSelector } from 'react-redux';

function RedeemAudioBookError({ data }) {
    const history = useHistory();

    const redemptionAudioBook = useSelector(state => state.redemptionReducer.redemptionAudioBook)

    const audioBook = {
        cover: img,
    }

    const error = data.location.state ? JSON.parse(data.location.state.error) : null
    const msg = error?.data['hydra:description'] || error?.data['message'] || error?.statusText

    useEffect(() => {
        if (!error) {
            history.push('/redemption')
        }
    })

    const isAudioBookExist = Object.keys(redemptionAudioBook).length > 0
    const printAudioBook = isAudioBookExist ? redemptionAudioBook : audioBook

    const isNotFound = error?.status === 404 || msg.toLowerCase() === 'card_not_activated';
    

    return (
        <StyledRedemptionContainer>
            <AudioBook data={printAudioBook} showBg={isAudioBookExist} />

            <StyledContent>
                <Title>Oops!</Title>
                <Paragraph>There has been a problem redeeming your audiobook.</Paragraph>

                <ParagraphSmall>The redemption code you've entered has {isNotFound ? 'not been recognised' : 'already been redeemed'}.</ParagraphSmall>

                <ParagraphSmall className='mb-15'><span>Please try the following steps:</span></ParagraphSmall>
                <List>
                    <li>{isNotFound ? 
                            <><span>Double-check your Code:</span> Ensure there are no typos in the redemption code.</> : 
                            <><span>Already Used?</span> If you've received this audiobook as a gift, the code may have already been redeemed. Please check with the gift giver.</>
                        }
                    </li>
                    <li><span>Still Stuck?</span> Contact us at <a href="mailto:support@spiracleaudiobooks.com">support@spiracleaudiobooks.com</a> and we'll get to the bottom of this mystery.</li>
                </List>

                <Buttons>
                    <button id='gtm-redeem-error-try-again' className='c-btn c-btn__black c-btn__ghost--square font-secondary font-14 letter-spacing-15 font-uppercase text-center mt-30' onClick={() => history.goBack()}>Try again</button>
                </Buttons>
            </StyledContent>
            
        </StyledRedemptionContainer>
    )
}

export default RedeemAudioBookError;