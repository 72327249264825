import ReactHtmlParser from 'react-html-parser';

import classes from './Testimonials.module.scss';

function Testimonials({ data, title, subtitle }) {
    return (
        <div className={`${classes.Testimonials} h-flex`}>
            {subtitle && <h2 className={`${classes.Testimonials__SubTitle} mb-15 font-18 font-uppercase font-secondary`}>{subtitle}</h2>}
            {title && <h3 className='mb-50 font-36 font-italic text-center'>{title}</h3>}

            <div className={`${classes.Testimonials__Content} h-flex`}>
                {
                    data?.slice(0, 3).map((item, index) => (
                        <div key={`testimonial-${index}`}className={`${classes.Testimonials__Content__Item} h-flex`}>
                            <div className='mb-16 font-secondary font-18'>{ReactHtmlParser(item.authorDescription)}</div>
                            <div className='font-italic font-22'>{item.authorName}</div>
                            <div className='font-10 ls-25 font-uppercase font-secondary'>{item.authorRole}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default Testimonials