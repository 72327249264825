import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Name, Price, Radio, RadioContainer, StyledChooseMembershipType, Type } from './style';

import { subscriptionPlans } from '../../../consts/consts';

const { supporterMonthly, supporterYearly, readerMonthly, readerYearly, avidReaderMonthly, avidReaderYearly, sixMonthly} = subscriptionPlans;

class ChooseMembershipType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isXmasGift: this.props.location.pathname === '/gift',
            isXmas: this.props.location.pathname === '/xmas',
            optionsGift: [
                { value: supporterMonthly.plan, label: <><Name>{supporterMonthly.name}</Name> <br /> <Price>£{supporterMonthly.price}</Price> <Type>/ {supporterMonthly.type2}</Type></> },
                { value: supporterYearly.plan, label: <><Name>{supporterYearly.name}</Name> <br /> <Price>£{supporterYearly.price}</Price> <Type>/ {supporterYearly.type2}</Type></> },
                { value: readerMonthly.plan, label: <><Name>{readerMonthly.name}</Name> <br /> <Price>£{readerMonthly.price}</Price> <Type>/ {readerMonthly.type2}</Type></> },
                { value: readerYearly.plan, label: <><Name>{readerYearly.name}</Name> <br /> <Price>£{readerYearly.price}</Price> <Type>/ {readerYearly.type2}</Type></> },
                { value: avidReaderMonthly.plan, label: <><Name>{avidReaderMonthly.name}</Name> <br /> <Price>£{avidReaderMonthly.price}</Price> <Type>/ {avidReaderMonthly.type2}</Type></> },
                { value: avidReaderYearly.plan, label: <><Name>{avidReaderYearly.name}</Name> <br /> <Price>£{avidReaderYearly.price}</Price> <Type>/ {avidReaderYearly.type2}</Type></> },
                { value: sixMonthly.plan, label: <><Name>{sixMonthly.name}</Name> <br /> <Price>£{sixMonthly.price}</Price> <Type>/ {sixMonthly.type2}</Type></> },
            ],
            options: [
                { value: avidReaderMonthly.plan, label: <><Name>{avidReaderMonthly.name}</Name> <br /> <Price>£{avidReaderMonthly.price}</Price> <Type>/ {avidReaderMonthly.type2}</Type></> },
                { value: avidReaderYearly.plan, label: <><Name>{avidReaderYearly.name}</Name> <br /> <Price>£{avidReaderYearly.price}</Price> <Type>/ {avidReaderYearly.type2}</Type></> },
            ],
            defaultOption: null,
        }
    }

    componentDidMount() {
        if (this.state.isXmasGift) {
            const plan = new URLSearchParams(this.props.location.search).get('plan');
            const findPlan = this.state.optionsGift.find((item) => item.value === Number(plan))
    
            if (findPlan) {
                this.setState({
                    defaultOption: findPlan
                })
            } else {
                const searchParams = new URLSearchParams();
                const defOpt = this.state.optionsGift[3]
                searchParams.set('plan', defOpt.value);
                this.props.history.replace(`/gift?${searchParams.toString()}`)
                
                this.setState({
                    defaultOption: defOpt
                })
            }
        }

        if (this.state.isXmas) {
            const plan = new URLSearchParams(this.props.location.search).get('plan');
            const findPlan = this.state.options.find((item) => item.value === Number(plan))

            if (findPlan) {
                this.setState({
                    defaultOption: findPlan
                })
            } else {
                const searchParams = new URLSearchParams();
                const defOpt = this.state.options[0]
                searchParams.set('plan', defOpt.value);
                this.props.history.replace(`/xmas?${searchParams.toString()}`)
                
                this.setState({
                    defaultOption: defOpt
                })
            }
        }
    }

    handleChange = (e) => {
        this.setState({
            subscriptionType: e.target.value,
        }, () => {
            const plan = this.state.subscriptionType;
            const searchParams = new URLSearchParams();
            searchParams.set('plan', plan);

            if (this.state.isXmasGift) {
                this.props.history.replace(`/gift?${searchParams.toString()}`)
            } 
            
            if (this.state.isXmas) {
                this.props.history.replace(`/xmas?${searchParams.toString()}`)
            }
        });
    }

    renderRadioButton = (item, index, defaultOption) => {
        if (defaultOption) {
            return (
                <Radio key={`radio-${index}`}>
                    <input type="radio" id={item.value} name="type" value={item.value} defaultChecked={defaultOption.value === item.value} onChange={(e) => this.handleChange(e)} />
                    <label htmlFor={item.value}>{item.label}</label>
                </Radio>
            )
        }
        
    }

    render() {
        return (
            <StyledChooseMembershipType className={this.props.className}>                                   
                <label>Select a plan: *</label>
                <RadioContainer>
                    {this.state.isXmasGift ? (
                        this.state.optionsGift.map((item, index) => {
                            return this.renderRadioButton(item, index, this.state.defaultOption)
                        })
                    ) : (
                        this.state.options.map((item, index) => {
                            return this.renderRadioButton(item, index, this.state.defaultOption)
                        })
                    )}
                </RadioContainer>
            </StyledChooseMembershipType>
        );
    }
}

export default withRouter(ChooseMembershipType);

