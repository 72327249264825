import * as Yup from "yup";
import * as actionTypes from "../../../../store/actions/userActions";
import * as cartActionTypes from '../../../../store/actions/cartActions';
import * as libraryActionTypes from '../../../../store/actions/libraryActions';

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import ApiService from "../../../../services/api.service";
import { ReactComponent as SpiracleLogoSmall } from "../../../../images/Spiral.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import cookie from 'react-cookies'

class Login extends Component {
    state = {
        passwordEye: true,
        error: false,
        isLoading: false,
    };

    /**
     * Handle submit login form
     */
    submitLoginForm = (values, callbacks) => {
        this.setState(
            {
                error: false,
                isLoading: true,
            },
            () => {
                const data = {
                    email: values.loginEmail,
                    password: values.loginPassword,
                };
                const { setSubmitting } = callbacks;

                ApiService.login(data)
                    .then((r) => {
                        localStorage.setItem("token", r.data.token);
                        localStorage.setItem("refresh_token", r.data.refresh_token);
                        ApiService.getUser().then((r) => {
                            let cartId = cookie.load('cartId')
                            this.props.onSetUser(r.data);
                            const isOutlet = r.data.outlet;
                            if (isOutlet) {
                                this.handleRedirect(isOutlet)
                            } else {
                                if (cartId) {
                                    const data = {
                                        cartUuid: cartId
                                    }
                                    ApiService.updateCartId(r.data.uuid, data).then(
                                        r => {
                                            this.props.onSetUser(r.data);
                                            cookie.remove('cartId');

                                            ApiService.getCartItems(cartId).then(
                                                cart => {
                                                this.props.onGetCartItems(cart.data['hydra:member'])
                                                ApiService.getLibrary().then(
                                                    library => {
                                                        this.setState({
                                                            isLoading: false
                                                        }, () => {
                                                        this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                                        this.handleRedirect()
                                                    })
                                                    }
                                                )
                                                }
                                            )
                                        }
                                    )
                                } else {
                                    if (r.data.cart && r.data.cart.uuid) {
                                        ApiService.getCartItems(r.data.cart.uuid).then(
                                            cart => {
                                                this.props.onGetCartItems(cart.data['hydra:member'])
                                                ApiService.getLibrary().then(
                                                    library => {
                                                    this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                                    this.setState({
                                                        isLoading: false
                                                    }, () => {
                                                        this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                                        this.handleRedirect()
                                                    })
                                                    }
                                                )
                                            }
                                        )
                                    } else {
                                        ApiService.getLibrary().then(
                                            library => {
                                            this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                            this.setState({
                                                isLoading: false
                                            }, () => {
                                                this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                                this.handleRedirect()
                                            })
                                            }
                                        )
                                    }
                                }
                            }
                        });
                        setSubmitting(false);
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        this.setState({
                            error: true,
                        });
                    });
            }
        );
    };

    /**
     * Handle toggle password eye
     */
    togglePassword = () => {
        this.setState({
            passwordEye: !this.state.passwordEye,
        });
    };

    handleRedirect = (isOutlet) => {
        if (isOutlet) {
            this.props.history.push('/activation');
        } else {
            this.props.history.push('/');
        }
    }

    render() {
        /** FORMIK LOGIN */
        const loginValidationSchema = Yup.object().shape({
            loginEmail: Yup.string().email("Email must be valid").required("Email is required"),
            loginPassword: Yup.string().required("Password is required"),
        });

        const loginInitialValues = {
            loginEmail: "",
            loginPassword: "",
        };
        //** FORMIK LOGIN END */

        return (
            <div className="c-login container container__basic-page c-homepage c-homepage--main-content font-secondary mt-90">
                <h1 className="font-24 font-600 mb-50 text-center letter-spacing-15 font-uppercase">
                    Welcome to <br /> Audiobook in a Card <br /> Reseller Portal
                </h1>

                <h2 className="font-500">For Registered Booksellers</h2>
                <p className="lh-20">This secure portal allows registered booksellers to activate Audiobook in a Card purchases and maintain customer relationships.</p>

                {this.props.user && !this.props.user.outlet && !this.state.isLoading ? 
                    <>
                        <h3 className="font-500 mb-10">Looking for your Spiracle customer account?</h3>
                        <p>Please use the login button on the <Link to='/' className='underlined lh-25'>home page</Link>.</p>

                        <h3 className="font-500 mb-10">Are you a bookseller who’s currently logged in as a Spiracle customer?</h3>
                        <p>Please <Link to='/' className='underlined lh-25'>log out</Link> of your customer account first, then return here to access your bookseller portal.</p>
                    </> : null
                }

                <h3 className="font-500">Portal Access</h3>
                <p><span className="font-500">Registered Booksellers:</span> Please sign in below to access your activation portal</p>

                <div className="h-flex h-flex-center">
                    <div className="full-width">
                        <Formik initialValues={loginInitialValues} validateOnMount={true} validationSchema={loginValidationSchema} onSubmit={this.submitLoginForm}>
                            {(form) => {
                                return (
                                    <Form className="c-form">
                                        <div>
                                            <Field
                                                className={`bg-white mb-11 ${form.errors && form.errors.loginEmail && form.touched && form.touched.loginEmail ? "invalid" : ""}`}
                                                placeholder="Email"
                                                type="email"
                                                name="loginEmail"
                                                required
                                                id="loginEmail"
                                            />
                                        </div>

                                        <div className="password-field">
                                            <Field
                                                className={`bg-white ${form.errors && form.errors.loginPassword && form.touched && form.touched.loginPassword ? "invalid" : ""}`}
                                                placeholder="Password"
                                                type={this.state.passwordEye ? "password" : "text"}
                                                name="loginPassword"
                                                required
                                                id="loginPassword"
                                            />
                                            <i onClick={() => this.togglePassword()} className={`icon-eye password-field--eye ${this.state.passwordEye ? "" : "show"}`}></i>
                                        </div>
                                        <div className="c-error-message mt-5">
                                            <ErrorMessage name="loginEmail" component="div" />
                                            <ErrorMessage name="loginPassword" component="div" />
                                            {this.state.error ? <div>Invalid credentials</div> : null}
                                        </div>
                                        <div className="mt-25">
                                            <button
                                                id="gtm-login-in"
                                                className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase mb-28 ${this.state.isLoading ? "submitting" : ""}`}
                                                type="submit"
                                                disabled={this.state.isLoading || !form.isValid || this.props.user}
                                            >
                                                Log in
                                                <SpiracleLogoSmall />
                                            </button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>

                <h3 className="font-500 mt-40">The Mission</h3>
                <p className="lh-20">
                    Audiobook in a Card puts the book back into audiobooks and audiobooks back in the bookshop. 
                    In this real-world, "brick and mortar" environment, audiobooks can be discovered by book lovers as recommended by booksellers, 
                    circumnavigating the bestseller bias of the online marketplace algorithm.
                </p>

                <h3 className="font-500">New to Audiobook in a Card? </h3>
                <p className="lh-20">
                    Join our growing network of independent bookshops to make this scheme part of your business and offer a curated selection of great audiobooks to your customers. 
                    Register for an account <a href="https://audiobookinacard.com/apps/wpdapp" target="_blank" rel="noreferrer" className="underlined lh-25">here</a>
                </p>

                <h3 className="font-500 mb-10">Key Benefits for Booksellers</h3>
                <ul className="c-list c-list--reseller">
                    <li>No upfront costs</li>
                    <li>60% wholesale discount</li>
                    <li>Earn 10% commission on all future customer purchases</li>
                    <li>Monthly catalogue additions</li>
                    <li>Support from local representatives</li>
                    <li>Simple activation process</li>
                    <li>Great audiobooks from literary publishers</li>
                </ul>

                <h3 className="font-500 mt-40 mb-10">Need Help?</h3>
                <ul className="c-list c-list--reseller">
                    <li>Technical support: support@spiracleaudiobooks.com</li>
                    <li>Trade enquiries: bookshop@audiobookinacard.com</li>
                    <li>Visit our Bookseller <a href="https://audiobookinacard.com/pages/bookseller-faq-audiobook-in-a-card" target="_blank" rel="noreferrer" className="underlined lh-26">FAQ</a></li>
                </ul>

                <p className="mt-40 mb-80 font-italic">Not a bookseller? For other purchases, please visit <Link to="/" className="underlined lh-25">spiracleaudiobooks.com</Link></p>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.userReducer.user,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUser: (user) => dispatch(actionTypes.onSetUser(user)),
        onGetCartItems: (cart) => dispatch(cartActionTypes.onGetCartItems(cart)),
        onGetLibraryItems: (lib, total) => dispatch(libraryActionTypes.onGetLibraryItems(lib, total))
    };
};

const loginWithRouter = withRouter(Login);

export default connect(mapStateToProps, mapDispatchToProps)(loginWithRouter);
