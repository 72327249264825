import styled from "styled-components/macro";

export const Cover = styled.div``;
export const Amount = styled.p``;
export const PageTitle = styled.span``;
export const Title = styled.h2``;
export const Subtitle = styled.h3``;
export const Background = styled.div``;

export const StyledPayment = styled.div`
    /* max-width: 520px;
    padding: 0 20px; */
    // margin-top: -37px;
    /* margin-left: auto;
    margin-right: auto;
    background: #F9F5E9;*/

    /* ${(props) => props.theme.breakpoints.down("sm")} {
        margin-top: 46px;
    } */

    ${Background} {
        background: #F9F5E9;
        padding: 35px 0 10px;
        margin-top: -19px;

        ${(props) => props.theme.breakpoints.down("sm")} {
            margin-top: 46px;
        }

        > h2 {
            font-size: 60px;
            font-style: italic;
            line-height: 60px;
            text-align: center;
            margin-bottom: 36px;

            ${(props) => props.theme.breakpoints.down("sm")} {
                font-size: 36px;
                line-height: 36px;
                margin-bottom: 26px;
                max-width: 300px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    ${PageTitle} {
        font-family: 'Gill Sans Nova';
        font-size: 14px;
        line-height: 43px;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #730000;
        display: block;
        text-align: center;
        margin: 0 0 20px;
    }

    ${Title} {
        font-size: 60px;
        font-style: italic;
        line-height: 60px;
        text-align: center;
        margin: 0;
    }

    ${Subtitle} {
        font-family: 'Gill Sans Nova';
        font-size: 26px;
        font-style: italic;
        font-weight: 500;
        line-height: 37px;
        text-align: center;
        margin: 0 0 10px;
    }

    ${Cover} {
        position: relative;
        margin: 0 auto;
        width: 153px;  
        height: 122px;

        img {
            position: absolute;
            width: 110px;
            height: 110px;
            object-fit: cover;
            filter: ${(props) => props.membership ? "drop-shadow(-2px 0px 5px rgba(0, 0, 0, 0.25));" : "drop-shadow(-2px 0px 20px rgba(0, 0, 0, 0.2))"};

            &:first-of-type {
                top: ${(props) => props.oneImage ? "6px" : "0"};  
                left: ${(props) => props.oneImage ? "22px" : "0"};
            }

            &:last-of-type {
                bottom: 0;
                right: 0;
            }
        }
    }

    ${Amount} {
        font-size: 32px;
        font-family: 'Gill Sans Nova';
        line-height: 46px;
        font-weight: 300;
        text-align: center;
        margin: 25px auto 40px;
        width: max-content;

        span {
            margin-right: 3px;
        }

        &.old-price {
            text-decoration: line-through;

            &:nth-of-type(2) {
                margin-top: -25px;
            }
        }

        &.new-price {
            margin-top: -25px;
        }
    }
`;