import styled from "styled-components/macro";

export const Icon = styled.div``;

export const StyledDate = styled.div`
    font-family: 'Gill Sans Nova';
    margin-top: -10px;
    position: relative;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;

    &.show-placeholder {
        &::before {
            content: 'DD/MM/YYYY';
            position: absolute;
            top: 36px;
            left: 15px;
            color: #bdbab1;
            font-size: 16px;
            letter-spacing: 0.04em;
            pointer-events: none;
        }
    }

    ${Icon} {
        position: absolute;
        top: 36px;
        right: 8px;
        width: 16px;
        height: 16px;
        pointer-events: none;
    }
`;

