
import React from "react";

import PageMeta from 'shared/PageMeta/PageMeta';

import Payment from 'pages/Ecommerce/Payment/Payment';
import { StyledXmasContainer } from './style';

import { subscriptionPlans } from 'consts/consts';

const { freeTrial } = subscriptionPlans;

export default function PromotionXmasFree() {
    const plan = freeTrial.plan;

    return (
        <>
        <PageMeta>
            <title>Free Trial | Spiracle</title>
            <link rel="canonical" href={window.location.href} />
        </PageMeta>

        <StyledXmasContainer>
            <h1 className="font-secondary font-16 font-400 text-center font-uppercase letter-spacing-15">
                Welcome to Spiracle Membership
            </h1>
            <p className='text-center'>
                Please fill in your details for a free Reader Premium Membership. 
                <span className="display-block font-bold">This includes 1 free audiobook.</span>
            </p>
            <p className='text-center mb-30'>
                We will remind you of your Membership through the month and let you know when it's coming to an end. You can decide to sign up as a Premium Member or cancel.
            </p>
        </StyledXmasContainer>

        <Payment promotion xmas xmasPlan={Number(plan)} freeTrial />
        </>
    );
}