import { Details, Header, MembershipDetailsContainer } from 'pages/Membership/style';

import React, { Component } from 'react';

import ApiService from '../../services/api.service';
import { connect } from 'react-redux';
import SubscriptionItem from '../../components/UI/MembershipGift/SubscriptionItem';

import PageMeta from '../../shared/PageMeta/PageMeta';
import SkeletonSubscription from 'components/UI/Skeleton/SkeletonSubscription/SkeletonSubscription';

class MembershipDetails extends Component {
    state = {
        isLoading: true,
        isError: false,
        id: null,
        allSubscriptions: null,
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/access-denied");
        } else {
            this.handleGetAllSubscriptions()
        }
    }

    handleGetAllSubscriptions = () => {
        const params = new URLSearchParams();
        params.append('itemsPerPage', 1000);

        this.setState({
            isLoading: true
        })

        ApiService.getAllSubscriptions(params).then(
            r => {
                this.setState({
                    allSubscriptions: r.data['hydra:member'], 
                    isLoading: false
                }, () => {
                    if (!this.state.allSubscriptions?.length) {
                        this.props.history.push('/subscription')
                    }
                })
            }
        )
    }

    render() {
        return (
            <MembershipDetailsContainer>
                <PageMeta>
                    <title>Membership Details | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

                {this.state.isLoading ? <SkeletonSubscription /> : (
                    this.state.allSubscriptions ?
                        <Details>
                            {/* <GiftContainer className='text-center'>
                                <span className='mb-35 display-inline-block font-12 lh-24 font-uppercase'>the sound of great writing</span>
                                <Cta>
                                    <Link id='gtm-membership-details-payment-month' to={{ pathname: "/payment", state: { subscription: 'month' }}} className={`c-btn c-btn__ghost c-btn__ghost--square full-width`}> 
                                        <Price>£{this.props.membershipPrice.month}</Price>
                                        <span>per month</span>
                                    </Link>
                                    <Link id='gtm-membership-details-payment-year' to={{ pathname: "/payment", state: { subscription: 'year' }}} className={`c-btn c-btn__ghost c-btn__ghost--square full-width`}> 
                                        <Price>£{this.props.membershipPrice.year}</Price>
                                        <span>per year</span>
                                    </Link>
                                </Cta>
                            </GiftContainer> */}

                            {/* <div className='mt-20 text-center font-16 font-uppercase'><Link id='gtm-membership-details-subscription' to='/subscription' className='animated-underline'>More about Subscriptions</Link></div> */}

                            <Header className='mt-45 pt-7 pb-7 font-uppercase font-25 font-400 letter-spacing-15'>Membership</Header>
                            
                            {this.state.allSubscriptions.map((item, index) => {
                                return <SubscriptionItem key={index} item={item} subscriptionsTotal={this.state.allSubscriptions.length} getAllSubscriptions={this.handleGetAllSubscriptions} />
                            })}
                        </Details>
                        : null
                )
                }
            </MembershipDetailsContainer>
        )
    }

}

const mapStateToProps = state => ({
    user: state.userReducer.user,
})


export default connect(mapStateToProps)(MembershipDetails);