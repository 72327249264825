import React, { Component } from 'react';

import PageMeta from '../../shared/PageMeta/PageMeta'
import Payment from '../Ecommerce/Payment/Payment';

class PromotionXmas extends Component {
    state = {
        plan: new URLSearchParams(this.props.location.search).get('plan')
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location !== this.props.location) {
            this.setState({
                plan: new URLSearchParams(this.props.location.search).get('plan')
            })
        }
    }

    render() {
        return (
            <>
                <PageMeta>
                    <title>Xmas Promo | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

   
                <Payment promotion xmas xmasPlan={Number(this.state.plan)} />
            </>
        )
    }

}

export default PromotionXmas;