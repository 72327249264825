import Login from 'components/UI/Retailer/Login/Login'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageMeta from 'shared/PageMeta/PageMeta'

function RetailerLoginPage() {
    const history = useHistory();
    const user = useSelector(state => state.userReducer.user);

    useEffect(() => {
        if (user?.outlet) {
            history.push('/activation')
        }
    }, [history, user?.outlet])

    return (
        <>
            <PageMeta>
                <title>Reseller Login | Spiracle</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>
            <Login />
        </>
    )
}
export default RetailerLoginPage