import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PageMeta from 'shared/PageMeta/PageMeta';
import RedeemAudioBookLogin from 'components/UI/Redeem/RedeemAudioBookLogin';

function RedeemLoginPage() {
    const history = useHistory();

    useEffect(() => {
        const token = localStorage.getItem('token');
        
        if (token) {
            history.push('/redemption')
        }
    }, [history])

    return (
        <>
            <PageMeta>
                <title>Redeem Login | Spiracle</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>

            <RedeemAudioBookLogin/>
        </>
    )
}
export default RedeemLoginPage