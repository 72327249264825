import styled  from "styled-components/macro";

export const StyledRedemptionContainer = styled.div`
    max-width: 1280px;
    padding: 0 40px;
    margin: 130px auto 0;
    font-family: 'Gill Sans Nova';
    display: flex;
    gap: 75px;
    justify-content: space-between;
    align-items: flex-start;

    ${(props) => props.theme.breakpoints.down('md')} {
        flex-direction: column;
        padding: 0 20px;
    }
`

export const Title = styled.h1``;
export const Paragraph = styled.p``;
export const ParagraphSmall = styled.p``;
export const List = styled.ol``;
export const Form = styled.div``;
export const Buttons = styled.div``;

export const StyledContent = styled.div`
    width: 100%;
    ${(props) => props.theme.breakpoints.up('lg')} {
        min-width: 455px;
    }

    ${Title} {
        font-family: 'JJannon';
        font-size: 48px;
        font-style: italic;
        line-height: 43px;
        letter-spacing: 0.03em;
        margin-bottom: 60px;
    }

    ${Paragraph} {
        font-family: 'JJannon';
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.03em;
        margin-bottom: 50px;
    }

    ${ParagraphSmall} {
        font-size: 18px;
        line-height: 24px;

        span {
            font-weight: 500;
        }

        i {
            pointer-events: none;
        }
    }

    ${List} {
        margin-left: 40px;
        font-size: 16px;
        line-height: 24px;

        ${(props) => props.theme.breakpoints.up('md')} {
            font-size: 18px;
            line-height: 24px;
        }

        li {
            span {
                font-weight: 500;
            }

            i {
                pointer-events: none;
            }
        }
    }

    ${Form} {
        margin-top: 50px;
        width: 320px;

        ${(props) => props.theme.breakpoints.down('md')} {
            margin-left: ${(props) => props.center && 'auto'};
            margin-right: ${(props) => props.center && 'auto'};
        }
    }

    ${Buttons} {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;

        ${(props) => props.theme.breakpoints.down('md')} {
            justify-content: center;
        }

        a {
            min-width: 220px;

            &.c-btn__ghost {
                padding: 12px 16px;
            }
        }
    }
`