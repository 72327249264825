import * as userActionTypes from '../../store/actions/userActions';
import * as discountActionTypes from '../../store/actions/discountActions';
import * as cartActionTypes from '../../store/actions/cartActions';

import { Heading, MembershipDetailsList, Note, OrderDetails, OrderFooter, StyledOrderConfirmation, Terms } from '../Order/style';
import React, { Component } from 'react';

import ApiService from 'services/api.service';
import { connect } from 'react-redux';
import DateFormater from '../../services/format-date.service';
import DownloadIcons from 'shared/DownloadIcons/DownloadIcons';
import MembershipCard from '../../shared/MembershipCard/MembershipCard';
import { Link } from 'react-router-dom';
import Loader from 'shared/Loader/Loader';
import PageMeta from '../../shared/PageMeta/PageMeta';
import image from '../../images/hand-apple.webp';

class MembershipConfirmationTrial extends Component {

    state = {
        subscription: null,
        isLoadingUserCart: false,
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/access-denied");
        } else {
            if (!this.props.user && localStorage.getItem('token')) {
                ApiService.getUser().then(
                    user => {
                        this.props.onSetUser(user.data);
                    }
                )
            }
        
            const id = this.props.location.state ? this.props.location.state.subscriptionId : parseInt(new URLSearchParams(this.props.location.search).get('id'))
            ApiService.getSubscriptionsInvoice(id).then(
                r => {
                    this.setState({
                        subscription: r.data
                    })
                    
                    this.props.onSetSubscription(r.data)

                    // get price with subscription discount
                    const cartId = this.props.user && this.props.user.cart && this.props.user.cart.uuid
                    const cartIdFromCart = this.props.cart && this.props.cart[0] && this.props.cart[0].cart.uuid

                    if (cartId) {
                        this.setState({
                            isLoadingUserCart: true
                        })

                        ApiService.getCartItems(cartId).then(
                            cart => {
                                this.props.onGetCartItems(cart.data['hydra:member'])
                                this.setState({
                                    isLoadingUserCart: false
                                })
                            }
                        )
                    } else if (cartIdFromCart) {
                        this.setState({
                            isLoadingUserCart: true
                        })

                        ApiService.updateCart(cartIdFromCart).then(
                            cart => {
                                ApiService.getCartItems(cartIdFromCart).then(
                                    cart => {
                                        this.props.onGetCartItems(cart.data['hydra:member'])
                                        ApiService.getUser().then(
                                            user => {
                                                this.props.onSetUser(user.data);
                                                this.setState({
                                                    isLoadingUserCart: false
                                                })
                                            }
                                        )
                                    }
                                )
                            }
                        )
                    }
                }
            )

            this.props.onRemoveDiscount()
            
        }
    }

    render() {
        let content = <Loader />

        if (this.state.subscription && !this.state.isLoadingUserCart) {
            content = (
                <StyledOrderConfirmation className="container container__big">
                    <PageMeta>
                        <title>Successful | Spiracle</title>
                        <link rel="canonical" href={window.location.href} />
                    </PageMeta>
                    
                    <Heading className="font-20 lh-29 font-italic">
                        Hello! Your free trial of Spiracle Audiobooks begins here.

                        <img src={image} alt="" />

                        <p>You are joining a community of booklovers where you can discover independent voices from around the world.</p>
                    </Heading>

                    <MembershipDetailsList>
                        Your free trial includes

                        <ol>
                            <li>Your free trial includes a free audiobook from this month’s <Link id='gtm-membership-confirmation-trial-subscriber-selection' to='/subscriber-selection'>Current Highlights</Link></li>
                            <li>Access to all articles and podcasts in <Link id='gtm-membership-confirmation-trial-murmurations' to='/murmurations'>Murmurations</Link></li>
                        </ol>
                    </MembershipDetailsList>

                    <Note>Please read <Link id='gtm-membership-confirmation-trial-spiracle-starters' to='/info/spiracle-for-starters'>Spiracle for Starters</Link>. There are also useful pointers in the <Link id='gtm-membership-confirmation-trial-faq' to='/faq'>Support</Link> section.</Note>

                    <DownloadIcons trialSubscription />

                    {this.state.subscription.orderItem ?
                        <>
                            <OrderDetails>
                                <span>ORDER No. {this.state.subscription.orderItem.orderCart.orderNumber}</span>
                                <span>{DateFormater.formatDateMonthYear(this.state.subscription.orderItem.createdAt)}</span>
                            </OrderDetails>
                            
                            <MembershipCard 
                                plan={this.state.subscription.plan} 
                                originalPrice={this.state.subscription.orderItem.originalPrice} 
                                price={this.state.subscription.orderItem.price} 
                                gift={this.state.subscription.gift} 
                                giftEmail={this.state.subscription.giftEmail} 
                                discountCode={this.state.subscription.orderItem.couponCode}
                                discountPercent={this.state.subscription.orderItem.percentDiscount}
                                discountPrice={this.state.subscription.orderItem.percentDiscount ? this.state.subscription.orderItem.price : null}
                            />
                        </> 
                    : null}

                    <OrderFooter className="text-center">
                        <p className="font-18 mt-45 mb-30">We will keep in touch with you during the course of your trial. Please do not hesitate to get in touch with us <a href="mailto:support@spiracleaudiobooks.com">support team</a></p>
                        <Terms>
                            <Link to='/info/terms-conditions'>Terms &amp; Conditions</Link>
                            <p>powered by <strong>stripe</strong></p>
                        </Terms>
                    </OrderFooter>
                </StyledOrderConfirmation>
            );
        }
        
        return content
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,
    cart: state.cartReducer.cart,
})

const mapDispatchToProps = dispatch => {
    return {
        onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
        onSetSubscription: (data) => dispatch(userActionTypes.onSetSubscription(data)),
        onRemoveDiscount: () => dispatch(discountActionTypes.onRemoveDiscount()),
        onGetCartItems: (cartItems) => dispatch(cartActionTypes.onGetCartItems(cartItems)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipConfirmationTrial);