import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SpiracleLogo from '../../images/Spiracle-logo.svg';
import { onLogout } from 'store/actions/userActions';
import { useEffect } from 'react';

function HeaderRetailer() {
    const [showLogout, setShowLogout] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector(state => state.userReducer.user);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('tokenRefreshedDate');
        dispatch(onLogout());
        history.push('/reseller');
    };

    useEffect(() => {
        const isOutlet = user?.outlet;
        setShowLogout(isOutlet);
    }, [user?.outlet])

    return (
        <header className='c-header h-flex h-flex-between'>
            <div className={`c-header__wrapper c-header__wrapper--app-sign-up h-flex h-flex-between h-flex-middle`}>
                <div className={`c-header__logo-wrapper h-flex h-flex-between h-flex-align-end`}>
                    <div className='c-header__logo h-flex h-flex-align-end'>
                        <img src={SpiracleLogo} alt='spiracle-logo' />
                    </div>
                </div>
                {showLogout && 
                    <button 
                        className='c-btn c-btn__black c-btn__ghost--square font-secondary font-14 letter-spacing-15 font-uppercase text-center'
                        onClick={handleLogout}
                    >
                        Logout
                    </button>
                }
            </div>
        </header>
)
}

export default HeaderRetailer;
