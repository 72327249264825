import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { Component } from 'react'

import ApiService from '../../../services/api.service'
import DropdownItem from '../../../components/UI/DropdownItem/DropdownItem';
import Loader from '../../../shared/Loader/Loader';
import Slider from "react-slick";
import PageMeta from '../../../shared/PageMeta/PageMeta';

export default class Faq extends Component {

    state = {
        faqs: null,
        activeTab: null,
        isLoading: true
    }

    componentDidMount() {
        ApiService.faqCategories().then(
            r => {
                this.setState({
                    faqs: r.data['hydra:member'],
                    activeTab: r.data['hydra:member'][0].name,
                    isLoading: false
                })
            }
        )
    }

    /**
     * Handle change tab
     */
    handleChangeTab = (activeTab) => {
        this.setState({
            activeTab: activeTab
        })
    }

    render() {
        let tabs
        let filteredTabs
        let questions
        let content = <Loader />

        if (this.state.faqs) {
            /**
             * FAQ Tabs
             */
            tabs = this.state.faqs.map( (item, index) => {
               return (
                   <span key={index} className={`mr-30 ${this.state.activeTab === item.name ? 'active' : ''}`} onClick={() => this.handleChangeTab(item.name)}>
                       {item.name}
                   </span>
               )
           })


            /**
             * FAQ dropdown items
             */
           filteredTabs = this.state.faqs.filter( item => item.name === this.state.activeTab )[0]
           questions = filteredTabs.faqsPublished.map( (faq, index) => (<DropdownItem key={index} question={faq.question} answer={faq.answer} activeTab={this.state.activeTab}/>))
        }

        /**
         * Slider settings
         */
        const settings = {
            dots: false,
            centerMode: true,
            slidesToScroll: 1,
            variableWidth: true,
            focusOnSelect: true
        }

        if (!this.state.isLoading) {
            content = (
                <div className="container container__basic-page" style={{minHeight: this.props.notPage && 'initial'}}>
                    {this.props.notPage ? null :
                        <PageMeta>
                            <title>Faq | Spiracle</title>
                            <link rel="canonical" href={window.location.href} />
                        </PageMeta>
                    }

                    <div className="c-info-page__header mb-30">
                        <h1 className="c-info-page__title font-secondary letter-spacing-15 font-25 full-width margin-reset pb-7 font-uppercase">
                            FAQ
                        </h1>
                        <div className={`c-info-page__tabs font-secondary font-25 margin-reset font-400 font-italic ${this.state.faqs && this.state.faqs.length > 3 ? 'shadow' : ''}`}>
                            {this.state.faqs && this.state.faqs.length > 3 ? <Slider
                                {...settings}
                            >
                                {tabs}
                            </Slider> : tabs }
                        </div>
                    </div>
                    <div className="c-faq">
                        {questions} 
                    </div>
                </div>
            );
        }

        return content
    }
}
