export const subscriptionPlans = {
    avidReaderMonthly: {
        name: 'Avid Reader',
        type: 'Monthly',
        type2: 'Month',
        plan: 1,
        price: 12,
        monthly: true,
    },
    avidReaderYearly: {
        name: 'Avid Reader',
        type: 'Annually',
        type2: 'Year',
        plan: 2,
        price: 120,
        yearly: true,
    },
    sixMonthly: {
        name: 'Avid Reader',
        type: '6 Months',
        type2: '6 Months',
        plan: 3,
        price: 60,
        sixMonth: true,
    },
    readerMonthly: {
        name: 'Reader',
        type: 'Monthly',
        type2: 'Month',
        plan: 4,
        price: 8,
        monthly: true,
    },
    readerYearly: {
        name: 'Reader',
        type: 'Annually',
        type2: 'Year',
        plan: 5,
        price: 80,
        yearly: true,
    },
    supporterMonthly: {
        name: 'Supporter',
        type: 'Monthly',
        type2: 'Month',
        plan: 6,
        price: 3,
        monthly: true,
    },
    supporterYearly: {
        name: 'Supporter',
        type: 'Annually',
        type2: 'Year',
        plan: 7,
        price: 30,
        yearly: true,
    },
    freeTrial: {
        name: 'Reader',
        type: 'Monthly',
        type2: 'Month',
        plan: 4,
        price: 0,
        monthly: true,
    },
}