import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ApiService from 'services/api.service';
import { ReactComponent as SpiracleLogoSmall } from '../../../images/Spiral.svg';
import { onSetRedemptionAudioBook, onSetRedemptionCode } from 'store/actions/redemptionActions';
import { onGetLibraryItems } from 'store/actions/libraryActions';
import AudioBook from 'components/UI/Redemption/AudioBook/AudioBook';
import { Form, List, Paragraph, ParagraphSmall,  StyledContent, StyledRedemptionContainer, Title } from './style';
import { StyledCheckoutForm } from 'components/UI/Ecommerce/CheckoutForm/style';

import img from '../../../images/audiobook-in-a-card-big.png'

function RedeemAudioBook() {
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState('');

    const audioBook = {
        cover: img,
    }

    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(state => state.userReducer.user);

    const handleChange = e => {
        const value = e.target.value.trim();
        setCode(value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true)

        ApiService.getIsRedemptionCodeValid(code).then(r => {
            const reduxDataCode = {
                redemptionCode: {
                    code: code,
                    bookshop: r.data.outlet.name
                }
            }
            const reduxDataAudiobook = {
                redemptionAudioBook: r.data.audioBook
            }

            dispatch(onSetRedemptionCode(reduxDataCode))
            dispatch(onSetRedemptionAudioBook(reduxDataAudiobook))

            if (user) {
                const data = {
                    token: code
                }

                ApiService.redeemAudiobookCard(data).then((r) => {
                    ApiService.getLibrary().then(
                        library => {
                            dispatch(onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems']))
                            history.push('/redemption/welcome')
                        }
                    ).catch((e) => {
                        console.log(e);
                        history.push('/redemption/welcome')
                    })
                }).catch((eAdToL) => {
                    console.log(eAdToL)
                    ApiService.getLibrary().then(
                        library => {
                            dispatch(onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems']))

                            history.push({
                                pathname: '/redemption/error',
                                state: { error: JSON.stringify(eAdToL.response)}
                            })
                        }
                    ).catch((er) => {
                        console.log(er);
                        history.push({
                            pathname: '/redemption/error',
                            state: { error: JSON.stringify(eAdToL.response)}
                        })
                    })
                })
            } else {
                history.push('/redemption/confirmation')
            }
        }).catch(e => {
            setIsLoading(false)
            history.push({
                pathname: '/redemption/error',
                state: { error: JSON.stringify(e.response)}
            })
        })
    }

    return (
        <StyledRedemptionContainer>
            <AudioBook data={audioBook} />

            <StyledContent center>
                <Title>Spiracle Audiobooks</Title>
                <Paragraph>Welcome, fellow bibliophile! Your audiobook adventure awaits.</Paragraph>

                <ParagraphSmall className='mb-15'>To redeem your audiobook, follow these simple steps:</ParagraphSmall>
                <List>
                    <li><span>Enter the Redemption Code</span>: Locate the unique code on your card and enter it in the field below.</li>
                    <li><span>Sign In <i className='icon-account font-14 font-black'></i> or Create an Account</span>.</li>
                    <li><span>Open the Library <i className='icon-library font-14 font-black'></i></span> : Your new audiobook will be automatically added to your Library</li>
                </List>

                <ParagraphSmall className='mt-30'>
                    Your online Library stores all your audiobooks. You can listen to them via the online Spiracle Player, or download the Spiracle Player App for <span>iOS or Android</span>.
                </ParagraphSmall>

                <Form>
                    <StyledCheckoutForm>
                        <label>Redemption Code</label>
                        <input className="bg-white" value={code} onChange={(e) => handleChange(e)} type="text" name="code" id="code" />

                        <button
                            id='gtm-redeem-audiobook'
                            disabled={isLoading || !code.length} 
                            className={`c-btn c-btn__black c-btn__ghost--square font-secondary font-14 letter-spacing-15 font-uppercase text-center mt-30 ${isLoading ? 'submitting' : ''}`} 
                            onClick={(e) => handleSubmit(e)}>
                                Redeem your audiobook
                                <SpiracleLogoSmall style={{ filter: 'invert()' }} />
                        </button>
                    </StyledCheckoutForm>
                </Form>

                <ParagraphSmall className='mt-50'><span>Need assistance?</span> Just email <a href="mailto:support@spiracleaudiobooks.com">support@spiracleaudiobooks.com</a>.</ParagraphSmall>
            </StyledContent>
        </StyledRedemptionContainer>
    )
}

export default RedeemAudioBook