import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import classes from './GiftMembership.module.scss';

function GiftMembership({ data }) {
    const {
        giftSubtitle,
        giftTitle,
        giftDescription,
        giftDescription1,
        giftDescription2,
        giftDescription3,
        giftSubtitle1,
        giftSubtitle2,
        giftSubtitle3
    } = data

    return (
        <div className={`${classes.GiftMembership} h-flex`}>
            {giftSubtitle && <h3 className='font-uppercase font-secondary font-18 ls-25 mb-15'>{giftSubtitle}</h3>}
            {giftTitle && <h2 className='font-36 ls-3 font-italic text-center mb-20'>{giftTitle}</h2>}
            {giftDescription && <div className='mb-16 font-22 lh-37 font-secondary text-center mb-40'>{ReactHtmlParser(giftDescription)}</div>}

            <div className={`${classes.GiftMembership__Content} h-flex`}>
                {(giftSubtitle1 || giftDescription1) && 
                    <div>
                        <p className='font-secondary font-16'>1</p>
                        {giftSubtitle1 && <h4 className='font-uppercase font-secondary fs-18 ls-20'>{giftSubtitle1}</h4>}
                        {giftDescription1 && <div className='mb-16 font-secondary font-16 ls-2'>{ReactHtmlParser(giftDescription1)}</div>}
                    </div>
                }

                {(giftSubtitle2 || giftDescription2) && 
                    <div>
                        <p className='font-secondary font-16'>2</p>
                        {giftSubtitle2 && <h4 className='font-uppercase font-secondary fs-18 ls-20'>{giftSubtitle2}</h4>}
                        {giftDescription2 && <div className='mb-16 font-secondary font-16 ls-2'>{ReactHtmlParser(giftDescription2)}</div>}
                    </div>
                }

                {(giftSubtitle3 || giftDescription3) && 
                    <div>
                        <p className='font-secondary font-16'>3</p>
                        {giftSubtitle3 && <h4 className='font-uppercase font-secondary fs-18 ls-20'>{giftSubtitle3}</h4>}
                        {giftDescription3 && <div className='mb-16 font-secondary font-16 ls-2'>{ReactHtmlParser(giftDescription3)}</div>}
                    </div>
                }  
            </div>

            <Link id='gtm-subscription-page-gift-membership' to='/gift' className='new-button-red font-20'>Gift a Membership</Link>
        </div>
    )
}
export default GiftMembership