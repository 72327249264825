
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ApiService from '../../services/api.service';
import AudioBookListItem from '../../components/UI/AudioBook/AudioBookListItem';
import PageMeta from "shared/PageMeta/PageMeta";
import SkeletonAudioBooks from "components/UI/Skeleton/SkeletonAudioBooks/SkeletonAudioBooks";
import TitleTextBlock from '../../shared/PageElements/TitleTextBlock';
import { EmptyPage } from "pages/AudioBooks/style";
import Pagination from 'shared/Pagination/Pagination';

function AudiobooksInCard() {
    const location = useLocation();
    const history = useHistory();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [paginationMaxPages, setPaginationMaxPages] = useState(0);
    const [page, setPage] = useState(new URLSearchParams(location.search).get('page') ? new URLSearchParams(location.search).get('page') : 1);
    const [itemsPerPage] = useState(12);

    const getBooks = (data) => {
        const pagePagination = data ? data.selected + 1 : page;
        setPage(pagePagination);

        const params = new URLSearchParams();
        params.append('page', pagePagination);
        params.append('itemsPerPage', itemsPerPage);

        history.replace(`/audiobooks-in-a-card?${params.toString()}`)
        
        setIsLoading(true);

        ApiService.getAudioBooksInCard(params).then(r => {
            const res = r.data['hydra:member'];
            const total = r.data['hydra:totalItems'];
            const maxPages = Math.ceil(total / itemsPerPage);

            setData(res);
            setPaginationMaxPages(maxPages);
            setIsLoading(false);
        }).catch(e => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getBooks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let audioBooks;

    if (data.length) {
        audioBooks = data.map((book, index) => {
            return (
                <Grid item xs={6} md={4} lg={3} key={`audioBook-${index}`}>
                    <AudioBookListItem
                        title={book.title}
                        imgSrc={book.cover}
                        imgAlt={book.coverAlt}
                        price={book.price}
                        subscriptionPrice={book.subscriptionPrice}
                        authors={book.authors}
                        type='audio-book'
                        audioBook={book.audioBook}
                        eBook={book.eBook}
                        link={`/audiobooks/${book.slug}`}
                        availableAsCard={book.availableAsCard}
                    />
                </Grid>
            );
        });
    }

    return (
        <div className="container container__big container__big--withPadding mb-10">
            <PageMeta>
                <title>Audiobooks in a Card | Spiracle</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>

            {isLoading ? <SkeletonAudioBooks marginTop /> : (
                <>
                    <TitleTextBlock 
                        fontItalic  
                        title="Audiobooks in a Card"
                    /> 

                    {
                        data?.length > 0 ? 
                        <>
                            <Grid container spacing={2}>
                                {audioBooks}
                            </Grid> 
                            {paginationMaxPages > 1 ?
                                <Pagination
                                    page={parseInt(page)} 
                                    onPageChange={(data) => getBooks(data)} 
                                    pageCount={paginationMaxPages}
                                /> 
                            : null}
                        </>
                            : 
                            <EmptyPage>
                                <p>Sorry, no results.</p>
                                <p>
                                Try again or visit our{" "}
                                <Link id='gtm-audiobook-in-card-collection' to="/audiobooks" className="font-secondary">
                                    collection
                                </Link>
                                </p>
                            </EmptyPage>
                    }
                </>
            )}
        </div>
    );
}

export default AudiobooksInCard
