import React, { Component } from 'react';

import CoverImage from 'shared/CoverImage/CoverImage';

import { getNamesFromArray } from 'shared/Functions/GetNamesFromArray';

import { StyledAudioBook, Image } from './style';

class AudioBook extends Component {
    render() {
        const cover = this.props.data?.cover || '';
        const title = this.props.data?.title || '';
        const authors = this.props.data?.authors || null;
        const narrators = this.props.data?.narrators || null;
        const translator = this.props.data?.translator || '';
        // const lengthHours = this.props.data?.lengthHours || '';
        // const lengthMin = this.props.data?.lengthMin || '';
        // const firstPublished = this.props.data?.firstPublished ? DateFormater.formatDate(this.props.data.firstPublished) : '';
        // const publisher = this.props.data?.publisher?.name || '';
        // const publisherSlug = this.props.data?.publisher ? this.props.data.publisher['@id'].split('/')[3] : '';

        return (
             <StyledAudioBook showBg={this.props.showBg}>
                <Image>
                     <CoverImage
                        src={cover}   
                        alt={title}                         
                    />
                </Image>

                {title && <h2>{title}</h2>}
                {authors && authors.length ? <div className='font-italic'>{getNamesFromArray(authors)}</div> : null}
                {narrators && narrators.length ? <div>Narrated by: <span className="font-italic">{getNamesFromArray(narrators)}</span></div> : null}
                {translator && <div>Translator: <span className='font-italic'>{translator.firstName} {translator.lastName}</span></div>}
                {/* {(lengthHours > 0 || lengthMin > 0) && <div>Length: {lengthHours>0 && `${lengthHours}hr${lengthHours!==1 ? 's':''}`} {lengthHours>0 && lengthMin>0 && 'and'} {lengthMin>0 && `${lengthMin}min${lengthMin!==1 ? 's':''}`}</div>}
                {firstPublished && <div>First published: {firstPublished}</div>}
                {publisher && <div>Publisher: <a href={`/publishers/${publisherSlug}`} className='font-italic underline'>{publisher}</a></div>} */}
            </StyledAudioBook>
        )
    }
}

export default AudioBook;
