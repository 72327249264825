import styled from "styled-components/macro";

export const Message = styled.div``;
export const MessageError = styled.div``;

export const StyledCardActivation = styled.form`
    max-width: 320px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;

    label {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: .15em;
        display: block;
        margin-left: 15px;
        margin-bottom: 8px;
    }

    button {
        height: 48px;

        &.submitting {
            font-size: 0 !important;
            padding-bottom: 0 !important;
            
            svg {
                display: block;
            }
        }

        svg {
            display: none;
        }
    }

    ${Message}, ${MessageError} {
        margin-top: 10px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    ${Message} {
        color: #215434;
    }

    ${MessageError} {
        color: rgba(255, 0, 0, .8);
    }
`;