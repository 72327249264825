import ReactHtmlParser from 'react-html-parser';

// import introImg from '../../../../images/membership-intro.png';
import classes from './Intro.module.scss';

function Intro({ data }) {
    const { joinSubtitle, joinTitle, joinDescription, image } = data

    return (
        <div className={`${classes.Intro}`}>
            <div className='container__big container__big--withPadding mb-0'>
                <div>
                    <h1 className='font-14 font-uppercase font-secondary font-brick-red'>{joinSubtitle}</h1>
                    <h2 className='font-italic mb-20 lh-60'>{joinTitle}</h2>
                    <div className='font-secondary lh-34 font-22'>{ReactHtmlParser(joinDescription)}</div>
                </div>

                <div>
                    {image && <img src={image} alt="Intro" />}
                </div>
            </div>
        </div>
    )
}
export default Intro