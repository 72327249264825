import PageMeta from 'shared/PageMeta/PageMeta'
import RedeemAudiobookConfirmation from 'components/UI/Redeem/RedeemAudiobookConfirmation'

function RedeemConfirmationPage() {
    return (
            <>
                <PageMeta>
                    <title>Redemption Success | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

                <RedeemAudiobookConfirmation />
            </>
    )
}
export default RedeemConfirmationPage