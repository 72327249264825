import PageMeta from 'shared/PageMeta/PageMeta';
import RedeemAudioBookError from 'components/UI/Redeem/RedeemAudioBookError';

function RedeemErrorPage(props) {
    return (
        <>
            <PageMeta>
                <title>Redeem Fail | Spiracle</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>

            <RedeemAudioBookError data={props}/>
        </>
    )
}
export default RedeemErrorPage