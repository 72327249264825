import styled from "styled-components/macro";

export const Dash = styled.span``;

export const StyledBanner = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 75;
    padding: 6px 20px;
    background: #F79B9B;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.72px;
    font-size: 10px;
    transition: all 0.3s ease-in-out;

    &.hide {
        ${(props) => props.theme.breakpoints.down('sm')} {
            transform: translateY(-350%);
        }
    }

    ${(props) => props.theme.breakpoints.up('lg')} {
        flex-direction: row;
        height: 63px;
        line-height: 63px;
        font-size: 18px;
    }

    span {
        max-width: 400px;

        ${(props) => props.theme.breakpoints.up('lg')} {
            max-width: initial;
        }
    }

    a {
        text-decoration: underline;

        &:hover {
            color: #721b00;
        }
    }



    i {
        &.icon-close {
            position: absolute;
            top: 10px;
            right: 10px;

            ${(props) => props.theme.breakpoints.up('md')} {
                top: 20px;
                right: 20px;
            }
        }

        &.icon-alert {
            pointer-events: none;;
        }
    }
`;

