import { useEffect, useState } from 'react';
import ApiService from 'services/api.service';
import Intro from 'components/UI/Membership/Intro/Intro';
import Plans from 'components/UI/Membership/Plans/Plans';
import Testimonials from 'components/UI/Membership/Testimonials/Testimonials';
import GiftMembership from 'components/UI/Membership/GiftMembership/GiftMembership';
import ContactUs from 'components/UI/Membership/ContactUs/ContactUs';
import PageMeta from 'shared/PageMeta/PageMeta';
import Faq from 'pages/InfoPages/FAQ/Faq';
import Loader from 'shared/Loader/Loader';

function MembershipRedesign() {
    const [membership, setMembership] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [testimonials, setTestimonials] = useState();

    useEffect(() => {
        ApiService.getMembershipPage().then((r) => {
            const data = r.data['hydra:member'] ? r.data['hydra:member'][0] : null
            setMembership(data)
            setTestimonials(data?.testimonialBlocks);
            setIsLoading(false)
        }).catch((e) => {
            setIsLoading(false)
        })

    }, [])

    return (
        <>
            <PageMeta>
                <title>Subscription | Spiracle</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>

            {
                isLoading ?
                    <Loader /> :
                    <>
                        {membership ? <Intro data={membership} /> : null}

                        <div className="container container__big container__big--withPadding">
                            {membership ? <Plans data={membership} /> : null}
                            {testimonials?.length ? <Testimonials data={testimonials} title={membership?.testimonialsTitle} subtitle={membership?.testimonialsSubtitle} /> : null}
                            {membership ? <GiftMembership data={membership} /> : null}
                            <Faq notPage />
                            <ContactUs />
                        </div>
                    </>
            }
        </>
    )
}
export default MembershipRedesign