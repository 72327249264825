import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ApiService from 'services/api.service';
import Loader from 'shared/Loader/Loader';
import PageMeta from '../../shared/PageMeta/PageMeta';
import { Heading } from '../Order/style';
import { StyledXmasContainer } from 'pages/Promotion/style';
import PaypalProblem from 'components/UI/PaypalProblem/PaypalProblem';
import Payment from 'pages/Ecommerce/Payment/Payment';

import * as userActionTypes from '../../store/actions/userActions';
import * as discountActionTypes from '../../store/actions/discountActions';
import { subscriptionPlans } from 'consts/consts';

const { freeTrial } = subscriptionPlans;

class MembershipConfirmationGiftXmas extends Component {

    state = {
        isLoading: false,
        isLoadingSubscription: false,
        recipient: this.props.location.state ? this.props.location.state.recipientName : new URLSearchParams(this.props.location.search).get('recipient'),
        type: this.props.location.state ? this.props.location.state.plan : parseInt(new URLSearchParams(this.props.location.search).get('type')),
        subscriptionId: '',
        isPaypalFailed: new URLSearchParams(this.props.location.search).get('redirect_status') === 'failed',
        plan: freeTrial.plan,
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/access-denied");
        } else {
            if (!this.props.user && localStorage.getItem('token')) {
                this.setState({
                    isLoading: true
                })

                ApiService.getUser().then(
                    user => {
                        this.props.onSetUser(user.data);

                        this.setState({
                            isLoading: false
                        })
                    }
                )
            } else {
                if (!this.state.isPaypalFailed) {
                this.props.onSetPaysSomeoneSubscription();
                }
            }

            this.props.onRemoveDiscount();
        }
    }

    render() {
        const subscription = Object.values(subscriptionPlans).find((item) => item.plan === this.state.type);
        const name = subscription?.name;
        const type = subscription?.type;

        let content = <Loader />

        if (!this.state.isLoading) {
            if (!this.state.isPaypalFailed) {
            content = 
                <>
                    <StyledXmasContainer>
                        <PageMeta>
                            <title>Successful | Spiracle</title>
                            <link rel="canonical" href={window.location.href} />
                        </PageMeta>
        
                        <Heading className="font-20 lh-29 font-italic">
                            Thank you for your purchase!
                        </Heading>

                        <p className='mb-53 font-18 text-center'>
                            We are sure {this.state.recipient} will enjoy their {`${name} ${type}`} subscription.
                        </p>
                        {this.props.user && !this.props.user.currentSubscription ?
                            <>
                                <p className='text-center'>
                                    Please fill in your details for a free Reader Premium Membership. 
                                    <span className="display-block font-bold">This includes 1 free audiobook.</span>
                                </p>
                                <p className='text-center mb-30'>
                                    We will remind you of your Membership through the month and let you know when it's coming to an end. You can decide to sign up as a Premium Member or cancel.
                                </p> 
                            </> : null
                        }
                    </StyledXmasContainer>
                    {this.props.user && !this.props.user.currentSubscription ?
                        <Payment promotion xmas xmasPlan={Number(this.state.plan)} freeTrial /> :null
                    }
                </>          
                       
            } else {
                content = <PaypalProblem />
            }
        }

        return content
          
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,
})

const mapDispatchToProps = dispatch => {
    return {
        onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
        onSetPaysSomeoneSubscription: () => dispatch(userActionTypes.onSetPaysSomeoneSubscription()),
        onRemoveDiscount: () => dispatch(discountActionTypes.onRemoveDiscount()),
    }
}

const membershipConfirmationGiftXmas = withRouter(MembershipConfirmationGiftXmas)

export default connect(mapStateToProps, mapDispatchToProps)(membershipConfirmationGiftXmas)
