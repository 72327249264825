import styled from "styled-components/macro";

export const ImageHolder = styled.div``;
export const StyledPlayerBody = styled.div``;

export const StyledPlayer = styled.div`
    ${StyledPlayerBody} {
        position: fixed;
        bottom: 0;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: ${(props) => props.isEdition ? "#EBDEDB" : "#f4eedc"};
        overflow: auto;
        width: 100%;
        height: 100vh;
        transform: translateY(-100%);
        transition: all 200ms ease-in-out;

        pre {
            width: max-content;
            max-width: 90%;
            margin: 0 auto;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Gill Sans Nova';
        }

        ${ImageHolder} {
            width: ${(props) => props.isEdition ? "100%" : "280px"};
            margin: 0 auto;

            // 7 months ago
            /* @media (max-height: 811px), (max-width: 374px) {
                width: 280px;
            } */

            // samsung s8+ fix
            /* @media (max-height: 811px) and (max-width: 359px) {
                width: 280px;
            } */

            // iphone 13 pro fix
            @media (max-height: 811px) and (max-width: 575px) {
                width: 280px;
            }

            // middle
            @media (max-height: 650px) and (max-width: 575px) {
                width: 220px;
            }

            // iphone SE fix
            @media (max-height: 610px) and (max-width: 575px) {
                width: 160px;
            }
        }

        .widget-player {
            transform: translateY(-100%);
        }

        .player-visible & {
            transform: translateY(0);
        }

        &.mini-player {
            height: 60px;
            bottom: 82px;
            //top: calc(100vh - 131px);
            top: initial;
            display: flex;
            overflow-y: hidden;
            background-color: ${(props) => props.isEdition ? "#EBDEDB" : "#EDE4C9"};
            padding: 5px 3px 5px 5px;
            z-index: 70;

            ${ImageHolder} {
                flex: 50px 0 0;      
                margin: 0;      
            }        

            > .info-wrapper {
                padding: 0 14px; 
                width: 100%;

                p {
                    max-height: 20px;
                    overflow: hidden;
                    word-break: break-all;
                    margin: 2px 0 6px;
                    font-family: Gill Sans Nova;
                    font-size: 14px;                
                    line-height: 17px;
                    letter-spacing: 0.03em;     
                    font-style: ${(props) => props.isEdition ? "italic" : "normal"};           

        
                    &:hover {
                        cursor: pointer;
                    }

                    &:nth-child(2) {
                        font-style: ${(props) => props.isEdition ? "normal" : "italic"};  
                    }
                }
            }

            .rhap_container {
                @media screen and (min-width: 375px) {
                    padding: 0 !important;
                }
                
                @media screen and (max-width: 360px) {
                    padding: 0 !important;
                }

                .rhap_progress-section {
                    display: none;
                }

                .rhap_main-controls {
                    margin-top: 0;
                }

                .rhap_forward-button {
                    display: none;
                }

                .rhap_controls-section {
                    margin-top: 6px;

                .rhap_play-pause-button {
                        width: 38px;
                        height: 38px;
                        font-size: 26px;
                    }

                    .rhap_rewind-button {
                        font-size: 34px;
                    }

                .rhap_main-controls-button {
                        margin: 0 17px 0 0;
                }
                }

                .rhap_main-controls {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: -19px;
                        left: -80px;    
                        background: linear-gradient(${(props) => props.isEdition ? "90deg, rgba(235, 222, 219, 0) -24.45%, #EBDEDB 101.73%" : "90deg,rgba(239,231,208,0) -24.45%, #EDE4C9 102.11%"});
                        height: 75px;
                        width: 80px;
                    }
                }
            }
        }
    }
    
`;