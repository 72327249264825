import {
  EmptyPage,
  FilterResultsInfo,
  Filters,
  FiltersToggle,
  StyledChipHolder,
  StyledChip,
  StyledRadio
} from "pages/AudioBooks/style";
import React, { Component } from 'react';

import ApiService from '../../services/api.service';
import AudioBookListItem from '../../components/UI/AudioBook/AudioBookListItem';
import AudioBookSingleFilter from "../../components/UI/AudioBook/AudioBookSingleFilter";
import { debounce } from 'shared/Functions/Debounce';
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Input from '../../components/UI/Input/Input';
import { Link } from "react-router-dom";
import PageMeta from "shared/PageMeta/PageMeta";
import SkeletonAudioBooks from "components/UI/Skeleton/SkeletonAudioBooks/SkeletonAudioBooks";
import { InputContainer, SearchContainer, SortButton, SortMenu } from './style';

class SearchBooks extends Component {
  constructor(props) {
    super(props);
    this.filtersWrapper = React.createRef();

    this.state = {
        books: [],
        page: 1,
        styles: [],
        categories: [],
        locations: [],
        publishers: [],
        nationalities: [],
        activeStyles: [],
        activeCategories: [],
        activeLocations: [],
        activePublishers: [],
        activeNationalities: [],
        stylesFilter: [],
        categoriesFilter: [],
        locationsFilter: [],
        publishersFilter: [],
        nationalitiesFilter: [],
        lengthHoursFilter: 0,
        lengthPagesFilter: 0,
        haveMore: false,
        numberOfBooks: 0,
        commonFilters: [],
        lengthHours: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        lengthPages: [100, 200, 300, 400, 500, 600, 700, 800, 900],
        filtersVisible: false,
        filtersHeight: null,
        isLoading: true,
        allFilters: [],
        isLoadingMore: false,
        isLoadMore: false,
        searchKeyword: '',
        isTyping: false,
        sort: 'title',
        direction: null,
        isSorting: false,
        isSortMenu: false,
        sortValue: 'relevance'
    };

    this.apiCallSearch = debounce(() => this.filterApi(false, false, this.state.direction), 400);
  }

    componentDidMount() {
        this.getBooks();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            if (this.props.location.state && this.props.location.state.fromQuick) {
                this.setState({
                    isLoadMore: true,
                    allFilters: [],
                    direction: null,
                    sortValue: 'relevance'
                }, () => {
                    this.getBooks();                  
                })
            }
            if (!this.state.isLoadMore && !this.state.isTyping && !this.state.isSorting && (this.props.location.state && !this.props.location.state.fromQuick)) {
                this.setState({
                    isLoadMore: true,
                    allFilters: [],
                }, () => {
                    this.getBooks();                    
                })
            }
        }
    }

    /**
     * decode query params from URL
     */
    decodeParams = (param) => {
        return decodeURIComponent(param.replace(/\+/g, ' '));
    }

    getBooks = () => {
        /**
         * Get all filters from URL
         */
        const searchParams = new URLSearchParams(this.props.history.location.search);
        const pageURLSearchParams = new URLSearchParams()
        pageURLSearchParams.append('itemsPerPage', 200);
       
        /**
         * Set items per page for filters
         */
        let allFilters = [...this.state.allFilters]
        let hours = searchParams.get('lengthHours') ? parseInt(searchParams.get('lengthHours'), 10) : searchParams.get('lengthHours') ? parseInt(searchParams.get('lengthHours'), 10) : 0
        let pages = searchParams.get('pagesMinimum') ? parseInt(searchParams.get('pagesMinimum'), 10) : searchParams.get('pagesMinimum') ? parseInt(searchParams.get('pagesMinimum'), 10) : 0
        if (hours > 0) {
            allFilters.push(hours)
        }
    
        if (pages > 0) {
            allFilters.push(pages);
        }
        this.setState({
            page: 1,
            searchKeyword: this.state.isTyping ? this.state.searchKeyword : this.props.location.search ? this.decodeParams(this.props.location.search.split('=')[1].split('&')[0]) : '',
            stylesFilter: searchParams.get('styles') ? searchParams.get('styles').split(',').map(item => {return parseInt(item, 10)}) : [],
            categoriesFilter: searchParams.get('categories') ? searchParams.get('categories').split(',').map(item => {return parseInt(item, 10)}) : [],
            locationsFilter: searchParams.get('locations') ? searchParams.get('locations').split(',').map(item => {return parseInt(item, 10)}) : [],
            publishersFilter: searchParams.get('publishers') ? searchParams.get('publishers').split(',').map(item => {return parseInt(item, 10)}) : [],
            nationalitiesFilter: searchParams.get('authorsNationality') ? searchParams.get('authorsNationality').split(',').map(item => {return parseInt(item, 10)}) : [],
            lengthHoursFilter: searchParams.get('lengthHours') ? parseInt(searchParams.get('lengthHours'), 10) : searchParams.get('lengthHours[') ? parseInt(searchParams.get('lengthHours['), 10) : 0,
            lengthPagesFilter: searchParams.get('pagesMinimum') ? parseInt(searchParams.get('pagesMinimum'), 10) : searchParams.get('pagesMinimum[') ? parseInt(searchParams.get('pagesMinimum['), 10) : 0,
            allFilters: allFilters,
        }, () => {
            this.filterApi();
        })
        /**
         * Get styles filter
         */
        ApiService.styles(pageURLSearchParams).then((r) => {
            this.setState({
                styles: r.data["hydra:member"],
            }, () => {
                    //fill state and active state with style 
                    if (searchParams.get('styles')) {
                        const categories = this.state.styles;
                        const actCategories = [];
                        searchParams.get('styles').split(',').forEach(id => {
                        let filteredCat = categories.filter(cat => cat.id === parseInt(id))
                        let index = categories.indexOf(filteredCat[0])
                        let allFilt = this.state.allFilters;
                        allFilt.push(filteredCat[0]);
                        actCategories.push(index)
                        this.setState({
                            activeStyles: actCategories,
                            allFilters: allFilt
                        })
                    })
                }
            });
        });
    
        /**
         * Get category filters
         */
        ApiService.categories(pageURLSearchParams).then((r) => {
            this.setState({
                categories: r.data["hydra:member"],
            }, () => {
                //fill state and active state with category 
                if (searchParams.get('categories')) {
                    const categories = this.state.categories;
                    const actCategories = [];
                    searchParams.get('categories').split(',').forEach(id => {
                        let filteredCat = categories.filter(cat => cat.id === parseInt(id))
                        let index = categories.indexOf(filteredCat[0])
                        let allFilt = this.state.allFilters;
                        allFilt.push(filteredCat[0]);
                        actCategories.push(index)
                        this.setState({
                            activeCategories: actCategories,
                            allFilters: allFilt
                        })
                    })
                }
            });
        });
    
        /**
         * Get location filter
         */
        ApiService.locations(pageURLSearchParams).then((r) => {
            this.setState({
                locations: r.data["hydra:member"],
            }, () => {
                //fill state and active state with locations 
                if (searchParams.get('locations')) {
                    const categories = this.state.locations;
                    const actCategories = [];
                    searchParams.get('locations').split(',').forEach(id => {
                        let filteredCat = categories.filter(cat => cat.id === parseInt(id))
                        let index = categories.indexOf(filteredCat[0])
                        let allFilt = this.state.allFilters;
                        allFilt.push(filteredCat[0]);
                        actCategories.push(index)
                        this.setState({
                            activeLocations: actCategories,
                            allFilters: allFilt
                        })
                    })
                }
            });
        });
    
        /**
         * Get publishers filter
         */
        ApiService.publishers(pageURLSearchParams).then((r) => {
            this.setState({
                publishers: r.data["hydra:member"],
            }, () => {
                //fill state and active state with publisher 
                if (searchParams.get('publishers')) {
                    const categories = this.state.publishers;
                    const actCategories = [];
                    searchParams.get('publishers').split(',').forEach(id => {
                        let filteredCat = categories.filter(cat => cat.id === parseInt(id))
                        let index = categories.indexOf(filteredCat[0])
                        let allFilt = this.state.allFilters;
                        allFilt.push(filteredCat[0]);
                        actCategories.push(index)
                        this.setState({
                            activePublishers: actCategories,
                            allFilters: allFilt
                        })
                    })
                }
            });
        });
    
        /**
         * Get nationality filter
         */
        ApiService.nationalities(pageURLSearchParams).then((r) => {
            this.setState({
                nationalities: r.data["hydra:member"],
            }, () => {
                //fill state and active state with nationality 
                if (searchParams.get('nationalities')) {
                    const categories = this.state.nationalities;
                    const actCategories = [];
                    searchParams.get('nationalities').split(',').forEach(id => {
                        let filteredCat = categories.filter(cat => cat.id === parseInt(id))
                        let index = categories.indexOf(filteredCat[0])
                        let allFilt = this.state.allFilters;
                        allFilt.push(filteredCat[0]);
                        actCategories.push(index)
                        this.setState({
                            activeNationalities: actCategories,
                            allFilters: allFilt
                        })
                    })
                }
            });
        });  
        
    }
    
    /**
     * API call for books with filters
     */  
    filterApi = (newPage, reset, direction) => {
        this.setState({
            isLoadingMore: true
        }, () => {
            const searchParams = new URLSearchParams();
            searchParams.append('keyword', reset ? '' : this.state.searchKeyword);
            searchParams.append("page", this.state.page);

            if (direction) {
                searchParams.append("sort", this.state.sort);
                searchParams.append("direction", direction);
            }

            if (this.state.stylesFilter.length) {
                searchParams.append("styles", this.state.stylesFilter);
            }
            if (this.state.categoriesFilter.length) {
                searchParams.append("categories", this.state.categoriesFilter);
            }
            if (this.state.locationsFilter.length) {
                searchParams.append("locations", this.state.locationsFilter);
            }
            if (this.state.publishersFilter.length) {
                searchParams.append("publishers", this.state.publishersFilter);
            }
            if (this.state.nationalitiesFilter.length) {
                searchParams.append("authorsNationality", this.state.nationalitiesFilter);
            }
            if (this.state.lengthHoursFilter !== 0) {
                if (this.state.lengthHoursFilter === 9) {
                    searchParams.append("lengthHours", this.state.lengthHoursFilter);
                } else {
                    searchParams.append("lengthHours", this.state.lengthHoursFilter);
                }
            }
            if (this.state.lengthPagesFilter !== 0) {
                if (this.state.lengthPagesFilter === 900) {
                    searchParams.append("pagesMinimum", this.state.lengthPagesFilter);
                } else {
                    searchParams.append("pagesMinimum", this.state.lengthPagesFilter);
                }
            }

            this.props.history.replace(`/search-books?${searchParams.toString()}`)

            ApiService.searchBooks(searchParams).then((r) => {
                if (newPage) {
                    this.setState({
                        books: [...this.state.books, ...r.data['hydra:member']],
                        numberOfBooks: r.data['hydra:totalItems'],
                        isLoading: false,
                        isLoadingMore: false,
                        isLoadMore: false
                        }, () => {
                            this.setState({
                                haveMore: r.data['hydra:totalItems'] > this.state.books.length ? true : false,
                            });
                    }
                    );
                } else {
                    this.setState({
                        books: r.data['hydra:member'],
                        numberOfBooks: r.data['hydra:totalItems'],
                        isLoading: false,
                        isLoadingMore: false,
                        isLoadMore: false
                    }, () => {
                            this.setState({
                                haveMore: r.data['hydra:totalItems'] > this.state.books.length ? true : false,
                            });
                    }
                );
                }

                if (window.dataLayer) {
                    const items = [];
                    r.data['hydra:member'].map((book, index) => {
                        const locations = []
                        if (book.locations && book.locations.length) {
                            book.locations.map((location) => {
                                return locations.push(location.title)
                            })
                        }
                        const tags = []
                        if (book.tags && book.tags.length) {
                            book.tags.map((tag) => {
                                return tags.push(tag.title)
                            })
                        }
                        const authors = []
                        if (book.authors && book.authors.length) {
                            book.authors.map((author) => {
                                return authors.push(author.fullName)
                            })
                        }
                        const narrators = []
                        if (book.narrators && book.narrators.length) {
                            book.narrators.map((narrator) => {
                                return narrators.push(`${narrator.firstName} ${narrator.lastName}`)
                            })
                        }

                        return items.push({
                            item_id: book.slug,
                            item_name: book.title,
                            item_brand: book.publisher ? book.publisher?.name : '',
                            item_category: book.category ? book.category?.name : '',
                            item_locations: locations?.length ? locations.join(', ') : '',
                            item_tags: tags?.length ? tags.join(', ') : '',
                            item_price: book.price,
                            item_author: authors?.length ? authors.join(', ') : '',
                            item_narrator: narrators?.length ? narrators.join(', ') : '',
                            item_translator: book.translator ? `${book.translator.firstName} ${book.translator.lastName}` : '',
                            item_length: `${book.lengthHours}:${book.lengthMin}`,
                        })
                    })

                    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                    window.dataLayer.push({
                        event: "view_item_list",
                        ecommerce: {
                            item_list_id: "search_audiobooks",
                            item_list_name: "Search Audiobooks",
                            items: items
                        }
                    });
                }
            });
        })
    };

    /**
     * handle sort menu
     */ 

    handleSortMenu = () => {
        this.setState({
            isSortMenu: !this.state.isSortMenu,
            filtersVisible: false,
        })
    }

    /**
     * handle sort by title
     */ 
    
    handleSort = (direction) => {
        this.setState({ 
            isSorting: true,
            isSortMenu: false,
            filtersVisible: false,
            direction: direction,
            page: 1,
        }, () => {
            if (direction) {
                this.setState({
                    sortValue: 'name'
                })
            } else {
                this.setState({
                    sortValue: 'relevance'
                })
            }
            this.filterApi(false, false, this.state.direction)
        })
    }

    /**
     * handle search
     */
    handleSearch = (e) => {
        this.setState({
            searchKeyword: e.target.value,
            isLoading: true,
            page: 1
        }, () => {
            if (e.target.value.length > 2) {
                this.apiCallSearch()
            }
        })

        if (e.target.value.length === 0) {
            this.handleResetSearchKeyword()
        }
    }

    /**
     * reset keyword
     */
    handleResetSearchKeyword = () => {
        this.setState({ 
            searchKeyword: '' 
        }, () => {
            this.filterApi(false, true, this.state.direction)
        });
    }

    /**
     * is typing
     */
    handleIsTyping = () => {
        this.setState({ 
            isTyping: !this.state.isTyping,
            filtersVisible: false,
            isSortMenu: false
        })
    }

    /**
     * Load more books
     */  
    loadMore = () => {
        this.setState({
            ...this.state,
            page: parseInt(this.state.page) + 1,
            isLoadingMore: true,
            isLoadMore: true,
        }, () => {
            if (this.state.isSorting) {
                this.filterApi(true, false, this.state.direction);
            } else {
                this.filterApi(true);
            }
        });
    };

    /**
     * Handle click on filter options
     */  
    handleFilter = (type, i, filterOption) => {
        this.setState({
            isLoadMore: true
        }, () => {
            let typeArr = [];
            let filtArr = [];
            let allFilters = this.state.allFilters;
        
            //set typeArr and filtArr depending on type
            if (type === "style") {
                typeArr = this.state.activeStyles;
                filtArr = this.state.stylesFilter;
            } else if (type === "categorie") {
                typeArr = this.state.activeCategories;
                filtArr = this.state.categoriesFilter;
            } else if (type === "location") {
                typeArr = this.state.activeLocations;
                filtArr = this.state.locationsFilter;
            } else if (type === "publisher") {
                typeArr = this.state.activePublishers;
                filtArr = this.state.publishersFilter;
            } else if (type === "nationalitie") {
                typeArr = this.state.activeNationalities;
                filtArr = this.state.nationalitiesFilter;
            }
        
            //if options already exists then remove it
            if (filtArr.includes(filterOption.id)) {
                const index = filtArr.indexOf(filterOption.id);
                const newArr = [...filtArr];
                const allFiltersIndex = allFilters.indexOf(filterOption);
                newArr.splice(index, 1);
                allFilters.splice(allFiltersIndex, 1);

                this.setState({
                    allFilters: allFilters
                })

                if (type === "style") {
                    this.setState({ stylesFilter: newArr }, () => {
                        this.filterApi(false, false, this.state.direction);
                    });
                } else if (type === "categorie") {
                    this.setState({ categoriesFilter: newArr }, () => {
                        this.filterApi(false, false, this.state.direction);
                });
                } else if (type === "location") {
                    this.setState({ locationsFilter: newArr }, () => {
                        this.filterApi(false, false, this.state.direction);
                });
                } else if (type === "publisher") {
                    this.setState({ publishersFilter: newArr }, () => {
                        this.filterApi(false, false, this.state.direction);
                });
                } else if (type === "nationalitie") {
                    this.setState({ nationalitiesFilter: newArr }, () => {
                        this.filterApi(false, false, this.state.direction);
                    });
                }
            } else {
                //if options doesn't exist, push to all filters and typeFilter
                const newArr = [...filtArr];
                newArr.push(filterOption.id);
                allFilters.push(filterOption);

                this.setState({
                    allFilters: allFilters
                })
        
                if (type === "style") {
                    this.setState({ stylesFilter: newArr }, () => {
                        this.filterApi(false, false, this.state.direction);
                    });
                } else if (type === "categorie") {
                    this.setState({ categoriesFilter: newArr }, () => {
                        this.filterApi(false, false, this.state.direction);
                    });
                } else if (type === "location") {
                    this.setState({ locationsFilter: newArr }, () => {
                        this.filterApi(false, false, this.state.direction);
                    });
                } else if (type === "publisher") {
                    this.setState({ publishersFilter: newArr }, () => {
                        this.filterApi(false, false, this.state.direction);
                    });
                } else if (type === "nationalitie") {
                    this.setState({ nationalitiesFilter: newArr }, () => {
                        this.filterApi(false, false, this.state.direction);
                    });
                }
            }
            if (typeArr.includes(i)) {
                //active state push if it doesn't exist
                const index = typeArr.indexOf(i);
                const newArr = [...typeArr];
                newArr.splice(index, 1);

                if (type === "style") {
                    this.setState({ activeStyles: newArr, page: 1 });
                } else if (type === "categorie") {
                    this.setState({ activeCategories: newArr, page: 1 });
                } else if (type === "location") {
                    this.setState({ activeLocations: newArr, page: 1 });
                } else if (type === "publisher") {
                    this.setState({ activePublishers: newArr, page: 1 });
                } else if (type === "nationalitie") {
                    this.setState({ activeNationalities: newArr, page: 1 });
                }
            } else {
                //active state push if it doesn't exist
                const newArr = [...typeArr];
                newArr.push(i);
                if (type === "style") {
                    this.setState({ activeStyles: newArr, page: 1 });
                } else if (type === "categorie") {
                    this.setState({ activeCategories: newArr, page: 1 });
                } else if (type === "location") {
                    this.setState({ activeLocations: newArr, page: 1 });
                } else if (type === "publisher") {
                    this.setState({ activePublishers: newArr, page: 1 });
                } else if (type === "nationalitie") {
                    this.setState({ activeNationalities: newArr, page: 1 });
                }
            }
        })
    };

    /**
     * Remove filter clicking on list of filters
     */  
    handleRemoveFilter = item => {
        this.setState({
            isLoadMore: true
        }, () => {
            const type = item['@type'];
            let typeArr = [];
            let filtArr = [];
            let allArr = [];
            let allFilters = this.state.allFilters;
            let filteredArr;

            if (type === 'Category') {
                typeArr = this.state.activeCategories;
                filtArr = this.state.categoriesFilter;
                allArr = this.state.categories;
            } else if (type === 'Style') {
                typeArr = this.state.activeStyles;
                filtArr = this.state.stylesFilter;
                allArr = this.state.styles;
            } else if (type === 'Nationality') {
                typeArr = this.state.activeNationalities;
                filtArr = this.state.nationalitiesFilter;
                allArr = this.state.nationalities;
            } else if (type === 'Publisher') {
                typeArr = this.state.activePublishers;
                filtArr = this.state.publishersFilter;
                allArr = this.state.publishers;
            } else if (type === 'Location') {
                typeArr = this.state.activeLocations;
                filtArr = this.state.locationsFilter;
                allArr = this.state.locations
            }
        
            filteredArr = allArr.filter(filteredItem => filteredItem.id === item.id)
            const index = allArr.indexOf(filteredArr[0])
            const index1 = filtArr.indexOf(item.id)
            const index2 = allFilters.indexOf(item);
            const typeArrIndex = typeArr.indexOf(index)
            typeArr.splice(typeArrIndex, 1);
            filtArr.splice(index1, 1);
            allFilters.splice(index2, 1);
        
            if (type === 'Category') {
                this.setState({
                    activeCategories: typeArr,
                    categoriesFilter: filtArr,
                    allFilters: allFilters
                }, () => {
                    this.filterApi(false, false, this.state.direction)
                })
                
            } else if (type === 'Style') {
                this.setState({
                    activeStyles: typeArr,
                    stylesFilter: filtArr,
                    allFilters: allFilters
                }, () => {
                    this.filterApi(false, false, this.state.direction)
                })
                
            } else if (type === 'Nationality') {
                this.setState({
                    activeNationalities: typeArr,
                    nationalitiesFilter: filtArr,
                    allFilters: allFilters
                }, () => {
                    this.filterApi(false, false, this.state.direction)
                })
                
            } else if (type === 'Publisher') {
                this.setState({
                    activePublishers: typeArr,
                    publishersFilter: filtArr,
                    allFilters: allFilters
                }, () => {
                    this.filterApi(false, false, this.state.direction)
                })
                
            } else if (type === 'Location') {
                this.setState({
                    activeLocations: typeArr,
                    locationsFilter: filtArr,
                    allFilters: allFilters
                }, () => {
                    this.filterApi(false, false, this.state.direction)
                })
            } else {
                this.setState({
                    lengthPagesFilter: 0,
                    lengthHoursFilter: 0,
                    allFilters: allFilters,
                }, () => {
                    this.filterApi(false, false, this.state.direction)
                })
            }
        })
    }

    /**
     * Reset all filters
     */  
    handleClearAllFilters = () => {
        this.setState({
            locationsFilter: [],
            activeLocations: [],
            publishersFilter: [],
            activePublishers: [],
            activeNationalities: [],
            nationalitiesFilter: [],
            stylesFilter: [],
            categoriesFilter: [],
            activeCategories: [],
            activeStyles: [],
            allFilters: [],
            lengthHoursFilter: 0,
            lengthPagesFilter: 0,
        }, () => {
            this.filterApi(false, false, this.state.direction);
        })
    }

    /**
     * Handle click on style filter
     */  
    handleStyleFilter = (style, i) => {
        this.handleFilter("style", i, style);
    };

    /**
     * Handle click on category filter
     */  
    handleCategorieFilter = (categorie, i) => {
        this.handleFilter("categorie", i, categorie);
    };

    /**
     * Handle click on location filter
     */  
    handleLocationsFilter = (location, i) => {
        this.handleFilter("location", i, location);
    };

    /**
     * Handle click on publisher filter
     */  
    handlePublishersFilter = (publisher, i) => {
        this.handleFilter("publisher", i, publisher);
    };

    /**
     * Handle click on author nationality filter
     */  
    handleNationalitiesFilter = (nationalitie, i) => {
        this.handleFilter("nationalitie", i, nationalitie);
    };

    /**
     * Handle click on hours filter
     */  
    handleHoursFilterChange = (length) => {
        this.setState({ 
            lengthHoursFilter: this.state.lengthHoursFilter === length ? 0 : length,
            page: 1 
        }, () => {
            const allFilters = [...this.state.allFilters]
            const index = allFilters.indexOf(this.state.lengthHoursFilter);
            let newArr = []
            
            if (index !== -1) {
                newArr = allFilters.filter( x => isNaN(x) );
            } else {
                newArr = allFilters.filter( x => isNaN(x) );

                if (this.state.lengthHoursFilter !== 0) {
                newArr.push(this.state.lengthHoursFilter);
                }
            }
            this.setState({
                allFilters: newArr
            }, () => {
                this.filterApi(false, false, this.state.direction);
            })
        });
    };

    /**
     * Handle click on hours filter
     */  
    handlePagesFilterChange = (length) => {
        this.setState({ 
            lengthPagesFilter: this.state.lengthPagesFilter === length ? 0 : length,
            page: 1 
        }, () => {
            const allFilters = [...this.state.allFilters]
            const index = allFilters.indexOf(this.state.lengthPagesFilter);
            let newArr = []
            
            if (index !== -1) {
                newArr = allFilters.filter( x => isNaN(x) );
            } else {
                newArr = allFilters.filter( x => isNaN(x) );

                if (this.state.lengthPagesFilter !== 0) {
                newArr.push(this.state.lengthPagesFilter);
                }
            }
            this.setState({
                allFilters: newArr
            }, () => {
                this.filterApi(false, false, this.state.direction);
            })
        });
    };

    /**
     * Handle toggle filters
     */  
    toggleFilters = () => {
        this.setState({ 
            filtersVisible: !this.state.filtersVisible,
            isSortMenu: false,
        });
    };

  render() {
    let books;
    let styles;
    let categories;
    let locations;
    let publishers;
    let nationalities;
    let lengthHours;
    let lengthPages;
    let allFilters;

    if (this.state.styles.length) {
      styles = this.state.styles.map((style, index) => {
        return (
            <StyledChipHolder key={`style-${index}`}>
                <StyledChip                    
                    className={this.state.activeStyles.includes(index) ? "active" : ""}            
                    onClick={() => this.handleStyleFilter(style, index, style.id)}
                >
                    {style.title}
                </StyledChip>
            </StyledChipHolder>
        );
      });
    }

    if (this.state.categories.length) {
      categories = this.state.categories.map((categorie, index) => {
        return (
            <StyledChipHolder key={`categorie-${index}`}>
                <StyledChip                   
                    className={
                        this.state.activeCategories.includes(index) ? "active" : ""
                    }            
                    onClick={() =>
                        this.handleCategorieFilter(categorie, index, categorie.id)
                    }
                >
                    {categorie.name}
                </StyledChip>
          </StyledChipHolder>
        );
      });
    }

    if (this.state.locations.length) {
      locations = this.state.locations.map((locations, index) => {
        return (
            <StyledChipHolder key={`location-${index}`}>
                <StyledChip                   
                    className={
                        this.state.activeLocations.includes(index) ? "active" : ""
                    }            
                    onClick={() =>
                        this.handleLocationsFilter(locations, index, locations.id)
                    }
                >
                    {locations.title}
                </StyledChip>
          </StyledChipHolder>
        );
      });
    }

    if (this.state.publishers.length) {
      publishers = this.state.publishers.map((publishers, index) => {
        return (
            <StyledChipHolder key={`publisher-${index}`}>
                <StyledChip                    
                    className={
                        this.state.activePublishers.includes(index) ? "active" : ""
                    }            
                    onClick={() =>
                        this.handlePublishersFilter(publishers, index, publishers.id)
                    }
                >
                    {publishers.name}
                </StyledChip>
          </StyledChipHolder>
        );
      });
    }

    if (this.state.nationalities.length) {
      nationalities = this.state.nationalities.map((nationalities, index) => {
        return (
            <StyledChipHolder key={`nationalitie-${index}`}>
                <StyledChip
                    className={
                        this.state.activeNationalities.includes(index) ? "active" : ""
                    }           
                    onClick={() =>
                        this.handleNationalitiesFilter(
                            nationalities,
                            index,
                            nationalities.id
                        )
                    }
                >
                    {nationalities.title}
                </StyledChip>
            </StyledChipHolder>
        );
      });
    }

    if (this.state.lengthHours.length) {
      lengthHours = this.state.lengthHours.map((length, index) => {
        return (
            <StyledChipHolder key={`length-${index}`}>
                <StyledRadio>
                    <input
                        id={`length-${length}`}
                        type="checkbox"
                        name="length"
                        value={length}
                        checked={this.state.lengthHoursFilter === length}
                        onChange={(e) => this.handleHoursFilterChange(length)}
                    />

                    <label htmlFor={`length-${length}`}>
                        {length === 9 ? `>` : `>`} {length} {length === 1 ? "hr" :  "hrs"}
                    </label>
                </StyledRadio>
            </StyledChipHolder>
        );
      });
    }

    if (this.state.lengthPages.length) {
      lengthPages = this.state.lengthPages.map((length, index) => {
        return (
            <StyledChipHolder key={`length-${index}`}>
                <StyledRadio>
                    <input
                        id={`length-${length}`}
                        type="checkbox"
                        name="length"
                        value={length}
                        checked={this.state.lengthPagesFilter === length}
                        onChange={(e) => this.handlePagesFilterChange(length)}
                    />

                    <label htmlFor={`length-${length}`}>
                        {length === 900 ? `>` : `>`} {length} {length === 1 ? "page" :  "pages"}
                    </label>
                </StyledRadio>
            </StyledChipHolder>
        );
      });
    }

    if (this.state.books.length > 0) {
      books = this.state.books.map((book, index) => {
        return (
          <Grid item xs={6} md={4} lg={3} key={`book-${index}`}>
            <AudioBookListItem
              title={book.title}
              imgSrc={book.cover}
              imgAlt={book.coverAlt}
              price={book.price}
              subscriptionPrice={book.subscriptionPrice}
              authors={book.authors}
              audioBook={book.audioBook}
              eBook={book.eBook}
              type={book.entityType}
              link={`/${book.entityType}/${book.slug}`}
              availableAsCard={book.availableAsCard}
            />
          </Grid>
        );
      });
    }

    if (this.state.allFilters.length) {
      allFilters = this.state.allFilters.map((item, index) => {
        if (item) {
          return (
            <StyledChip
              labelWithIcon
              detached
              key={`filter-${index}`}
              onClick={() => this.handleRemoveFilter(item)}
            >
              {item.title ? item.title : item.name ? item.name : `${item === 9 ? '>' : '>'} ${item} ${item > 99 ? 'pages' : 'hrs'}`}
              <i className="icon-close-small no-animation" />
            </StyledChip>
          );
        } else {
          return null
        }
      })
    }

    return (
      <SearchContainer className="container container__big container__big--withPadding" direction={!this.state.isSortMenu} hide={this.state.filtersVisible}>
        <PageMeta>
            <title>Searched Books | Spiracle</title>
            <link rel="canonical" href={window.location.href} />
        </PageMeta>

        <InputContainer className='c-navigation__search-navigation__input margin-center'>
            <Input type='text' placeholder='SEARCH' value={this.state.searchKeyword}
                    onChange={(e) => this.handleSearch(e)}  onFocus={this.handleIsTyping} onBlur={this.handleIsTyping} />

            {this.state.searchKeyword.length > 0 && 
                <i className='icon-close font-11 font-black' onClick={this.handleResetSearchKeyword}></i>}
        </InputContainer>

        {(this.state.searchKeyword.length > 2 && this.state.isLoading) ? (
          <SkeletonAudioBooks withFilter={true} withSort={true}/>
        ) : (
          <>
            <FiltersToggle className='h-flex-between' style={{position: 'relative'}}>
                <SortButton onClick={this.handleSortMenu}>sort by {this.state.sortValue}<i className='icon-arrow no-hover'></i></SortButton>
                {this.state.isSortMenu && <SortMenu>
                    <div onClick={() => this.handleSort()}>Relevance</div>
                    <div onClick={() => this.handleSort('asc')}>Name</div>
                </SortMenu>}
              {(!this.state.filtersVisible || this.state.isTyping) ? (
                <StyledChip small detached onClick={() => this.toggleFilters()}>
                  Filter
                </StyledChip>
              ) : (
                  <>
                    <Hidden mdUp>
                        <span className="pl-42">Filter</span>
                    </Hidden>
                    <i
                    className="icon-close mr-24"
                    style={{ verticalAlign: "bottom" }}
                    onClick={() => this.toggleFilters()}
                    />
                </>
              )}
            </FiltersToggle>
            {!this.state.isTyping && <Filters
              ref={this.filtersWrapper}
              className={this.state.filtersVisible ? "visible" : "hidden"}
              height={this.state.filtersHeight}
              
            >
              <div className="filters-wrapper">
                <h2>Style</h2>
                {styles && (
                  <AudioBookSingleFilter
                    filtersId={'stylesContainer'}
                    filters={styles}                    
                  />
                )}

                <h2>Category</h2>
                {categories && (
                  <AudioBookSingleFilter
                    filtersId={'categoriesContainer'}
                    filters={categories}                    
                  />
                )}

                <h2>Geographical setting</h2>
                {locations && (
                  <AudioBookSingleFilter
                    filtersId={'locationsContainer'}
                    filters={locations}                    
                  />
                )}

                <h2 className="c-filter__heading">LENGTH</h2>
                {lengthHours && (
                  <AudioBookSingleFilter
                    filtersId={'lengthsHoursContainer'}
                    filters={lengthHours}                    
                  />
                )}

                <h2 className="c-filter__heading">PAGES</h2>
                {lengthPages && (
                  <AudioBookSingleFilter
                    filtersId={'lengthsPagesContainer'}
                    filters={lengthPages}                    
                  />
                )}

                <h2>Author nationality</h2>
                {nationalities  && (
                    <AudioBookSingleFilter
                        filtersId={'nationalitiesContainer'}
                        filters={nationalities}                        
                    />
                )} 

                <h2>Publisher</h2>
                {publishers && (
                  <AudioBookSingleFilter
                    filtersId={'publishersContainer'}
                    filters={publishers}                   
                  />
                )}
              </div>
            </Filters>
            }
            <FilterResultsInfo>
                <span className={`font-13 font-uppercase ${(this.state.numberOfBooks > 0 || window.innerWidth < 768) && 'mr-25'} font-secondary letter-spacing-15`}>
                  {this.state.searchKeyword.length > 2 && this.state.isLoadingMore ? <span style={{display: 'inline-block', width: '55px'}} className="c-skeleton__audio-book--listing--bookNumber"></span> : 
                    this.state.numberOfBooks > 0 && (this.state.numberOfBooks === 1
                      ? `books (${this.state.numberOfBooks} result)`
                      : `books (${this.state.numberOfBooks} results)`
                     )
                  }
                </span>
                
                {this.state.allFilters.length > 0 &&
                    <StyledChip onClick={() => this.handleClearAllFilters()} circle detached className="mr-12" style={{marginLeft: '0'}}> 
                        <i className="icon-close-small no-animation" />
                    </StyledChip>
                }
               
                {allFilters}
            </FilterResultsInfo>

            {(this.state.searchKeyword.length > 2 || !this.state.isTyping) && this.state.isLoadingMore && <SkeletonAudioBooks />}

            {this.state.numberOfBooks ? (
                <>
                    <Grid container spacing={2}>
                        {books}
                    </Grid> 
                    {this.state.isLoadingMore && <SkeletonAudioBooks />}
                </>
            ) : ((this.state.searchKeyword.length > 2 || !this.state.isTyping) && this.state.numberOfBooks < 1) && !this.state.isLoading && (
                <EmptyPage>
                    <p>Sorry, no results.</p>
                    <p>
                    Try again or visit our{" "}
                    <Link id='gtm-search-books-collection' to="/audiobooks" className="font-secondary">
                        collection
                    </Link>
                    </p>
                </EmptyPage>
            )}

            {this.state.haveMore ? (
              <div className="text-center mt-30">
                <button
                  id='gtm-search-books-load-more'
                  onClick={() => this.loadMore()}
                  className="c-btn c-btn__ghost text-center"
                >
                  load more
                </button>
              </div>
            ) : null}
          </>
        )}
      </SearchContainer>
    );
  }
}

export default SearchBooks;