import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AudioBook from 'components/UI/Redemption/AudioBook/AudioBook';

import { Buttons, Paragraph, ParagraphSmall,  StyledContent, StyledRedemptionContainer, Title } from './style';


function RedemptionConfirmation() {
    const history = useHistory();

    const redemptionAudioBook = useSelector(state => state.redemptionReducer.redemptionAudioBook)
    const redemptionBookshop = useSelector(state => state.redemptionReducer.redemptionCode.bookshop)
    const code = useSelector(state => state.redemptionReducer.redemptionCode.code)

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!code || token) {
            history.push('/redemption')
        }
    }, [code, history])

    return (
        <StyledRedemptionContainer>
            <AudioBook data={redemptionAudioBook} showBg/>

            <StyledContent>
                <Title>Congratulations</Title>
                <Paragraph>{redemptionAudioBook?.title} <span className='font-secondary'>is ready for you</span>.</Paragraph>

                <Buttons>
                    <Link 
                        id='gtm-redemption-login'
                        to='/redemption/login'
                        className='c-btn c-btn__black font-secondary font-14 letter-spacing-15 font-uppercase text-center'>
                            Log in
                    </Link>
                    <Link 
                        id='gtm-redemption-signup'
                        to='/redemption/sign-up'
                        className='c-btn c-btn__ghost font-secondary font-14 letter-spacing-15 font-uppercase text-center'>
                            Create an account
                    </Link>
                </Buttons>

                <ParagraphSmall className='mt-50'>
                    Your online Library <i className='icon-library font-14 font-black'></i> stores all your audiobooks.
                    You can listen to them directly via the online Spiracle Player, or download the Spiracle Player App for <span>iOS</span> or <span>Android</span>. 
                    Happy listening!
                    Grow your collection with new audiobooks from {redemptionBookshop} and Spiracle Audiobooks.</ParagraphSmall>
            </StyledContent>
        </StyledRedemptionContainer>
    )
}

export default RedemptionConfirmation