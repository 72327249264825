import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';

import ApiService from '../../services/api.service';
import { Link } from 'react-router-dom';
import SpiracleLogo from '../../images/Spiracle-small.svg';
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';
import { connect } from 'react-redux';
import { calculateFooterHeight } from 'shared/Functions/CalculateFooterHeight';

class Footer extends Component {
    state = {
        error: false,
        subscribed: false,
        checkBoxSubscribed: false,
        linksMenuList: [],
        footerHeight: null,
    };

    componentDidMount() {
        ApiService.menuEntries().then(
            r => {
                this.setState({
                    linksMenuList: r.data['hydra:member'].filter(cat => cat.category === 'Links')
                })
            }
        )
        this.setState({
            footerHeight: calculateFooterHeight()
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            if (this.props.user && this.props.user.newsletter) {
                this.setState(
                    {
                        checkBoxSubscribed: true,
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({
                                checkBoxSubscribed: false,
                            });
                        }, 10000);
                    }
                );
            } else {
                this.setState({
                    checkBoxSubscribed: false,
                });
            }
        }
        if(prevProps.widgetPlayer !== this.props.widgetPlayer) {
            this.setState({
                footerHeight: calculateFooterHeight()
            })
        }
    }

    /**
     * Handle subscribe to newsletter
     */
    submitSubscribeToNewsletter = (values, callbacks) => {
        this.setState(
            {
                error: false,
            },
            () => {
                const data = {
                    email: values.newsletterEmail,
                };

                const { setSubmitting } = callbacks;

                ApiService.subscribeToNewsletter(data)
                    .then((r) => {
                        setSubmitting(false);
                        this.setState(
                            {
                                subscribed: true,
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState({
                                        subscribed: false,
                                    });
                                }, 10000);
                            }
                        );
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        this.setState({
                            error: true,
                        });
                    });
            }
        );
    };

    handleScrollToTop = () => {
        window.scrollTo(0,0);
    }

    render() {
        let newsletter;
        let menuLinkList;
        if (this.state.linksMenuList) {
            menuLinkList = this.state.linksMenuList.map((link, index) => (
                <li key={`link-${index}`}>
                    <Link 
                        id={`gtm-footer-link-${link.title.toString().toLowerCase().replace(/ /g, '-')}`}
                        className='c-navigation__burger-navigation--footer--item font-12 font-uppercase font-secondary letter-spacing-15 animated-underline' 
                        to={link.url}
                        onClick={this.handleScrollToTop}
                        >
                        {link.title}
                    </Link>
                </li>
            ))
        }

        //** FORMIK SUBSCRIBE TO NEWSLETTER */
        const forgotPasswordValidationSchema = Yup.object().shape({
            newsletterEmail: Yup.string().email('Email must be valid').required('Email is required'),
        });

        const forgotPasswordInitialValues = {
            newsletterEmail: '',
        };
        //** FORMIK SUBSCRIBE TO NEWSLETTER END */
        if (this.state.checkBoxSubscribed) {
            if (this.props && this.props.user && this.props.user.newsletter) {
                newsletter = (
                    <>
                        <p className='font-italic font-18 mb-1 text-center'>Subscribed to Newsletter</p>
                    </>
                );
            } else {
                newsletter = null;
            }
        } else if (this.props && this.props.user && this.props.user.newsletter) {
            newsletter = null;
        } else if (this.state.subscribed === true) {
            newsletter = (
                <>
                    <p className='font-italic font-18 mb-1 text-center'>Subscribed to Newsletter</p>
                </>
            );
        } else {
            newsletter = (
                <>
                    <p className='text-center font-italic lh-24px mb-38'>
                        Sign up to the newsletter. 
                    </p>
                    <Formik initialValues={forgotPasswordInitialValues} validateOnMount={true} validationSchema={forgotPasswordValidationSchema} onSubmit={this.submitSubscribeToNewsletter}>
                        {(form) => {
                            return (
                                <Form className='c-form'>
                                    <div>
                                        <Field
                                            className={`bg-white ${form.errors && form.errors.newsletterEmail && form.touched && form.touched.newsletterEmail ? 'invalid' : ''}`}
                                            placeholder='Your email'
                                            type='email'
                                            name='newsletterEmail'
                                            required
                                            id='newsletterEmail'
                                        />
                                    </div>
                                    <div className='c-error-message mt-5'>
                                        <ErrorMessage name='newsletterEmail' component='div' />
                                        {this.state.error ? <div>Something went wrong, please try again later</div> : null}
                                    </div>
                                    <div className='mt-25 c-form__btn-container'>
                                        <button
                                            id='gtm-newsletter-sign-up'
                                            className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase mb-28 ${
                                                form.isSubmitting ? 'submitting' : ''
                                            }`}
                                            type='submit'
                                            disabled={form.isSubmitting || !form.isValid}>
                                            Sign up
                                            <SpiracleLogoSmall />
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            );
        }
        return (
            <footer className={`${this.state.footerHeight > 70 && "c-footer__bigMargin"} c-footer h-flex h-flex-between h-flex-align-end`}>
                <ul className='c-footer__link-list'>
                    <li>
                        <Link id='gtm-footer-spiracle-logo' to='/' onClick={this.handleScrollToTop}>
                            <img src={SpiracleLogo} alt='spiracle-logo' />
                        </Link>
                    </li>
                    <li>
                        <Link 
                            id='gtm-footer-link-reseller-portal'
                            to='/reseller' 
                            className='c-navigation__burger-navigation--footer--item font-12 font-uppercase font-secondary letter-spacing-15 animated-underline'
                             onClick={this.handleScrollToTop}
                        >
                            Reseller portal
                        </Link>
                    </li>
                    {menuLinkList}
                </ul>

                <ul className='c-footer__social-list'>
                    <li>
                        <a className='icon' href='https://twitter.com/SpiracleHQ' target='_blank' rel='noreferrer'>
                            <i className='icon-twitter-new' />
                        </a>
                    </li>
                    <li>
                        <a className='icon' href='https://www.instagram.com/spiraclehq/' target='_blank' rel='noreferrer'>
                            <i className='icon-instagram' />
                        </a>
                    </li>
                    <li>
                        <a className='icon' href='https://www.facebook.com/Spiraclehq' target='_blank' rel='noreferrer'>
                            <i className='icon-facebook' />
                        </a>
                    </li>
                    <li>
                        <a className='icon' href='https://www.linkedin.com/company/spiracle-audiobooks' target='_blank' rel='noreferrer'>
                            <i className='icon-linkedin' />
                        </a>
                    </li>
                </ul>

                <div className='c-footer__newsletter'>{newsletter}</div>
            </footer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
    widgetPlayer: state.bookReducer.widgetPlayer,
});

export default connect(mapStateToProps)(Footer);
