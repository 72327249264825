import React, { Component } from 'react'
import ApiService from '../../services/api.service';
import PageMeta from '../../shared/PageMeta/PageMeta';
import SkeletonAudiobookEdition from "../../components/UI/Skeleton/SkeletonAudiobookEdition/SkeletonAudiobookEdition";
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';
import TitleTextBlock from 'shared/PageElements/TitleTextBlock';
import AudiobookEditionItem from "components/UI/AudiobookEdition/AudiobookEditionItem";

class AudiobookEdition extends Component {
    state = {
        _page: 1,
        itemsPerPage: 5,
        loadButton: false,
        moreAudiobooks: false, 
        audiobookEdition: [],
        isLoading: true
     }

    componentDidMount() {
        this.getAudiobookEdition()
    }

    handleLoadMoreAudiobooks = () => {
        this.setState({
            _page: this.state._page + 1,
            loadButton: true,
        }, () => {
            this.getAudiobookEdition()
        })
    }

    handleResponse = (r) => {
        if (r.data['hydra:member'].length) {
            this.setState({
                audiobookEdition: [...this.state.audiobookEdition, ...r.data['hydra:member']],
                loadButton: false,
                isLoading: false,
            }, () => {
                if (this.state.audiobookEdition.length < r.data['hydra:member'][0]?.totalItems) {
                    this.setState({
                        moreAudiobooks: true,
                    })
                } else {
                    this.setState({
                        moreAudiobooks: false,
                    })
                }

                if (window.dataLayer) {
                    const items = [];
                    r.data['hydra:member'].map((book, index) => {
                        const locations = []
                        if (book.audioBook.locations && book.audioBook.locations.length) {
                            book.audioBook.locations.map((location) => {
                                return locations.push(location.title)
                            })
                        }
                        const tags = []
                        if (book.audioBook.tags && book.audioBook.tags.length) {
                            book.audioBook.tags.map((tag) => {
                                return tags.push(tag.title)
                            })
                        }
                        const authors = []
                        if (book.audioBook.authors && book.audioBook.authors.length) {
                            book.audioBook.authors.map((author) => {
                                return authors.push(author.fullName)
                            })
                        }
                        const narrators = []
                        if (book.audioBook.narrators && book.audioBook.narrators.length) {
                            book.audioBook.narrators.map((narrator) => {
                                return narrators.push(`${narrator.firstName} ${narrator.lastName}`)
                            })
                        }
                        return items.push({
                            item_id: book.audioBook.slug,
                            item_name: book.audioBook.title,
                            item_brand: book.audioBook.publisher ? book.audioBook.publisher?.name : '',
                            item_category: book.audioBook.category ? book.audioBook.category?.name : '',
                            item_locations: locations?.length ? locations.join(', ') : '',
                            item_tags: tags?.length ? tags.join(', ') : '',
                            item_price: book.audioBook.price,
                            item_author: authors?.length ? authors.join(', ') : '',
                            item_narrator: narrators?.length ? narrators.join(', ') : '',
                            item_translator: book.audioBook.translator ? `${book.audioBook.translator.firstName} ${book.audioBook.translator.lastName}` : '',
                            item_length: `${book.audioBook.lengthHours}:${book.audioBook.lengthMin}`,
                        })
                    })

                    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                    window.dataLayer.push({
                        event: "view_item_list",
                        ecommerce: {
                            item_list_id: "spiracle_editions",
                            item_list_name: "Spiracle editions",
                            items: items
                        }
                    });
                }
            })
        } else {
            this.setState({
                moreAudiobooks: false,
                loadButton: false,
                isLoading: false,
            })
        }
    }

    getAudiobookEdition = () => {
        const params = new URLSearchParams();

        params.append('_page', this.state._page);
        params.append('itemsPerPage', this.state.itemsPerPage);

        this.props.history.replace(`/editions?${params.toString()}`)

        ApiService.getAudioBookEdition(params).then(r => this.handleResponse(r))
    }

    render() {
        return (
            <>
                <PageMeta>
                    <title>Editions | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
                <div className="container__big--withPadding">
                    <TitleTextBlock 
                        editions
                        title={<>Spiracle <span className="font-italic">editions</span></>}
                        text="Original titles co-published with the UKs independent publishers."
                        subtext={<><span className="font-italic">If you only read the books that everyone else is reading, you can only think what everyone else is thinking &#8212;</span> Haruki Murakami</>}
                    /> 
                </div>                  
                {this.state.isLoading ? <SkeletonAudiobookEdition /> : (
                    this.state.audiobookEdition.length > 0 &&
                        <div className="container container__big" > 
                            {this.state.audiobookEdition.map((audiobook, index) => (                            
                                <AudiobookEditionItem 
                                    key={`audiobook-${index}`}
                                    link={`/audiobooks/${audiobook.audioBook.slug}`}
                                    cover={audiobook.audioBook.cover} 
                                    coverAlt={audiobook.audioBook.coverAlt} 
                                    title={audiobook.audioBook.title}     
                                    summary={audiobook.audioBook.summary}
                                    authors={audiobook.audioBook.authors}
                                    price={audiobook.audioBook.price}
                                    subscriptionPrice={audiobook.audioBook.subscriptionPrice}
                                    eBook={audiobook.audioBook.eBook}
                                    bookBgColor={audiobook.audioBook.bookBackgroundColour} 
                                    containerBgColor={audiobook.audioBook.containerBackgroundColour}
                                    textColor={audiobook.audioBook.textColour}
                                    availableAsCard={audiobook.audioBook.availableAsCard}
                                />                            
                            ))}
                        </div> 
                )}

                {this.state.moreAudiobooks && <div className="text-center">
                    <button 
                        id='gtm-spiracle-editions-load-more'
                        onClick={this.handleLoadMoreAudiobooks} 
                        disabled={this.state.loadButton}
                        className={`c-btn c-btn__ghost font-22 font-secondary font-italic mt-33 mb-50 ls-2 ${this.state.loadButton ? 'submitting' : ''}`}>
                            load more
                            <SpiracleLogoSmall />
                    </button>
                </div>}               
            </>
        );
    }
}

export default AudiobookEdition;