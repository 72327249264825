import { useEffect, useState } from 'react';
import ApiService from 'services/api.service';
import Loader from 'shared/Loader/Loader';
import { ReactComponent as SpiracleLogoSmall } from '../../../../images/Spiral.svg';
import { Message, MessageError, StyledCardActivation } from './style';

function CardActivation() {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSettings, setIsLoadingSettings] = useState(true);
    const [data, setData] = useState(null);
    const [activationErrorMessage, setActivationErrorMessage] = useState(null);
    const [isEmailRequired, setIsEmailRequired] = useState(null);

    const [state, setState] = useState({
        email: '',
        code: '',
    });
    const [validationState, setValidationState] = useState({
        email: true,
        code: true,
    })

    useEffect(() => {
        ApiService.getSettings(1).then(r => {
            setIsEmailRequired(r.data.requiredPurchaserEmail);
            setIsLoadingSettings(false);
        }).catch(e => {
            console.log(e)
            setIsLoadingSettings(false);
        })   
    }, [])

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const handleChange = e => {
        const value = e.target.value.trim();
        setState({
            ...state,
            [e.target.name]: value
        });
    }

    const handleBlur = e => {
        const value = e.target.value.trim();
        if (e.target.name === 'email') {
            setValidationState({
                ...validationState,
                [e.target.name]: regex.test(String(value).toLowerCase())
            })
        } else {
            setValidationState({
                ...validationState,
                [e.target.name]: value.length > 0 ? true : false
            })
        }
    }

    const handleSubmit = () => {
        setIsLoading(true)

        const data = {
            buyerEmail: state.email ? state.email : null,
            token: state.code
        }

        ApiService.activateAudiobookCard(data).then(r => {
            setActivationErrorMessage(null)
            setIsLoading(false)
            setData(r.data);
        }).catch(e => {
            const msg = e.response.data['hydra:description'] || e.response.data['message'] || e.response.statusText
            const betterMsg = msg?.replaceAll('_', ' ').toLowerCase()
            setActivationErrorMessage(betterMsg)
            setData(null)
            setIsLoading(false)
        })
    }

    const emailValidationFalse = isEmailRequired ? !regex.test(String(state.email).toLowerCase()) : state.email.length > 0 && !regex.test(String(state.email).toLowerCase())

    return (
        isLoadingSettings ? <Loader /> : 
        <StyledCardActivation className='mt-90 font-secondary'>
            <h1 className='text-center'>Activate card</h1>

            <div>
                <label htmlFor="email">Buyer email{isEmailRequired ? '*' : ''}</label>
                <input
                    className={`bg-white mb-30 ${isEmailRequired && !validationState.email ? 'invalid' : ''}`}
                    type='email'
                    name='email'
                    id='email'
                    value={state.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>

            <div>
                <label htmlFor="code">Activation code</label>
                <input
                    className={`bg-white mb-30 ${!validationState.code ? 'invalid' : ''}`}
                    type='text'
                    name='code'
                    id='code'
                    value={state.code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>

            <button
                id='gtm-activate-card'
                disabled={
                    isLoading ||
                    emailValidationFalse ||
                    !validationState.code ||
                    !state.code.length
                }
                className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-14 letter-spacing-15 font-uppercase text-center mt-10 letter-spacing-15 ${isLoading ? 'submitting' : ''}`}
                onClick={handleSubmit}
            >
                Activate card
                <SpiracleLogoSmall />
            </button>
            {
                !isLoading && (
                    <>
                        {activationErrorMessage ? <MessageError>{activationErrorMessage}</MessageError> : null}
                        {data?.activated ? <Message>The card has been activated.</Message> : null}
                    </>
                ) 
            }
        </StyledCardActivation>
    )
}

export default CardActivation