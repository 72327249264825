import axios from 'axios';
import axiosRetry from 'axios-retry';
import config from '../config';
import { cancelToken } from '../pages/AudioBookPlayer/AudioBookPlayer';

let refreshTokenPromise = null;

axios.defaults.baseURL = `${config.apiGateway.URL}/api`;

// set header token
axios.interceptors.request.use(request => {
    // Do something before request is sent

    let modifiedRequest = {...request};
    const token = localStorage.getItem('token');

    if (token) {
        modifiedRequest.headers = { ...modifiedRequest.headers, xtoken: 'Bearer ' + token }
        localStorage.setItem('tokenRefreshedDate', new Date());
    }

    return modifiedRequest;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  // remove token when session is expired
axios.interceptors.response.use(response => {
    // set token for every reposnse
    return response;
    }, err => {
        const originalRequest = err.config;
        const newAxiosInstance = axios.create();

        if (err.response && err.response.status >= 500 && window.location.pathname !== '/server-error') {
            window.location.href = window.location.origin +  '/server-error'
        }

        if (err.response && err.response.status === 401 && !originalRequest._retry) {
            const refreshToken = localStorage.getItem('refresh_token');
            originalRequest._retry = true;

            if (refreshToken) {
                if (!refreshTokenPromise) { 
                    // get new token
                    refreshTokenPromise = newAxiosInstance.post('/token/refresh', { refresh_token: refreshToken }) 
                }
                
                return refreshTokenPromise.then(
                    response => {
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('tokenRefreshedDate', new Date());
                        localStorage.setItem('refresh_token', response.data.refresh_token);
                        refreshTokenPromise = null;
                        // window.location.reload();
                        axiosRetry(axios, { retries: 1 });
                        return axios(originalRequest);
                    }
                    ).catch(
                        error => {
                            localStorage.removeItem('token');
                            localStorage.removeItem('refresh_token');
                            localStorage.removeItem('tokenRefreshedDate');
                            window.location.href = window.location.origin +  '/login'
                        }
                    )
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('tokenRefreshedDate');
                // don't know why this was here
                // it will refresh the page every
                // time you fail on login
                // window.location.reload();
            }

        }

        return Promise.reject(err);
    }
);

class ApiService {
    static registerUser(data) {
        return axios.post('users', data)
    }

    static login(data) {
        return axios.post('login', data);
    }

    static confirmUser(data) {
        return axios.post('user_confirmation/confirm', data);
    }

    static getUser() {
        return axios.get('logged-user');
    }

    static forgotPassword(data) {
        return axios.post('forgot_passwords', data)
    }

    static updateSettings(data, id) {
        return axios.patch(`users/${id}`, data, {
            headers: { 'Content-type': 'application/merge-patch+json' }
        })
    }

    static newsletterUpdate(data, id) {
        return axios.patch(`users/${id}/newsletter-update`, data, {
            headers: { 'Content-type': 'application/merge-patch+json' }
        })
    }

    static promotionsUpdate(data, id) {
        return axios.patch(`users/${id}/promotion-update`, data, {
            headers: { 'Content-type': 'application/merge-patch+json' }
        })
    }

    static changePassword(data, id) {
        return axios.put(`users/${id}/change-password`, data)
    }

    static subscribeToNewsletter(data) {
        return axios.post('newsletters', data)
    }

    static forgotPasswordChange(uuid, data) {
        return axios.patch(`forgot_passwords/${uuid}`, data, {
            headers: { 'Content-type': 'application/merge-patch+json' }
        })
    }

    static deleteAccount(uuid) {
        return axios.post(`users/${uuid}/delete-account`, {}, {
            headers: { 'Content-Type': 'application/ld+json' }
        })
    }

    static infoPages(infoPage) {
        return axios.get(`info_pages/${infoPage}`);
    }

    static faqCategories() {
        return axios.get('f_a_q_categories');
    }

    static getArticles(params) {
        return axios.get('articles', {params: params});
    }

    static getArticleSingle(slug) {
        return axios.get(`articles/${slug}`);
    }

    static getDiscussions(params) {
        return axios.get('discussions', {params: params});
    }

    static getInterviews(params) {
        return axios.get('interviews', {params: params});
    }

    static getDiscussionsSingle(slug) {
        return axios.get(`discussions/${slug}`);
    }

    static getInterviewsSingle(slug) {
        return axios.get(`interviews/${slug}`);
    }

    static downloadAudioFile(link) {
        if (cancelToken) {
            return axios.get(link, {
                cancelToken: cancelToken.token,
                responseType: 'blob'
            });
        } else {
             return axios.get(link, {
                responseType: 'blob'
            });
        }
    }

    static audioBooks(params, type) {
        return axios.get(`${type === 'ebooks' ? 'e_books' : 'audio_books'}`, {params: params});
    }

    static getAudioBookBySlug(slug, type) {
        return axios.get(`${type === 'ebook' ? 'e_books' : 'audio_books'}/${slug}`);
    }

    static getAudioBookRelated(slug, type) {
        return axios.get(`${type === "ebook" ? 'e' : 'audio'}_books/${slug}/tag_related`);
    }

    static getAudioBookEdition(params) {
        //return axios.get('audio_books', {params: params})
        return axios.get('searches?&search[]=audiobooks&edition=true',{params: params})
    }

    static getSecondEditions(params) {
        return axios.get('audio_books/second_editions',{params: params})
    }

    static getSecondEditionsStringParams(stringParams) {
        return axios.get(`audio_books/second_editions${stringParams}`)
    }

    static styles(params) {
        return axios.get('styles', {params: params})
    }

    static categories(params) {
        return axios.get('categories', {params: params})
    }

    static locations(params) {
        return axios.get('locations', {params: params})
    }

    static publishers(params) {
        return axios.get('publishers', {params: params})
    }

    static nationalities(params) {
        return axios.get('nationalities', {params: params})
    }

    static chapterTimes(params) {
        return axios.get('user_chapter_times', {params: params})
    }

    static downloadAudioFiles(link) {
        return axios.get(link, {
            headers: { 'Content-type': 'audio/mpeg' }
        });
    }

    static userChapterTimes(params) {
        return axios.get('user_chapter_times', {params: params})
    }

    static setFirstChapterTime(data) {
        return axios.post('user_chapter_times', data)
    }

    static patchChapterTime(id, data) {
        return axios.patch(`user_chapter_times/${id}`, data, {
            headers: { 'Content-type': 'application/merge-patch+json' }
        })
    }

    static downloadAudioBook(link) {
       return axios.get(link, {responseType: 'arraybuffer'})
    }

    static getAudioTimes() {
        return axios.get('user_audio_book_times?itemsPerPage=1000');
    }

    static createAudioTime(data) {
        return axios.post('user_audio_book_times', data)
    }

    static patchAudioTime(data, id) {
        return axios.patch(`user_audio_book_times/${id}`, data, {
            headers: { 'Content-type': 'application/merge-patch+json' }
        })
    }

    static getMurmurations(params) {
        return axios.get('searches', {params: params})
    }

    static getPublisherBySlug(slug) {
        return axios.get(`publishers/${slug}`)
    }

    static getPublisherAudioBooks(slug, params) {
        return axios.get(`publishers/${slug}/audio_books`, {params: params})
    }

    static typesMurmurations(params) {
        return axios.get('types/murmurations', {params: params})
    }

    static getHomepageBasic(params) {
        return axios.get('home_panels_basic', {params: params})
    }

    static getHomepageLatestAudioBook(params) {
        // return axios.get('home_panels_latest_audio_book', {params: params})
        return axios.get('audio_books/latest', {params: params})
    }

    static getHomepageLatestEdition(params) {
        // return axios.get('home_panels_latest_audio_book_edition', {params: params})
        return axios.get('audio_books/latest?edition=1', {params: params})
    }

    static getHomepageFeaturedAudioBooks(params) {
        return axios.get('home_panels_featured_audio_books', {params: params})
    }

    static getHomepageGuestPick(params) {
        return axios.get('home_panels_guest_pick', {params: params})
    }

    static getHomepagePublisher(params) {
        return axios.get('home_panels_publisher', {params: params})
    }

    static getHomepageMurmuration(params) {
        return axios.get('murmuration_blocks', {params: params})
    }

    static getHomepagePromo(params) {
        return axios.get('promo_blocks', {params: params})
    }

    static getHomepagePanels(params) {
        return axios.get('homepage_panels', {params: params})
    }

    static getStaffPicks(params) {
        return axios.get('staff_pick_blocks', {params: params})
    }

    static menuEntries() {
        return axios.get('menu_entries');
    }

    static createCart(data) {
        return axios.post('cart_items', data)
    }

    static addToExistingCart(data) {
        return axios.post('cart_items', data)
    }

    static getCartItems(uuid) {
        return axios.get(`carts/${uuid}/cart_items`)
    }

    static deleteCartItem(uuid) {
        return axios.delete(`cart_items/${uuid}`)
    }

    static updateCartId(uuid, data) {
        return axios.post(`users/${uuid}/cart-update`, data)
    }

    static updateCart(cartId) {
        return axios.post(`carts/${cartId}/apply-user`, {}, {
            headers: { 'Content-Type': 'application/ld+json' }
        });
    }

    static createPaymentIntent(uuid, data) {
        return axios.post(`carts/${uuid}/checkout`, data, {
            headers: { 'Content-type': 'application/ld+json' }
        });
    }

    static registerUserOnPayment(uuid, data) {
        return axios.post(`carts/${uuid}/register`, data)
    }

    static getLibrary(params) {
        return axios.get('libraries',{params: params})
    }

    static getUserOrderList(params) {
        return axios.get(`order_carts`, {params: params})
    }

    static getOrder(uuid) {
        return axios.get(`order_carts/${uuid}`)
    }

    static getAllPublishers(params) {
        return axios.get('publishers', {params: params});
    }

    static downloadEBook(url) {
        return axios.get(url, {
            responseType: 'blob'
        })
    }

    static quickSearch(params) {
        return axios.get('quick_results', {params: params});
    }

    static searchBooks(params) {
        return axios.get('search_books', {params: params});
    }

    
    static checkGeoblock(uuid) {
        return axios.get(`carts/${uuid}/availability`)
    }

    static removeGeoBlockedItems(uuid, data) {
        return axios.post(`carts/${uuid}/checkout`, data)
    }

    static setGiftInfo(uuid, data) {
        return axios.patch(`cart_items/${uuid}`, data, {
            headers: { 'Content-type': 'application/merge-patch+json' }
        })
    }

    static getCurrentSubscription(params) {
        return axios.get('get-current-subscription', {params: params});
    }

    static getSubscriptionIntents(params) {
        return axios.get('subscription_intents', {params: params});
    }

    static createSubscription(data, isFreeTrial) {
        return axios.post(isFreeTrial ? 'subscriptions/create_trial_subscription' : 'subscriptions', data, {
            headers: { 'Content-Type': 'application/ld+json' }
        });
    }

    static getSubscriptionsInvoice(id) {
        return axios.get(`subscriptions/${id}/invoice`);
    }

    // not in use (default payment method)
    static updateSubscriptionsPaymentMethod(method) {
        return axios.post(`set-payment-method/${method}`, {}, {
            headers: { 'Content-Type': 'application/ld+json' }
        });
    }

    static updateSubscriptionPaymentMethod(id, data) {
        return axios.post(`subscriptions/${id}/update-payment-method`, data, {
            headers: { 'Content-Type': 'application/ld+json' }
        });
    }

    static cancelSubscription(id) {
        return axios.get(`subscriptions/${id}/cancel`);
    }

    static getSubscriptionsDetails(id) {
        return axios.get(`subscriptions/${id}/details`);
    }

    static getAllSubscriptions(params) {
        return axios.get('subscriptions', {params: params});
    }

    static checkGiftSubscription(data) {
        return axios.post('subscriptions/gift-check', data);
    }

    static createSubscriptionWithoutPayment(data) {
        return axios.post('subscriptions/create-without-payment', data, {
            headers: { 'Content-Type': 'application/ld+json' }
        });
    }

    static getPaypalSetupIntent() {
        return axios.get('setup-intent');
    }

    static ecommerceRegister(data) {
        return axios.post('ecommerce-register', data);
    }
    
    static checkEmail(type, slug, data) {
        return axios.post(`${type}/${slug}/purchased`, data)
    } 

    static claimBook(slug, type) {
        return axios.get(`audio_books/${slug}/${type}`);
    }

    static searchMenuList() {
        return axios.get('search_menu_entries');
    }

    static getDiscount(id, subscriptionPlan) {
        if (subscriptionPlan) {
            return axios.get(`discounts/${id}/sub-apply-coupon?plan=${subscriptionPlan}`);
        }
        return axios.get(`discounts/${id}`);
    }

    static getDefaultPaymentMethod() {
        return axios.get('subscriptions/default-payment');
    }

    static addFreeBookToLibrary(type, slug, data) {
        return axios.post(`${type === 'eBook' ? 'e_books' : 'audio_books'}/add-to-library/${slug}`, data, {
            headers: { 'Content-Type': 'application/ld+json' }
        });
    }

    static getAudioBookBySpisin(spisin) {
        return axios.get(`audio_books?spiSIN=${spisin}`);
    }

    static getRedemptionCode(code) {
        return axios.get(`redemption_codes/${code}`);
    }

    static addToLibraryWithRedemptionCode(code, data) {
        return axios.post(`redemption_codes/add_to_library_with_code/${code}`, data, {
            headers: { 'Content-Type': 'application/ld+json' }
        });
    }

    static getAudioBooksWithCategory(id, params) {
        return axios.get(`audio_books?categories%5B%5D=${id}`, {params});
    }

    static getMembershipPage() {
        return axios.get('membership_pages');
    }

    static getAudioBooksInCard(params) {
        return axios.get('audio_books?availableAsCard=true', {params: params});
    }

    static getSettings(id, params) {
        return axios.get(`settings/${id}`, {params});
    }

    static activateAudiobookCard(data) {
        return axios.post('audiobook-cards/activate', data, {
            headers: { 'Content-type': 'application/ld+json' }
        });
    }

    static getIsRedemptionCodeValid(code) {
        return axios.get(`audiobook-cards/${code}/card-apply-code`)
    }

    static redeemAudiobookCard(data) {
        return axios.post('audiobook-cards/redeem', data, {
            headers: { 'Content-type': 'application/ld+json' }
        });
    }
}

export default ApiService;