import styled from "styled-components/macro";

export const Heading = styled.h1`
    margin: 85px auto 33px;
    font-size: 24px;
    text-align: center;
    line-height: 1.2em;
    letter-spacing: 0;

    a {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 1px;
    }

    p {
        span {
            display: block;
        }
    }

    img {
        width: 210px;
        margin-top: 7px;
        margin-bottom: -15px;
    }
`;

export const Paragraph = styled.p`
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Gill Sans Nova';
    margin: 0 auto;
`;

export const OrderItem = styled.div`
    margin-bottom: 50px;
`;

export const OrderDetails = styled.div`
    margin: 0 auto 10px;
    padding: 0 15px;
    font-family: 'Gill Sans Nova';
    font-size: 12px;
    letter-spacing: 0.15em;
    display: grid;
    grid-template-columns: 1fr 1fr;

    span:nth-child(2) {
        text-align: right;
    }

    span:nth-child(3) {
        grid-column: 1/-1;
        margin-top: 8px;
    }
`;

export const OrderFooter = styled.div`
    a {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 1px;
    }
`;

export const Terms = styled.div`
    font-family: 'Gill Sans Nova';

    a {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 1px;
        letter-spacing: 0.04em;
    }

    p {
        font-size: 14px;
        margin-top: 14px;
        letter-spacing: 0.03em;

        strong {
            font-weight: 500;
        }
    }

    div {
        display: flex;
        align-items: center;

        input {
            all: initial;
        }

        label {
            margin: 0;

            &::before {
                display: inline-block;
                height: 25px;
            }
        }
    }
`;

// Order Confirmation

export const StyledOrderConfirmation = styled.div`
    max-width: 375px;
    padding: 0 8px;
    margin-top: 42px;

    ${(props) => props.theme.breakpoints.down("sm")} {
        margin-top: 115px;
    }

    ${Paragraph} {
        max-width: 330px;
    }
`;

// Payment Problem

export const TryAgain = styled.div``;
export const Container = styled.div``;
export const ParagraphBig = styled.p``;
export const StyledPaymentProblem = styled.div`
    max-width: 375px;
    padding: 0 8px;
    margin-top: -20px;

    ${(props) => props.theme.breakpoints.down("sm")} {
        margin-top: 50px;
    }

    ${Heading} {
        line-height: 29px;
    }

    ${TryAgain} {
        font-family: 'Gill Sans Nova';
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        margin-bottom: 15px;
        margin-left: 32px;
        cursor: pointer;

        .arrow-back {
            position: relative;
            width: 18px;
            height: 1px;
            background: #000;
            margin-bottom: 3px;
            margin-right: 18px;

            &::before {
                content: '';
                position: absolute;
                top: -2px;
                left: 0;
                width: 4px;
                height: 4px;
                border-top: 1px solid #000;
                border-left: 1px solid #000;
                transform: rotate(-45deg);
            }
        }
    }

    ${Container} {
        background: #E8E2CF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        padding: 32px 28px 40px;

        a {
            text-decoration: underline;
        }
    }

    img {
        max-width: 100%;
        margin-top: -15px;
    }

    ${ParagraphBig} {
        font-size: 24px;
        font-style: italic;
        text-align: center;
        margin: 30px 0 27px;
    }

    ${Paragraph} {
        font-style: normal;
    }
`

// Orders History

export const StyledOrderListHistory = styled.div`
    max-width: 375px;
    padding: 0 8px;
    margin-top: 15px;

    ${(props) => props.theme.breakpoints.down("sm")} {
        margin-top: 90px;
    }

    ${Heading} {
        font-size: 16px;
        font-style: normal;
        text-transform: uppercase;
        font-family: 'Gill Sans Nova';
        letter-spacing: 0.15em;

        ${(props) => props.theme.breakpoints.down("sm")} {
            font-size: 12px;
            text-align: left;
            margin-left: 15px;
        }
    }
`;

// Membership
export const MembershipDetailsList = styled.div`
    font-style: italic;
    font-size: 19px;
    line-height: 24px;
    margin-top: 38px;
    margin-bottom: 48px;
    padding: 0 13px;
    
    ol {
        margin-left: 30px;
        margin-bottom: 25px;

        li {
            font-size: 19px;
            line-height: 24px;
            max-width: 300px
        }

        a {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 1px;

            &:hover {
                color: #721b00;
            }
        }
    }
`;

export const Note = styled.p`
    margin-bottom: 48px;
    font-size: 18px;
    
    a {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 1px;

        &:hover {
            color: #721b00;
        }
    }
`;