import img from '../../images/audiobook-in-a-card.png';

import { AudiobookInCardContainer } from './style';

function AudiobookInCard({ small }) {
    return (
        <AudiobookInCardContainer small={small}>
            <img src={img} alt="Audiobook in a card" />
        </AudiobookInCardContainer>
    )
}

export default AudiobookInCard;