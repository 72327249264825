import PageMeta from 'shared/PageMeta/PageMeta';
import RedeemAudioBookWelcome from 'components/UI/Redeem/RedeemAudioBookWelcome';

function RedeemWelcomePage(props) {
    return (
        <>
            <PageMeta>
                <title>Redeem Welcome | Spiracle</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>

            <RedeemAudioBookWelcome/>
        </>
    )
}
export default RedeemWelcomePage