import { Heading, OrderDetails, OrderItem, StyledOrderListHistory } from './style';
import React, { Component } from 'react';

import ApiService from 'services/api.service';
import AudioBookListItem from '../../components/UI/AudioBook/AudioBookListItem';
import DateFormater from 'services/format-date.service';
import EmptyOrdersPage from 'shared/Pages/EmptyOrdersPage/EmptyOrdersPage';
import MembershipCard from '../../shared/MembershipCard/MembershipCard';
import PageMeta from '../../shared/PageMeta/PageMeta';
import SkeletonOrderItem from 'components/UI/Skeleton/SkeletonOrderItem/SkeletonOrderItem';
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';

class OrderListHistory extends Component {
    state = {
        _page: 1,
        itemsPerPage: 5,
        loadButton: false,
        moreOrders: false,
        orders: [],
        isLoading: true,
        defaultPaymentMethod: {},
        isLoadingDefaultPaymentMethod: true,
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/access-denied");
        } else {
            ApiService.getDefaultPaymentMethod().then(
                r => {
                    this.setState({
                        defaultPaymentMethod: r.data.defaultPayment,
                        isLoadingDefaultPaymentMethod: false,
                    })

                    this.getOrders() 
                }
            );
        }
    }

    handleLoadMoreOrders = () => {
        this.setState({
            _page: this.state._page + 1,
            loadButton: true,
        }, () => {
            this.getOrders()
        })
    }

    handleResponse = (r) => {
        if (r.data['hydra:member'].length) {
            this.setState({
                orders: [...this.state.orders, ...r.data['hydra:member']],
                loadButton: false,
                isLoading: false,
            }, () => {
                if (this.state.orders.length < r.data['hydra:totalItems']) {
                    this.setState({
                        moreOrders: true,
                    })
                } else {
                    this.setState({
                        moreOrders: false,
                    })
                }
            })
        } else {
            this.setState({
                moreOrders: false,
                loadButton: false,
                isLoading: false,
            })
        }
    }

    getOrders = () => {
        const params = new URLSearchParams();

        params.append('page', this.state._page);
        params.append('itemsPerPage', this.state.itemsPerPage);

        this.props.history.replace(`/orders?${params.toString()}`)

        ApiService.getUserOrderList(params).then(r => this.handleResponse(r))
    }

    render() {
        return (
            <StyledOrderListHistory className="container container__big">
                <PageMeta>
                    <title>Orders | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>  
                <Heading className="mb-38">Order history</Heading>  
                {this.state.isLoading ? 
                    <SkeletonOrderItem orders={3} items={2} />    
                    :
                    <>          
                        {this.state.orders.length ?
                            <>                          
                                {this.state.orders.map((order, index) => {
                                    return(
                                        <OrderItem key={`order-${index}`}>
                                            <OrderDetails>
                                                <span>ORDER No.{order.orderNumber}</span><span>{DateFormater.formatDateMonthYear(order.createdAt)}</span>
                                                {order.orderItems[0].subscription ?
                                                    <span>PAID WITH {order.transactions.length ? 
                                                        order.transactions[0].last4 ? 
                                                            `**** **** **** ${order.transactions[0].last4}`
                                                            : order.transactions[0].type === 'paypal' ? 'PAYPAL' : ''
                                                        : order.orderItems[0].subscription.cardLast4 ? `**** **** **** ${order.orderItems[0].subscription.cardLast4}` : order.orderItems[0].subscription.status === 'trialing' ? 'TRIAL' : ''}</span>
                                                    :
                                                    <span>PAID WITH {order.transactions.length ? 
                                                        order.transactions[0].last4 ? `**** **** **** ${order.transactions[0].last4}` : order.transactions[0].type === 'paypal' ? 'PAYPAL' : ''
                                                    : 'SUBSCRIPTION'}</span>
                                                    
                                                }
                                               
                                            </OrderDetails>

                                            {order.orderItems.map((book, index) => {
                                                let bookInfo                    
                                                if (book.audioBook) {
                                                    bookInfo = book.audioBook
                                                } 
                                                if (book.eBook){
                                                    bookInfo = book.eBook
                                                }
                                                if (bookInfo) {
                                                    return  <AudioBookListItem
                                                                key={`book-${index}`}                
                                                                title={bookInfo.title}
                                                                authors={bookInfo.authors}
                                                                imgSrc={bookInfo.cover}
                                                                imgAlt={bookInfo.coverAlt}
                                                                price={order.transactions.length ? book.originalPrice : '0'}
                                                                giftEmail={book.giftEmail}
                                                                link={`/${book.audioBook ? 'audiobooks' : 'ebooks'}/${bookInfo.slug}`}
                                                                eBook={bookInfo.eBook}
                                                                gift={book.gift}
                                                                isEdition={bookInfo.edition}
                                                                bookBgColor={bookInfo.bookBackgroundColour}
                                                                containerBgColor={bookInfo.containerBackgroundColour}
                                                                textColor={bookInfo.textColour}
                                                                type={book.audioBook ? 'audiobook' : 'ebooks'}
                                                                availableAsCard={bookInfo.availableAsCard}
                                                                horizontal
                                                                mobileOnly
                                                                discountCode={book.couponCode}
                                                                discountPercent={book.percentDiscount}
                                                                discountPrice={book.price !== book.originalPrice ? book.price : null}
                                                                className="mb-18"
                                                            />
                                                } 
                                                
                                                if (book.subscription) {
                                                    return <MembershipCard 
                                                                key={`membership-${index}`} 
                                                                plan={book.subscription.plan} 
                                                                originalPrice={book.originalPrice}
                                                                price={book.price} 
                                                                gift={book.gift} 
                                                                giftEmail={book.giftEmail}
                                                                discountCode={book.couponCode}
                                                                discountPercent={book.percentDiscount}
                                                                discountPrice={book.percentDiscount ? book.price : null}
                                                            />
                                                }

                                                return null
                                            })}
                                        </OrderItem>
                                    )
                                })}             

                                {this.state.moreOrders && 
                                    <div className="text-center">
                                        <button 
                                            id='gtm-order-history-load-more'
                                            onClick={this.handleLoadMoreOrders} 
                                            disabled={this.state.loadButton}
                                            className={`c-btn c-btn__ghost font-22 font-secondary font-italic mt-10 mb-50 ls-2 ${this.state.loadButton ? 'submitting' : ''}`}>
                                                load more
                                                <SpiracleLogoSmall />
                                        </button>
                                    </div>
                                }      
                            </>          
                            :
                            <EmptyOrdersPage pageName="order history" />                
                        }
                    </>
                }
            </StyledOrderListHistory>
        )
    }
}

export default OrderListHistory;