import * as cartActionTypes from '../../store/actions/cartActions';
import * as userActionTypes from '../../store/actions/userActions';

import { CartContainer, OrderStatus, PriceHolder, Vat } from "pages/ShoppingBag/style";
import React, { Component } from 'react';

import ApiService from "services/api.service";
import EmptyOrdersPage from 'shared/Pages/EmptyOrdersPage/EmptyOrdersPage';
import Loader from "shared/Loader/Loader";
import PageMeta from '../../shared/PageMeta/PageMeta';
import ShoppingBagItems from "components/UI/ShoppingBag/ShoppingBagItems";
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';
import { connect } from 'react-redux';
import cookie from "react-cookies";

class ShoppingBag extends Component {


    state ={
        isLoading: true,
        geoBlockedItems: false,
    }

    componentDidMount() {
        // if (!localStorage.getItem('token')) {
        //     this.props.history.push({
        //         pathname: '/login',
        //         state: {
        //             returnUrl: '/shopping-bag'
        //         }
        //     })
        // } else if (this.props.cart.length < 1) {
        //     this.props.history.push('/')
        // }

        if (this.props.cart.length < 1) {
            this.props.history.push('/')
        }

        //clear validation if any 
        this.props.onClearValidation();
        
        if (!this.props.user && localStorage.getItem('token')) {
            ApiService.getUser().then(
                r => {
                    this.props.onSetUser(r.data)
                    let cartUuid = cookie.load('cartId') ? cookie.load('cartId') : this.props.user && this.props.user.cart ? this.props.user.cart.uuid : null
                    ApiService.getCartItems(cartUuid).then(
                        cart => {
                            this.props.onGetCartItems(cart.data['hydra:member'])
                            this.setState({
                                isLoading: false,
                            })
                        }
                    )
                }
            )
        } else {
            let cartUuid = cookie.load('cartId') ? cookie.load('cartId') : this.props.user && this.props.user.cart ? this.props.user.cart.uuid : null
            ApiService.getCartItems(cartUuid).then(
                cart => {
                    this.props.onGetCartItems(cart.data['hydra:member'])
                    this.setState({
                        isLoading: false,
                    })
                }
            )
        }
    }

    handlePayNow = () => {
        this.setState({
            isLoading: true
        }, () => {
            this.props.onClearValidation();
            let geoBlocked = false;
            let cartUuid = cookie.load('cartId') ? cookie.load('cartId') : this.props.user && this.props.user.cart ? this.props.user.cart.uuid : null
            this.props.cart.forEach( book => {
                if (book.geoBlocked) {
                    geoBlocked = true;
                }
            })
            if (geoBlocked === true) {
                const data = {
                    remove: 1
                }
                ApiService.removeGeoBlockedItems(cartUuid, data).then(
                    r => {
                        ApiService.getCartItems(cartUuid).then(
                            cart => {
                                this.props.onGetCartItems(cart.data['hydra:member'])
                                this.props.history.push({
                                    pathname: '/payment',
                                    state: { totalCostShoppingBag: this.props.cart[0].cart.sumCheckedForPayment }
                                })
                            }
                        )
                    }
                ).catch(
                    e => {
                        this.props.onClearCart();
                    }
                )
            } else {
                this.props.history.push({
                    pathname: '/payment',
                    state: { totalCostShoppingBag: this.props.cart[0].cart.sumCheckedForPayment }
                })
            }
        })

    }
    
    render() {

        let totalCost = 0
        let VAT = 0
        let numberOfItems = 0;
        let disabled = false;
        let disableGeoBlocked = false;
        if (this.props.cart.length) {
            numberOfItems = this.props.cart.length
            totalCost = this.props.cart[0].cart.sumCheckedForPayment;
            VAT = this.props.cart[0].cart.sumVat;
        }
        
        if (this.props.cartReducer) {
            if (this.props.cartReducer.checkboxArr !== this.props.cartReducer.emailArr) {
                disabled = true
            } else {
                disabled = false
            }
        }

        if (totalCost === 0) {
            disabled = true
        }

        let content = <Loader />
        if (!this.state.isLoading) {
           content = ( 
            <CartContainer>
                    <PageMeta>
                        <title>Shopping Bag | Spiracle</title>
                        <link rel="canonical" href={window.location.href} />
                    </PageMeta>

                    {this.props.cart.length ?
                        <>
                            <h3>Your bag has {numberOfItems} {numberOfItems === 1 ? 'item' : 'items'} totalling £{totalCost}</h3>
                            <ShoppingBagItems 
                                shoppingBag={true}
                                subtotal={(totalCost - VAT).toFixed(2)}
                            />
                            <OrderStatus>
                                <h4>Your order</h4>
                                <Vat>
                                    <div>
                                        <span>sub total</span><span>£{(totalCost - VAT).toFixed(2)}</span>
                                    </div>
                                    <div>
                                        <span>VAT</span><span>£{(VAT).toFixed(2)}</span>
                                    </div>
                                </Vat>
                                <hr />
                                <PriceHolder>
                                    <span>total to pay</span>
                                    <span>£{totalCost}</span>
                                </PriceHolder>
                                <button disabled={disabled || disableGeoBlocked} 
                                    id='gtm-shopping-bag-pay-now'
                                    onClick={() => this.handlePayNow()} 
                                    className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-14 letter-spacing-15 font-uppercase text-center ${this.state.isLoading ? 'submitting' : ''}`}>
                                    Pay now
                                    <SpiracleLogoSmall />
                                </button>
                                <span>powered by <strong>stripe</strong></span>
                            </OrderStatus>
                        </>                    
                        :
                        <EmptyOrdersPage pageName="bag" />
                    }      
                </CartContainer>    
           ); 
        }

        return content
    }
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart,
    user: state.userReducer.user,
    cartReducer: state.cartReducer,
})

const mapDispatchToProps = dispatch => ({
    onGetCartItems: (cartItems) => dispatch(cartActionTypes.onGetCartItems(cartItems)),
    onClearCart: () => dispatch(cartActionTypes.onClearCart()),
    onSetUser: user => dispatch(userActionTypes.onSetUser(user)),
    onClearValidation: () => dispatch(cartActionTypes.onClearValidation())
})

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingBag);