import * as bookActionTypes from '../../store/actions/bookActions';
import * as cartActionTypes from '../../store/actions/cartActions';
import * as libraryActionTypes from '../../store/actions/libraryActions';
import * as userActionTypes from '../../store/actions/userActions';

import {
  AudiobookDetails,
  AudiobookImage,
  BgWrapperDesktop,
  BgWrapperMob,
  ContainerOrder,
  ContainerRelated,
  Cta,
  CtaHolder,
  Details,
  Genre,
  GiftInfo,
  Headline,
  ImageHolder,
  InfoButton,
  PhysicalBookLink,
  StyledButton,
  StyledChip,
  Summary
} from "pages/Audiobook/style";
import React, { Component } from "react";

import ApiService from "../../services/api.service";
import ArticleItem from '../../components/UI/ArticleItem/ArticleItem'
import AudiobookInCard from 'shared/AudiobookInCard/AudiobookInCard';
import AudioBookSingleCarousel from '../../components/UI/AudiobookSingle/AudioBookSingleCarousel';
import BackButton from 'shared/BackButton/BackButton';
import Collapse from "@material-ui/core/Collapse";
import ConfirmationDialog from 'shared/PageElements/ConfirmationDialog';
import CoverImage from "shared/CoverImage/CoverImage";
import DateFormater from '../../services/format-date.service';
import FreeBook from 'shared/FreeBook/FreeBook';
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import PageMeta from "shared/PageMeta/PageMeta";
import Popup from 'shared/Popup/Popup';
import ReactHtmlParser from 'react-html-parser';
import SkeletonAudioBookSingle from '../../components/UI/Skeleton/SkeletonAudioBookSingle/SkeletonAudioBookSingle';
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';
import { calculateFooterHeight } from 'shared/Functions/CalculateFooterHeight';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { getNamesFromArray } from 'shared/Functions/GetNamesFromArray';

class Audiobook extends Component {
  constructor(props) {
    super(props)

    this.divElement = React.createRef();
    this.divElementMobile = React.createRef();
    this.divDetailsMobile = React.createRef();
    this.clearTimeout = []

    this.state = {
      isFullSummaryShown: false,
      areDetailsShown: false,
      isLoading: true,
      audioBook: null,
      audioBookRelated: null,
      slug: null,
      summaryHeight: 0,
      detailsMobileHeight: 0,
      type: '',
      isModalOpen: false,
      eBook: false,
      audioBookBtn: false,
      eBookAudioBook: false,
      audioBookEBook: false,
      eBookSaved: false,
      audioBookBtnSaved: false,
      eBookAudioBookSaved: false,
      audioBookEBookSaved: false,
      eBookError: false,
      audioBookBtnError: false,
      eBookAudioBookError: false,
      audioBookEBookError: false,
      audioBookInCart: false,
      audioBookEBookInCart: false,
      eBookInCart: false,
      eBookAudioBookInCart: false,
      bookClaimed: false,
      buttonsPosition: null,
      zeroPriceAddedInLibraryAudioBook: false,
      zeroPriceAddedInLibraryEBook: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
    let type = 'audiobook'
    if (this.props.match.path.includes('ebooks')) {
      type = 'ebook'
    }
    this.getBook(type, true)
    this.setState({
        type: type,
        buttonsPosition: calculateFooterHeight()
    })

  }

  componentDidUpdate(prevProps, prevState){
    if ((this.props.location.pathname !== prevProps.location.pathname)) {
      let type = 'audiobook'
      //not the brightest, but it works
      this.props.removeInCartItems();
      this.setState({
        audioBookBtn: false,
        eBookAudioBook: false,
        audioBookEBook: false,
        eBookSaved: false,
        audioBookBtnSaved: false,
        eBookAudioBookSaved: false,
        audioBookEBookSaved: false,
        eBookError: false,
        audioBookBtnError: false,
        eBookAudioBookError: false,
        audioBookEBookError: false,
        audioBookInCart: false,
        audioBookEBookInCart: false,
        eBookInCart: false,
        eBookAudioBookInCart: false,
        buttonsPosition: calculateFooterHeight(),
        zeroPriceAddedInLibraryAudioBook: false,
        zeroPriceAddedInLibraryEBook: false,
      })

      //clearing button switch timeouts 
      //so it doesn't set state after
      //component did update
      this.clearTimeout.forEach( timeout => {
        clearTimeout(timeout);
      })
      if (this.props.match.path.includes('ebooks')) {
        type = 'ebook'
      }
      this.getBook(type, false);
      this.setState({
        type: type
      })
    }

    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      window.scrollTo(0,0);
    }

    if(prevProps.widgetPlayer !== this.props.widgetPlayer) {
        this.setState({
            buttonsPosition: calculateFooterHeight()
        })
    }

    //sample to play if user owns a book
    //and logs in on page of a book
    if (prevProps.user !== this.props.user) {
      let type = 'audiobook'
      if (this.props.match.path.includes('ebooks')) {
        type = 'ebook'
      }
      ApiService.getAudioBookBySlug(this.props.match.params.slug, type).then(
        r => {
          this.setState({
            ...this.state,
            audioBook: r.data
          }, () => {
            this.props.onAddAudioFiles(r.data.audioFiles, r.data.download);
            this.props.onGetCurrentBook(r.data);

            if (window.dataLayer) {
              const locations = []
              if (this.state.audioBook.locations && this.state.audioBook.locations['hydra:member'] && this.state.audioBook.locations['hydra:member'].length) {
                this.state.audioBook.locations['hydra:member'].map((location) => {
                  return locations.push(location.title)
                })
              }
              const tags = []
              if (this.state.audioBook.tags && this.state.audioBook.tags['hydra:member'] && this.state.audioBook.tags['hydra:member'].length) {
                this.state.audioBook.tags['hydra:member'].map((tag) => {
                  return tags.push(tag.title)
                })
              }
              const authors = []
              if (this.state.audioBook.authors && this.state.audioBook.authors['hydra:member'] && this.state.audioBook.authors['hydra:member'].length) {
                this.state.audioBook.authors['hydra:member'].map((author) => {
                  return authors.push(author.fullName)
                })
              }
              const narrators = []
              if (this.state.audioBook.narrators && this.state.audioBook.narrators['hydra:member'] && this.state.audioBook.narrators['hydra:member'].length) {
                this.state.audioBook.narrators['hydra:member'].map((narrator) => {
                  return narrators.push(`${narrator.firstName} ${narrator.lastName}`)
                })
              }

              window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
              window.dataLayer.push({
                event: "view_item",
                ecommerce: {
                  currency: "GBP",
                  value: this.props.user && this.props.user.currentSubscription ? this.state.audioBook.subscriptionPrice : this.state.audioBook.price,
                  items: [
                    {
                      item_id: this.state.audioBook.slug,
                      item_name: this.state.audioBook.title,
                      item_brand: this.state.audioBook.publisher ? this.state.audioBook.publisher?.name : '',
                      item_category: this.state.audioBook.category ? this.state.audioBook.category?.name : '',
                      item_locations: locations?.length ? locations.join(', ') : '',
                      item_tags: tags?.length ? tags.join(', ') : '',
                      item_price: this.state.audioBook.price,
                      item_author: authors?.length ? authors.join(', ') : '',
                      item_narrator: narrators?.length ? narrators.join(', ') : '',
                      item_translator: this.state.audioBook.translator ? `${this.state.audioBook.translator.firstName} ${this.state.audioBook.translator.lastName}` : '',
                      item_length: `${this.state.audioBook.lengthHours}:${this.state.audioBook.lengthMin}`,
                    }
                  ]
                }
              });
            }
          })
        }
      )
    }

    if (prevProps.cart !== this.props.cart) {
      //user login in cart check
      let type = 'audiobook'
      if (this.props.match.path.includes('ebooks')) {
        type = 'ebook'
      }
      this.props.cart.forEach((item, index) => {
        if (type === 'audiobook') {
          if (item.audioBook) {
            if (item.audioBook.slug === this.props.audioBook.slug) {
              this.props.onAddBookToCart('audioBookInCart')
            }
          } else {
            if (item.eBook && this.props.audioBook.eBook && item.eBook.slug === this.props.audioBook.eBook.slug) {
              this.props.onAddBookToCart('audioBookEBookInCart')
            }
          }
        } else {
          if (item.eBook) {
            if (item.eBook.slug === this.props.audioBook.slug) {
              this.props.onAddBookToCart('eBookInCart')
            }
          } else {
            if (item.audioBook && this.props.audioBook.audioBook && item.audioBook.slug === this.props.audioBook.audioBook.slug) {
              this.props.onAddBookToCart('eBookAudioBookInCart')
            }
          }
        }
      })
    }
  }

  componentWillUnmount() {
    this.props.onRemoveCurrentBook();
    this.props.removeInCartItems();
  }

  getBook = (type, cdm) => {
    this.setState({
      isLoading: true
    }, () => {
      ApiService.getAudioBookBySlug(this.props.match.params.slug, type).then((r)=>{
        this.setState({
          ...this.state,
          audioBook: r.data,
        }, () => {
          this.props.onGetCurrentBook(r.data)

          if (window.dataLayer) {
            const locations = []
            if (this.state.audioBook.locations && this.state.audioBook.locations['hydra:member'] && this.state.audioBook.locations['hydra:member'].length) {
              this.state.audioBook.locations['hydra:member'].map((location) => {
                return locations.push(location.title)
              })
            }
            const tags = []
            if (this.state.audioBook.tags && this.state.audioBook.tags['hydra:member'] && this.state.audioBook.tags['hydra:member'].length) {
              this.state.audioBook.tags['hydra:member'].map((tag) => {
                return tags.push(tag.title)
              })
            }
            const authors = []
            if (this.state.audioBook.authors && this.state.audioBook.authors['hydra:member'] && this.state.audioBook.authors['hydra:member'].length) {
              this.state.audioBook.authors['hydra:member'].map((author) => {
                return authors.push(author.fullName)
              })
            }
            const narrators = []
            if (this.state.audioBook.narrators && this.state.audioBook.narrators['hydra:member'] && this.state.audioBook.narrators['hydra:member'].length) {
              this.state.audioBook.narrators['hydra:member'].map((narrator) => {
                return narrators.push(`${narrator.firstName} ${narrator.lastName}`)
              })
            }

            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            window.dataLayer.push({
              event: "view_item",
              ecommerce: {
                currency: "GBP",
                value: this.props.user && this.props.user.currentSubscription ? this.state.audioBook.subscriptionPrice : this.state.audioBook.price,
                items: [
                  {
                    item_id: this.state.audioBook.slug,
                    item_name: this.state.audioBook.title,
                    item_brand: this.state.audioBook.publisher ? this.state.audioBook.publisher?.name : '',
                    item_category: this.state.audioBook.category ? this.state.audioBook.category?.name : '',
                    item_locations: locations?.length ? locations.join(', ') : '',
                    item_tags: tags?.length ? tags.join(', ') : '',
                    item_price: this.state.audioBook.price,
                    item_author: authors?.length ? authors.join(', ') : '',
                    item_narrator: narrators?.length ? narrators.join(', ') : '',
                    item_translator: this.state.audioBook.translator ? `${this.state.audioBook.translator.firstName} ${this.state.audioBook.translator.lastName}` : '',
                    item_length: `${this.state.audioBook.lengthHours}:${this.state.audioBook.lengthMin}`,
                  }
                ]
              }
            });
          }

          ApiService.getAudioBookRelated(this.props.match.params.slug, type).then((r)=>{
            this.setState({
              ...this.state,
              audioBookRelated: r.data,
              isLoading: false,
            }, () => {
              this.props.cart.forEach((item, index) => {
                if (type === 'audiobook') {
                  if (item.audioBook) {
                    if (item.audioBook.slug === this.props.audioBook.slug) {
                      this.props.onAddBookToCart('audioBookInCart')
                    }
                  } else {
                    if (item.eBook && this.props.audioBook.eBook && item.eBook.slug === this.props.audioBook.eBook.slug) {
                      this.props.onAddBookToCart('audioBookEBookInCart')
                    }
                  }
                } else {
                  if (item.eBook) {
                    if (item.eBook.slug === this.props.audioBook.slug) {
                      this.props.onAddBookToCart('eBookInCart')
                    }
                  } else {
                    if (item.audioBook && this.props.audioBook.audioBook && item.audioBook.slug === this.props.audioBook.audioBook.slug) {
                      this.props.onAddBookToCart('eBookAudioBookInCart')
                    }
                  }
                }
              })
              setTimeout(() => {
                if (cdm) {
                  window.scrollTo(0,0)
                }
                // summary height - show/hide read more (less) button
                if(this.divElement.current){
                  const summaryHeight = this.divElement.current.clientHeight;
        
                  if(this.state.summaryHeight !== summaryHeight){
                    this.setState({ ...this.state, summaryHeight: summaryHeight })
                  }
                }
        
                if(this.divElementMobile.current){
                  const summaryHeight = this.divElementMobile.current.clientHeight;
        
                  if(this.state.summaryHeight !== summaryHeight){
                    this.setState({ ...this.state, summaryHeight: summaryHeight })
                  }
                }
        
                // mobile details height - show/hide arrow
                if(this.divDetailsMobile.current){
                  const detailsMobileHeight = this.divDetailsMobile.current.clientHeight;
        
                  if(this.state.detailsMobileHeight !== detailsMobileHeight){
                    this.setState({...this.state, detailsMobileHeight: detailsMobileHeight})
                  }
                }
              })
            })
          })
        })
      }).catch(
        e => {
          this.props.history.push('/404')
        }
      )
    })
  }
  

  toggleVisibility = (togler) => {
    let togglerStatus = this.state[togler];
    this.setState({ [togler]: !togglerStatus });    
  };

  handleOpenAudioPlayer = () => {
    if (this.props.location.pathname.includes('ebooks')) {
      //download ebook on ebook page
      //isModalOpen
      if (this.props.audioBook.download && this.props.audioBook.download !== "") {
        if (this.props.audioBook.download.includes('/api/download-epub/')) {
          ApiService.downloadEBook(this.props.audioBook.download).then(
            response => {
              let blob = new Blob([response.data])
              let downloadUrl = URL.createObjectURL(blob)
              let a = document.createElement('a');
              a.href = downloadUrl;
              // a.download = itemOriginalName;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          )
        } else {
          const a = document.createElement('a')
          a.href = this.props.audioBook.download
          a.download = this.props.audioBook.download.split('/').pop()
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      } else {
        this.setState({
          isModalOpen: true
        })
      }
    } else {
      document.body.classList.add('player-visible');
      //open audio player on audiobook page
      if (window.innerWidth >= 768) {
        window.open(`/${this.props.audioBook && this.props.audioBook.download ? 'player' : 'sample'}/${this.props.match.params.slug}`,'targetWindow',
            `toolbar=no,
            location=no,
            status=no,
            menubar=no,
            scrollbars=yes,
            resizable=yes,
            width=376px,
            height=813px`
        )
      } else {
        document.body.classList.add('noScroll');
        this.props.onGetBook(this.props.audioBook);
        if (this.props.bookReducer.widgetPlayer) {
          // this.props.onSwitchWidgetPlayer();
          this.props.onTurnOnWidgetPlayer();
        }
      }
    }
  }

  handleOpenAddToLibrary = (event, type, otherType, isFree) => {
    confirmAlert({
        customUI: ({ onClose }) => (
            <ConfirmationDialog 
                handleClose={() => { onClose()}}                    
                title="This title will be added to your library"
                firstButtonLabel="Add"
                onClickFirstButton={() => {this.handleAddToLibrary(event, type, otherType, isFree); onClose()}}
                secondButtonLabel="Cancel"
                onClickSecondButton={() => { onClose()}}
                firstButtonPrimary
                audioBook
            />  
        )
    })
  }

  handleOpenLoginPopup = () => {
    setTimeout(() => {
      document.querySelector('.c-header .icon-login').click()
    }, 100);
  }

  handleAddToLibrary = (event, type, otherType, isFree) => {
    if (this.props.user && isFree) {
      // add book to library if it's free (price 0)
      let slug = otherType ? this.state.type === 'ebook' ? `${this.props.audioBook.audioBook.slug}` : `${this.props.audioBook.eBook.slug}` : this.props.match.params.slug

      let data = {
        email: this.props.user.email
      }

      this.setState({
        [event.target.name]: true
      }, () => {
        ApiService.addFreeBookToLibrary(type, slug, data).then(
          r => {
            const libraryItem = {
              audioBook: type === 'audioBook' ? r.data : null,
              eBook: type === 'eBook' ? r.data : null,
            }
            
            //check if audioBook. If it is, add all audio files
            //to enable play.
            if (this.props.audioBook.slug === this.props.match.params.slug) {
              this.props.onAddAudioFiles(r.data.audioFiles, r.data.download);
            }
            this.props.onAddSingleBookToLibrary(libraryItem);

            ApiService.getUser().then((r) => {
              this.props.onSetUser(r.data);
            });

            this.setState({
              [event.target.name]: false,
            })

            if (type === 'audioBook') {
              this.setState({
                zeroPriceAddedInLibraryAudioBook: true,
              })
            } else {
              this.setState({
                zeroPriceAddedInLibraryEBook: true,
              })
            }
          }
        ).catch(
          e => {
            this.setState({
              [event.target.name]: false,
            })
          } 
        )
      })
    } else {
    //add item to library if there's credit
    this.setState({
      [event.target.name]: true
    }, () => {
      let bookType;
      if (type === 'audioBook') {
        bookType = 'move-audio-book-to-lib'
      } else {
        bookType = 'move-e-book-to-lib'
      }
      let slug = otherType ? this.state.type === 'ebook' ? `${this.props.audioBook.audioBook.slug}` : `${this.props.audioBook.eBook.slug}` : this.props.match.params.slug
      ApiService.claimBook(slug, bookType).then(
        r => {

          const libraryItem = {
            audioBook: type === 'audioBook' ? r.data : null,
            eBook: type === 'eBook' ? r.data : null,
          }
          //check if audioBook. If it is, add all audio files
          //to enable play.
          if (this.props.audioBook.slug === this.props.match.params.slug) {
            this.props.onAddAudioFiles(r.data.audioFiles, r.data.download);
          }
          this.props.onAddSingleBookToLibrary(libraryItem);
          // this.props.onAddBookToLibrary();

          ApiService.getUser().then((r) => {
            this.props.onSetUser(r.data);
          });
          this.setState({
            [event.target.name]: false,
            bookClaimed: true
          }, () => {
            setTimeout(() => {
              this.setState({
                bookClaimed: false,
              })
            }, 5000)
          })

        }
      )
    })
    }
  }

  handleAddToCart = (event, type, otherType, hasCredit, isFree) => {
    event.stopPropagation();
    if ((hasCredit && type !== 'eBook') || (this.props.user && isFree)) {
      this.handleOpenAddToLibrary(event, type, otherType, isFree);
    } else if (!this.props.user && isFree) {
      this.handleOpenLoginPopup()
    } else {
      //add item to cart 
      this.setState({
        [event.target.name]: true
      }, () => {
        if (this.props.user) {
          let cartId = this.props.user.cart ? this.props.user.cart.uuid : null;
          if (cartId) {
            const data = {
              cart: `/api/carts/${cartId}`,
              [type]: `/api/${type === 'eBook' ? 'e_books' : 'audio_books'}/${otherType ? this.state.type === 'ebook' ? `${this.props.audioBook.audioBook.slug}` : `${this.props.audioBook.eBook.slug}` : this.props.match.params.slug}`,
            }
            ApiService.createCart(data).then(
              r => {
                this.setState({
                  [event.target.name]: false,
                  [event.target.getAttribute("saved")]: true
                }, () => {
                  // let timer = setTimeout(() => {
                  // }, 1500)
                  // this.clearTimeout.push(timer)
                  this.setState({
                    [event.target.getAttribute("saved")]: false,
                    [event.target.getAttribute("error")]: true,
                    [event.target.saved]: false,
                  }, () => {
                    this.props.onAddBookToCart(event.target.getAttribute("error"))
                  })
                  this.props.onAddCartItem(r.data);
                })
              }
            ).catch(
              e => {
                this.setState({
                  [event.target.name]: false,
                  [event.target.getAttribute("error")]: true,
                  [event.target.saved]: false,
                }, () => {
                  this.props.onAddBookToCart(event.target.getAttribute("error"))
                })
              }
            )
          } else {
            const data = {
              [type]: `/api/${type === 'eBook' ? 'e_books' : 'audio_books'}/${otherType ? this.state.type === 'ebook' ? `${this.props.audioBook.audioBook.slug}` : `${this.props.audioBook.eBook.slug}` : this.props.match.params.slug}`,
            }
    
            ApiService.createCart(data).then(
              r => {
                //redux is causing page unmount! 
                //needs to be fixed ASAP
                this.setState({
                  [event.target.name]: false,
                  [event.target.getAttribute("saved")]: true
                }, () => {
                  this.setState({
                    [event.target.getAttribute("saved")]: false,
                    [event.target.getAttribute("error")]: true,
                    [event.target.saved]: false,
                  }, () => {
                    this.props.onAddBookToCart(event.target.getAttribute("error"))
                    this.props.onAddCart(r.data.cart);
                    this.props.onAddCartItem(r.data);
                  })
                  // let timer = setTimeout(() => {
                  // }, 1500)
                  // this.clearTimeout.push(timer)
                })
              }
            ).catch(
              e => {
                this.setState({
                  [event.target.name]: false,
                  [event.target.getAttribute("error")]: true,
                  [event.target.saved]: false,
                }, () => {
                  this.props.onAddBookToCart(event.target.getAttribute("error"))
                })
              }
            )
          }
        } else {
          let cartId = cookie.load('cartId')
          if (cartId) {
            //if there's cart ID in cookies;
            const data = {
              cart: `/api/carts/${cartId}`,
              [type]: `/api/${type === 'eBook' ? 'e_books' : 'audio_books'}/${otherType ? this.state.type === 'ebook' ? `${this.props.audioBook.audioBook.slug}` : `${this.props.audioBook.eBook.slug}` : this.props.match.params.slug}`
            }
            ApiService.addToExistingCart(data).then(
              r => {
                this.setState({
                  [event.target.name]: false,
                  [event.target.getAttribute("saved")]: true,
                }, () => {
                  this.setState({
                    [event.target.getAttribute("saved")]: false,
                    [event.target.getAttribute("error")]: true,
                    [event.target.saved]: false,
                  }, () => {
                    this.props.onAddBookToCart(event.target.getAttribute("error"))
                    this.props.onAddCartItem(r.data);
                  })
                  // let timer = setTimeout(() => {
                  // }, 1500)
                  // this.clearTimeout.push(timer)
                })
              }
            ).catch(
              e => {
                this.setState({
                  [event.target.name]: false,
                  [event.target.getAttribute("error")]: true,
                  [event.target.saved]: false,
                }, () => {
                  this.props.onAddBookToCart(event.target.getAttribute("error"))
                })
              }
            )
          } else {
            //create new cart 
            const data = {
              [type]: `/api/${type === 'eBook' ? 'e_books' : 'audio_books'}/${otherType ? this.state.type === 'ebook' ? `${this.props.audioBook.audioBook.slug}` : `${this.props.audioBook.eBook.slug}` : this.props.match.params.slug}`
            }
            ApiService.createCart(data).then(
              r => {
                this.setState({
                  [event.target.name]: false,
                  [event.target.getAttribute("saved")]: true
                }, () => {
                  // let timer = setTimeout(() => {
                  // }, 1500)
                  // this.clearTimeout.push(timer)
                  this.setState({
                    [event.target.getAttribute("saved")]: false,
                    [event.target.getAttribute("error")]: true,
                    [event.target.saved]: false,
                  }, () => {
                    this.props.onAddBookToCart(event.target.getAttribute("error"))
                  })
                  this.props.onAddCartItem(r.data);
                  cookie.save('cartId', r.data.cart.uuid);
                })
              }
            ).catch(
              e => {
                this.setState({
                  [event.target.name]: false,
                  [event.target.saved]: false,
                  [event.target.getAttribute("error")]: true
                }, () => {
                  this.props.onAddBookToCart(event.target.getAttribute("error"))
                })
              }
            )
          }
        }
      })

      if (window.dataLayer) {
        const locations = []
        if (this.state.audioBook.locations && this.state.audioBook.locations['hydra:member'] && this.state.audioBook.locations['hydra:member'].length) {
          this.state.audioBook.locations['hydra:member'].map((location) => {
            return locations.push(location.title)
          })
        }
        const tags = []
        if (this.state.audioBook.tags && this.state.audioBook.tags['hydra:member'] && this.state.audioBook.tags['hydra:member'].length) {
          this.state.audioBook.tags['hydra:member'].map((tag) => {
            return tags.push(tag.title)
          })
        }
        const authors = []
        if (this.state.audioBook.authors && this.state.audioBook.authors['hydra:member'] && this.state.audioBook.authors['hydra:member'].length) {
          this.state.audioBook.authors['hydra:member'].map((author) => {
            return authors.push(author.fullName)
          })
        }
        const narrators = []
        if (this.state.audioBook.narrators && this.state.audioBook.narrators['hydra:member'] && this.state.audioBook.narrators['hydra:member'].length) {
          this.state.audioBook.narrators['hydra:member'].map((narrator) => {
            return narrators.push(`${narrator.firstName} ${narrator.lastName}`)
          })
        }

        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "add_to_cart",
          ecommerce: {
            currency: "GBP",
            value: this.props.user && this.props.user.currentSubscription ? this.state.audioBook.subscriptionPrice : this.state.audioBook.price,
            items: [
              {
                item_id: this.state.audioBook.slug,
                item_name: this.state.audioBook.title,
                item_brand: this.state.audioBook.publisher ? this.state.audioBook.publisher?.name : '',
                item_category: this.state.audioBook.category ? this.state.audioBook.category?.name : '',
                item_locations: locations?.length ? locations.join(', ') : '',
                item_tags: tags?.length ? tags.join(', ') : '',
                item_price: this.state.audioBook.price,
                item_author: authors?.length ? authors.join(', ') : '',
                item_narrator: narrators?.length ? narrators.join(', ') : '',
                item_translator: this.state.audioBook.translator ? `${this.state.audioBook.translator.firstName} ${this.state.audioBook.translator.lastName}` : '',
                item_length: `${this.state.audioBook.lengthHours}:${this.state.audioBook.lengthMin}`,
              }
            ]
          }
        });
      }
    }
  }

  handleToggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    })
  }

  renderDetails() {    
    const title = this.props.audioBook && this.props.audioBook.title ? this.props.audioBook.title : null;
    const authors = this.props.audioBook?.authors['hydra:member'] ?? [];
    const narrators = this.props.audioBook && this.props.audioBook.narrators ? this.props.audioBook.narrators['hydra:member']: [];
    const lengthHours = this.props.audioBook && this.props.audioBook.lengthHours ? this.props.audioBook.lengthHours : null;
    const translator = this.props.audioBook && this.props.audioBook.translator ? this.props.audioBook.translator : null;
    const publisher = this.props.audioBook && this.props.audioBook.publisher ? this.props.audioBook.publisher : null;
    const publisherSlug = this.props.audioBook && this.props.audioBook.publisher ? this.props.audioBook.publisher['@id'].split('/')[3] : null;
    const lengthMin = this.props.audioBook && this.props.audioBook.lengthMin ? this.props.audioBook.lengthMin : null;
    const firstPublished = this.props.audioBook && this.props.audioBook.firstPublished ? DateFormater.formatDate(this.props.audioBook.firstPublished) : null;
    const language = this.props.audioBook && this.props.audioBook.language ? this.props.audioBook.language.title : null;
    return (
      <div ref={this.divDetailsMobile}>
        {title && <h1>{title}</h1>}
        {authors.length > 0 && 
          <div className='font-italic'>{getNamesFromArray(authors)}</div>
        }
        {narrators.length > 0 && <div>
          Narrated by: 
            <span className='font-italic'> {getNamesFromArray(narrators)}</span>          
        </div>
        }
        {(lengthHours>0 || lengthMin>0) && <div>Length: {lengthHours>0 && `${lengthHours}hr${lengthHours!==1 ? 's':''}`} {lengthHours>0 && lengthMin>0 && 'and'} {lengthMin>0 && `${lengthMin}min${lengthMin!==1 ? 's':''}`}</div>}
        {firstPublished && <div>First published: {firstPublished} </div>}
        {language && <div>Language: {language}</div>}
        {translator && <div>Translator: <span className='font-italic'>{translator.firstName} {translator.lastName}</span></div>}
        {publisher && <div>Publisher: <Link id='gtm-audiobook-page-publisher' to={`/publishers/${publisherSlug}`}>{publisher.name}</Link></div>}
      </div>
    )
  }

  renderEBookCta() {
      const price = this.props.audioBook && (this.props.audioBook.price || this.props.audioBook.price === 0) ? this.props.audioBook.price : null;
      const audioBookPrice = this.props.audioBook && this.props.audioBook.audioBook ? this.props.audioBook.audioBook.price : null;
      const audioBookSubscriptionPrice = this.props.audioBook && this.props.audioBook.audioBook ? this.props.audioBook.audioBook.subscriptionPrice : null;
      const geoBlocked = this.props.audioBook && this.props.audioBook.geoBlocked ? this.props.audioBook.geoBlocked : null;
      const audioBookGeoBlocked = this.props.audioBook && this.props.audioBook.audioBook ? this.props.audioBook.audioBook.geoBlocked : null;
      //user can claim only audiobook if
      //audiobook is not in library and
      //audiobook is an edition
      const hasCreditForAudiobook = this.props.user && this.props.user.hasCredit && this.props.audioBook.audioBook && !this.props.audioBook.audioBook.inLibrary && this.props.audioBook.audioBook.edition && this.props.audioBook.audioBook.moveToLibrary;
      const isPublished = this.props.audioBook ? this.props.audioBook.published : null;
      const isAudioBookPublished = this.props.audioBook && this.props.audioBook.audioBook ? this.props.audioBook.audioBook.published : null;
      const isFreeEBook = this.props.audioBook && !this.props.audioBook.inLibrary && this.props.audioBook.price === 0;
      const isFreeAudioBook = this.props.audioBook && this.props.audioBook.audioBook && !this.props.audioBook.audioBook.inLibrary && this.props.audioBook.audioBook.price === 0;
      const isZeroPriceInLibraryEBook = this.props.audioBook && this.props.audioBook.price === 0 && this.props.audioBook.inLibrary;
      const isZeroPriceInLibraryAudioBook = this.props.audioBook && this.props.audioBook.audioBook && this.props.audioBook.audioBook.price === 0 && this.props.audioBook.audioBook.inLibrary;
    return (
        <CtaHolder positionBottom={this.state.buttonsPosition}>
            <Cta>     
                {(!isPublished && !isAudioBookPublished) || (!isPublished && isAudioBookPublished === null) ? <InfoButton>Not available</InfoButton> : (geoBlocked && audioBookGeoBlocked) ||  (geoBlocked && audioBookGeoBlocked === null) ? 
                    <InfoButton>Not available in your region</InfoButton>
                :
                    <>           
                        {(price || price === 0) && 
                          <> 
                          {!isPublished ? <InfoButton>Not available</InfoButton> : !geoBlocked ?
                  
                            <button
                                    id='gtm-ebook-added-to-bag'
                                    name="eBook" 
                                    error="eBookInCart"
                                    onClick={(e) => this.handleAddToCart(e, 'eBook', false, false, isFreeEBook)}
                                    className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary ${this.props.bookReducer.eBookInCart || this.state.eBookSaved || isZeroPriceInLibraryEBook || this.state.zeroPriceAddedInLibraryEBook ? 'font-14' : 'font-20'} font-300 ${this.state.eBook ? 'submitting' : ''} ${this.state.eBookSaved || isZeroPriceInLibraryEBook || this.state.zeroPriceAddedInLibraryEBook ? 'c-btn__ghost--saved' : ''}`} 
                                    disabled={this.state.eBook || this.state.eBookSaved || this.props.bookReducer.eBookInCart || isZeroPriceInLibraryEBook || this.state.zeroPriceAddedInLibraryEBook}>
                                {this.state.zeroPriceAddedInLibraryEBook ? 'ADDED IN LIBRARY' : isZeroPriceInLibraryEBook ? 'ALREADY IN LIBRARY' : this.state.eBookSaved ? 'ADDED TO BAG!' : this.props.bookReducer.eBookInCart ? 'IN BAG' : 
                                    isFreeEBook ? <>
                                      <i 
                                          className="font-16 mr-8 icon-e-book"></i>
                                          <span className="font-12 font-secondary font-400 ls-9">ADD TO LIBRARY</span>
                                    </> :
                                    <>
                                      <i 
                                          name="eBook" 
                                          error="eBookInCart"
                                          className="font-16 mr-8 icon-e-book"></i>
                                      <span 
                                          name="eBook" 
                                          error="eBookInCart"
                                          className='mr-3'>£</span>
                                          <span
                                          name="eBook" 
                                          error="eBookInCart"
                                        >{price}</span>
                                    </>
                                }
                                <SpiracleLogoSmall />
                            </button>
                            :
                            <InfoButton><i className="icon-e-book no-hover" /> Not available in your region</InfoButton>
                          }
                          </>
                        }
                        {(audioBookPrice || audioBookPrice === 0) && 
                          <>
                          {!isAudioBookPublished ? <InfoButton>Not available</InfoButton> : !audioBookGeoBlocked ?
                            <button
                                id={`${this.state.bookClaimed ? 'gtm-audiobook-added-to-library' : 'gtm-audiobook-added-to-bag'}`}
                                name="eBookAudioBook" 
                                error="eBookAudioBookInCart"
                                onClick={(e) => this.handleAddToCart(e, 'audioBook', true, hasCreditForAudiobook, isFreeAudioBook)}
                                className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary ${this.props.bookReducer.eBookAudioBookInCart || this.state.eBookAudioBookSaved || this.state.bookClaimed || isZeroPriceInLibraryAudioBook || this.state.zeroPriceAddedInLibraryAudioBook ? 'font-14' : 'font-20'} font-300 ${this.state.eBookAudioBook ? 'submitting' : ''} ${this.state.eBookAudioBookSaved || this.state.bookClaimed || isZeroPriceInLibraryAudioBook || this.state.zeroPriceAddedInLibraryAudioBook ? 'c-btn__ghost--saved' : ''} ${hasCreditForAudiobook ? 'h-flex h-flex-center h-flex-middle' : ''}`} 
                                disabled={this.state.eBookAudioBook || this.state.eBookAudioBookSaved || this.props.bookReducer.eBookAudioBookInCart || this.state.bookClaimed || isZeroPriceInLibraryAudioBook || this.state.zeroPriceAddedInLibraryAudioBook}>
                                {this.state.zeroPriceAddedInLibraryAudioBook ? 'ADDED TO LIBRARY' : isZeroPriceInLibraryAudioBook ? 'ALREADY IN LIBRARY' : this.state.eBookAudioBookSaved || this.state.bookClaimed ? `ADDED TO ${this.state.bookClaimed ? 'LIBRARY' : 'BAG'}!` : this.props.bookReducer.eBookAudioBookInCart ? 'IN BAG' :
                                <>
                                  {!hasCreditForAudiobook && !isFreeAudioBook ? 
                                    <>
                                      <i 
                                          name="eBookAudioBook" 
                                          error="eBookAudioBookInCart"
                                          className="font-16 mr-8 icon-audio-book"></i>
                                      <span 
                                          name="eBookAudioBook" 
                                          error="eBookAudioBookInCart"
                                          className='mr-3'>£</span>
                                          <span
                                          name="eBookAudioBook" 
                                          error="eBookAudioBookInCart"
                                          >{audioBookPrice} {audioBookSubscriptionPrice && (audioBookSubscriptionPrice !== audioBookPrice) ? 
                                                    <span className='price-with-subscription'> / <span className='mr-3'>£</span>{audioBookSubscriptionPrice} <span>for Premium Members</span></span> 
                                                    : null
                                                }
                                      </span>
                                    </>
                                  : 
                                  <>
                                    <i 
                                      name="eBook" 
                                      error="eBookInCart"
                                      className="font-16 mr-8 icon-audio-book"></i>
                                    <span className="font-12 font-secondary font-400 ls-9">ADD TO LIBRARY</span>
                                  </>
                                  }
                                </>
                                }
                            <SpiracleLogoSmall />
                        </button>
                        : 
                            <InfoButton> <i className="icon-audio-book no-hover" /> Not available in your region</InfoButton>
                        }
                        </>
                        }
                    </>
                } 
            </Cta>
        </CtaHolder>
      )
  }

  renderAudioBookCta() {
      const price = this.props.audioBook && (this.props.audioBook.price || this.props.audioBook.price === 0) ? this.props.audioBook.price : null;  
      const subscriptionPrice = this.props.audioBook ? this.props.audioBook.subscriptionPrice : null;      
      const eBookPrice = this.props.audioBook && this.props.audioBook.eBook ? this.props.audioBook.eBook.price : null;
      const geoBlocked = this.props.audioBook && this.props.audioBook.geoBlocked ? this.props.audioBook.geoBlocked : null;
      const eBookGeoBlocked = this.props.audioBook && this.props.audioBook.eBook ? this.props.audioBook.eBook.geoBlocked : null;
      //user can claim only audiobook if
      //audiobook is not in library and
      //audiobook is an edition
      const hasCreditForAudiobook = this.props.user && this.props.user.hasCredit && this.props.audioBook && !this.props.audioBook.inLibrary && (this.props.audioBook.edition || this.props.audioBook.secondEdition) && this.props.audioBook.moveToLibrary;
      const isPublished = this.props.audioBook ? this.props.audioBook.published : null;
      const isEBookPublished = this.props.audioBook && this.props.audioBook.eBook ? this.props.audioBook.eBook.published : null;
      const isFreeAudioBook = this.props.audioBook && !this.props.audioBook.inLibrary && this.props.audioBook.price === 0;
      const isFreeEBook = this.props.audioBook && this.props.audioBook.eBook && !this.props.audioBook.eBook.inLibrary && this.props.audioBook.eBook.price === 0;
      const isZeroPriceInLibraryAudioBook = this.props.audioBook && this.props.audioBook.price === 0 && this.props.audioBook.inLibrary;
      const isZeroPriceInLibraryEBook = this.props.audioBook && this.props.audioBook.eBook && this.props.audioBook.eBook.price === 0 && this.props.audioBook.eBook.inLibrary;

      return (        
        <CtaHolder positionBottom={this.state.buttonsPosition}>
            <Cta>
                {(!isPublished && !isEBookPublished) || (!isPublished && isEBookPublished === null) ? <InfoButton>Not available</InfoButton> :
                  (geoBlocked && eBookGeoBlocked) || (geoBlocked && eBookGeoBlocked === null) ? 
                    <InfoButton>Not available in your region</InfoButton>
                :
                    <>
                        {(price || price === 0) &&
                            <>
                            {!isPublished ? <InfoButton>Not available</InfoButton> : !geoBlocked ?
                                <button
                                    id={`${this.state.bookClaimed ? 'gtm-audiobook-added-to-library' : 'gtm-audiobook-added-to-bag'}`}
                                    name="audioBookBtn" 
                                    error="audioBookInCart" 
                                    onClick={(e) => this.handleAddToCart(e, 'audioBook', false, hasCreditForAudiobook, isFreeAudioBook)} 
                                    className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary ${this.props.bookReducer.audioBookInCart || this.state.audioBookBtnSaved || this.state.bookClaimed || isZeroPriceInLibraryAudioBook || this.state.zeroPriceAddedInLibraryAudioBook ? 'font-14' : 'font-20'} font-300 ${this.state.audioBookBtn ? 'submitting' : ''} ${this.state.audioBookBtnSaved || this.state.bookClaimed || isZeroPriceInLibraryAudioBook || this.state.zeroPriceAddedInLibraryAudioBook ? 'c-btn__ghost--saved' : ''} ${hasCreditForAudiobook ? 'h-flex h-flex-center h-flex-middle' : ''}`} 
                                    disabled={this.state.audioBookBtn || this.state.audioBookBtnSaved || this.props.bookReducer.audioBookInCart || this.state.bookClaimed || isZeroPriceInLibraryAudioBook || this.state.zeroPriceAddedInLibraryAudioBook}>
                                    {this.state.zeroPriceAddedInLibraryAudioBook ? 'ADDED IN LIBRARY' : isZeroPriceInLibraryAudioBook ? 'ALREADY IN LIBRARY' : this.state.audioBookBtnSaved || this.state.bookClaimed ? `ADDED TO ${this.state.bookClaimed ? 'LIBRARY' : 'BAG'}!` : this.props.bookReducer.audioBookInCart ? 'IN BAG' : 
                                    <>
                                    {!hasCreditForAudiobook && !isFreeAudioBook ? 
                                    <>
                                        <i 
                                            name="audioBookBtn" 
                                            error="audioBookInCart" 
                                            className="font-16 mr-8 icon-audio-book"></i>
                                        <span 
                                        name="audioBookBtn" 
                                        error="audioBookInCart" 
                                        className='mr-3'>£</span><span                    
                                        name="audioBookBtn" 
                                        error="audioBookInCart" 
                                        >{price}  {subscriptionPrice && (subscriptionPrice !== price) ? 
                                                    <span className='price-with-subscription'> / <span className='mr-3'>£</span>{subscriptionPrice} <span>for Premium Members</span></span> 
                                                    : null
                                                }
                                        </span>
                                    </>
                                    : 
                                    <>
                                        <i 
                                        name="audioBookBtn" 
                                        error="audioBookInCart" 
                                        className="font-16 mr-8 icon-audio-book"></i>
                                        <span className="font-12 font-secondary font-400 ls-9">ADD TO LIBRARY</span>
                                    </>
                                    }
                                    </>
                                    }
                                    <SpiracleLogoSmall />
                                </button>
                            : 
                                <InfoButton><i className="icon-audio-book no-hover" /> Not available in your region</InfoButton>
                            }
                            </>
                        } 
                        {(eBookPrice || eBookPrice === 0) && 
                            <>
                                {!isEBookPublished ? <InfoButton>Not available</InfoButton> : !eBookGeoBlocked ?
                                    <button
                                        id='gtm-ebook-added-to-bag'
                                        name="audioBookEBook" 
                                        error="audioBookEBookInCart" 
                                        onClick={(e) => this.handleAddToCart(e, 'eBook', true, hasCreditForAudiobook, isFreeEBook)}
                                        className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary ${this.props.bookReducer.audioBookEBookInCart || this.state.audioBookEBookSaved || isZeroPriceInLibraryEBook || this.state.zeroPriceAddedInLibraryEBook ? 'font-14' : 'font-20'} font-300 ${this.state.audioBookEBook ? 'submitting' : ''} ${this.state.audioBookEBookSaved || isZeroPriceInLibraryEBook || this.state.zeroPriceAddedInLibraryEBook ? 'c-btn__ghost--saved' : ''}`} 
                                        disabled={this.state.audioBookEBook || this.state.audioBookEBookSaved || this.props.bookReducer.audioBookEBookInCart || isZeroPriceInLibraryEBook || this.state.zeroPriceAddedInLibraryEBook}>
                                        {this.state.zeroPriceAddedInLibraryEBook ? 'ADDED TO LIBRARY' : isZeroPriceInLibraryEBook ? 'ALREADY IN LIBRARY' : this.state.audioBookEBookSaved ? 'ADDED TO BAG!' : this.props.bookReducer.audioBookEBookInCart ? 'IN BAG' : 
                                        isFreeEBook ? <>
                                          <i 
                                              className="font-16 mr-8 icon-e-book"></i>
                                              <span className="font-12 font-secondary font-400 ls-9">ADD TO LIBRARY</span>
                                        </> : 
                                        <>
                                        <i 
                                            name="audioBookEBook" 
                                            error="audioBookEBookInCart" 
                                            className="font-16 mr-8 icon-e-book"></i>
                                        <span 
                                            name="audioBookEBook" 
                                            error="audioBookEBookInCart" 
                                            className='mr-3'>£</span><span
                                                name="audioBookEBook" 
                                                error="audioBookEBookInCart" 
                                            >{eBookPrice}</span>
                                        </>
                                        }
                                        <SpiracleLogoSmall />
                                    </button>
                                    : 
                                    <InfoButton><i className="icon-e-book no-hover" /> Not available in your region</InfoButton>
                                }
                            </>
                        }
                    </>
                }
            </Cta>
        </CtaHolder>        
      )
  }

  render() {
    const summary = this.props.audioBook && this.props.audioBook.summary ? ReactHtmlParser(this.props.audioBook.summary) : null;
    const bookCoverImage = this.props.audioBook && this.props.audioBook.cover ? this.props.audioBook.cover : null;
    const bookCoverAlt = this.props.audioBook && this.props.audioBook.coverAlt ? this.props.audioBook.coverAlt : '';    
    const violence = this.props.audioBook && this.props.audioBook.violence ? this.props.audioBook.violence : null;
    const style = this.props.audioBook && this.props.audioBook.style ? this.props.audioBook.style.title : null;    
    const styleId = this.props.audioBook && this.props.audioBook.style ? this.props.audioBook.style.id : null;    
    const isEdition = this.props.audioBook && this.props.audioBook.edition ? this.props.audioBook.edition : null;    
    const bookBgColor = this.props.audioBook && this.props.audioBook.bookBackgroundColour ? this.props.audioBook.bookBackgroundColour : null; 
    const containerBgColor = this.props.audioBook && this.props.audioBook.containerBackgroundColour ? this.props.audioBook.containerBackgroundColour : null; 
    const textColor = this.props.audioBook && this.props.audioBook.textColour ? this.props.audioBook.textColour : null; 
    const publisherBookLink = this.props.audioBook && this.props.audioBook.publisherBookLink? this.props.audioBook.publisherBookLink  : null;    
    const publisherBookName = this.props.audioBook && this.props.audioBook.publisherBookName ? this.props.audioBook.publisherBookName  : null;    
    const urlParam = '&styles%5B%5D=' + styleId;
    const isAvailableAsCard = this.props.audioBook && this.props.audioBook.availableAsCard;

    let bothBlocked

    if (window.location.href.includes('audiobooks')) {
      const geoBlocked = this.props.audioBook && this.props.audioBook.geoBlocked ? this.props.audioBook.geoBlocked : null;
      const eBookGeoBlocked = this.props.audioBook && this.props.audioBook.eBook ? this.props.audioBook.eBook.geoBlocked : null;
      if ((geoBlocked && eBookGeoBlocked) || (geoBlocked && eBookGeoBlocked === null)) {
        bothBlocked = true
      }
    } else {
      const geoBlocked = this.props.audioBook && this.props.audioBook.geoBlocked ? this.props.audioBook.geoBlocked : null;
      const audioBookGeoBlocked = this.props.audioBook && this.props.audioBook.audioBook ? this.props.audioBook.audioBook.geoBlocked : null;
      if ((geoBlocked && audioBookGeoBlocked) ||  (geoBlocked && audioBookGeoBlocked === null)) {
        bothBlocked = true
      }
    } 
  
    return (
      <ContainerOrder className="container container__big">
        <PageMeta>
          <title>{this.props.audioBook ? this.props.audioBook.title + ' | ' : ''}Spiracle Audiobooks</title>
          <meta name="description" content={this.props.audioBook && this.props.audioBook.metaDescription ? this.props.audioBook.metaDescription : 'Ambitious, imaginative, original audiobooks, selected by authors and small publishers, fed by years of delighted reading.'} />
          <meta name="keywords" content={this.props.audioBook && this.props.audioBook.metaKeywords ? this.props.audioBook.metaKeywords : 'spiracle audiobooks'} />
          <link rel="canonical" href={window.location.href} />
        </PageMeta>
        {this.state.isLoading ? <SkeletonAudioBookSingle /> :
        (<>
          <Popup toggleModalOpen={this.handleToggleModal} isOpen={this.state.isModalOpen} text={this.props.audioBook.sample} />

          {this.props.location.state && this.props.location.state.backButton ? <BackButton /> : null}

          <AudiobookDetails isEdition={isEdition} bookBgColor={bookBgColor} containerBgColor={containerBgColor} textColor={textColor}> 
            
            {this.props.audioBook && this.props.audioBook.price === 0 ? <FreeBook /> : null}           

            {isAvailableAsCard ? <AudiobookInCard /> : null}           
            
            <Grid container spacing={0}>
              <Grid item xs={12} lg={4} xl={5}>
                <AudiobookImage>
                  <BgWrapperMob isEdition={isEdition} bookBgColor={bookBgColor} containerBgColor={containerBgColor} textColor={textColor}>
                    <ImageHolder>
                        <CoverImage
                            src={bookCoverImage}   
                            alt={bookCoverAlt}                          
                        />
                    </ImageHolder>
                    <Hidden mdDown>
                      {this.props.location.pathname.includes('ebooks') ?
                        <StyledButton
                            className="c-btn"
                            onClick={() => this.handleOpenAudioPlayer()}
                          >
                            <i className={this.props.audioBook && this.props.audioBook.download === '' ? 'icon-download' : this.props.audioBook.download ? 'icon-download' : 'icon-e-book'} />
                            {this.props.audioBook && this.props.audioBook.download === '' ? 'processing' : this.props.audioBook.download ? 'download' : 'sample'}
                        </StyledButton> 
                        :
                        <StyledButton
                            id={`${this.props.audioBook && this.props.audioBook.download ? 'gtm-player-play-desktop' : 'gtm-player-sample-desktop'}`}
                            className="c-btn"
                            onClick={() => this.handleOpenAudioPlayer()}
                          >
                            <i className="icon-audio-book" />
                            {this.props.audioBook && this.props.audioBook.download ? 'play' : 'sample'}
                        </StyledButton>
                      }
                    </Hidden>
                  </BgWrapperMob>
                </AudiobookImage>
              </Grid>
              <Grid item xs={12} lg={4} xl={3}>
                <Details>
                  <BgWrapperMob isEdition={isEdition} bookBgColor={bookBgColor} containerBgColor={containerBgColor} textColor={textColor}>
                    <Hidden lgUp> 
                      {this.props.location.pathname.includes('ebooks') ?
                        <StyledButton
                            className="c-btn mt-10 mb-20"
                            onClick={() => this.handleOpenAudioPlayer()}
                          >
                            <i className={this.props.audioBook && this.props.audioBook.download === '' ? 'icon-download' : this.props.audioBook.download ? 'icon-download' : 'icon-e-book'} />
                            {this.props.audioBook && this.props.audioBook.download === '' ? 'processing' : this.props.audioBook.download ? 'download' : 'sample'}
                        </StyledButton> 
                        :
                        <StyledButton
                            id={`${this.props.audioBook && this.props.audioBook.download ? 'gtm-player-play' : 'gtm-player-sample'}`}
                            className="c-btn mt-10 mb-20"
                            onClick={() => this.handleOpenAudioPlayer()}
                          >
                            <i className="icon-audio-book" />
                            {this.props.audioBook && this.props.audioBook.download ? 'play' : 'sample'}
                        </StyledButton>
                      }
                    </Hidden>
                    <Hidden mdUp>
                      <Collapse
                        in={this.state.areDetailsShown}
                        collapsedSize={this.state.detailsMobileHeight > 100 ? 100 : this.state.detailsMobileHeight}
                      >
                        {this.renderDetails()}
                      </Collapse>
                    </Hidden>
                    <Hidden smDown>{this.renderDetails()}</Hidden>
                    {violence && (
                      <Hidden mdDown>
                        <div className="mt-18">CAUTION!</div>
                        <div>Contains extreme violence</div>
                      </Hidden>
                    )}

                    {this.state.detailsMobileHeight > 100 && (
                      <i
                        className={
                          "icon-arrow-down " +
                          (this.state.areDetailsShown ? "rotated" : "")
                        }
                        onClick={() => this.toggleVisibility("areDetailsShown")}
                    />
                    )}
                  </BgWrapperMob>
                </Details>
              </Grid>
              <Grid item xs={12} lg={4} xl={3}>
                {summary && (
                  <Summary>
                    <BgWrapperMob isEdition={isEdition} bookBgColor={bookBgColor} containerBgColor={containerBgColor} textColor={textColor}>
                      <Hidden mdUp>
                        <div className="font-12 ls-15 text-center font-uppercase mb-15">
                          Summary
                        </div>
                      </Hidden>
                      <Hidden mdUp>
                        <Collapse
                          in={this.state.isFullSummaryShown}
                          collapsedSize={this.state.summaryHeight > 118 ? 118 : this.state.summaryHeight}
                        >
                          <div ref={this.divElementMobile}>{summary}</div>
                        </Collapse>
                      </Hidden>
                      <Hidden smDown>
                        <Collapse
                          in={this.state.isFullSummaryShown}
                          collapsedSize={this.state.summaryHeight > 437 ? 437 : this.state.summaryHeight}
                        >
                          <div ref={this.divElement}>{summary}</div>
                        </Collapse>
                      </Hidden>
                      <Hidden smDown>
                        {this.state.summaryHeight > 437 && (
                           <span
                            style={{
                            cursor: "pointer",
                            color: "rgba(0, 0, 0, .3)",
                          }}
                          onClick={() =>
                            this.toggleVisibility("isFullSummaryShown")
                          }
                        >
                          {this.state.isFullSummaryShown
                            ? "Read less"
                            : "Read more"}
                        </span>
                        )}
                      </Hidden>
                      {this.state.summaryHeight > 118 && (
                        <i
                          className={
                            "icon-arrow-down " +
                            (this.state.isFullSummaryShown ? "rotated" : "")
                          }
                          onClick={() =>
                            this.toggleVisibility("isFullSummaryShown")
                          }
                        />
                      )}
                    </BgWrapperMob>
                  </Summary>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={0} className="detached">
              <Grid item xs={12} lg={4} xl={5}></Grid>

              {isEdition && this.props.user && (
                <Hidden lgUp>   
                    <Grid item xs={12}>
                        <BgWrapperMob isEdition={isEdition} bookBgColor={bookBgColor} containerBgColor={containerBgColor} textColor={textColor} className="text-center">
                            <Link id='gtm-audiobook-page-visit-spiracle-edition' to={this.state.type === "ebook" ? "/ebooks" : "/editions"} className="c-btn c-btn__brown font-primary font-22">
                                Visit the Spiracle <span className="font-italic">edition</span>
                            </Link>
                        </BgWrapperMob>
                    </Grid>
                </Hidden>
              )}
              
              <Grid item xs={12} lg={4} xl={3}>
                {style && (
                  <Genre>
                    <BgWrapperMob>
                      <small>Style:</small>
                      <StyledChip id='gtm-style-chip' onClick={() => this.props.history.push(`/${this.props.location.pathname.includes('audiobooks') ? 'audiobooks' : 'ebooks'}?page=1&itemsPerPage=12${urlParam}`)} label={style} variant="outlined" />
                    </BgWrapperMob>
                  </Genre>
                )}
              </Grid>

              {violence && (
                <Hidden lgUp>                  
                  <Grid item xs={12}>
                    <BgWrapperMob>
                      <div className="text-center font-14" style={{letterSpacing: '0.03em'}}>
                        <span style={{fontSize: '12px', letterSpacing:'0.15em'}}>CAUTION!</span> Contains extreme violence
                      </div>
                    </BgWrapperMob>
                  </Grid>
                </Hidden>
              )}

              <Grid item xs={12} lg={4} xl={3}>                
                    {this.state.type === "ebook" ?
                        (this.renderEBookCta())
                        :
                        (this.renderAudioBookCta())                    
                    }                     
              </Grid>
            </Grid>           
          </AudiobookDetails>   

           {isEdition && this.props.user && (
               <Grid container spacing={0}>
                    <Hidden mdDown>   
                        <Grid item xs={12}>
                            <BgWrapperDesktop isEdition={isEdition} bookBgColor={bookBgColor} containerBgColor={containerBgColor} textColor={textColor} className="text-center">
                                <Link id='gtm-audiobook-page-visit-spiracle-edition' to={this.state.type === "ebook" ? "/ebooks" : "/editions"} className="c-btn c-btn__brown font-primary font-22">
                                    Visit the Spiracle <span className="font-italic">edition</span>
                                </Link>
                            </BgWrapperDesktop>
                        </Grid>
                    </Hidden>
                </Grid>
              )}
            
            {
              !bothBlocked ? 
              <GiftInfo>
                <i className="icon-gift no-hover"></i>
                <p>You can send this as a gift in checkout</p>
              </GiftInfo> : null
            }

            {publisherBookLink && (
                <PhysicalBookLink>Buy the physical book at <br /> 
                    <a className="font-secondary" target="_blank" rel="noreferrer" href={publisherBookLink}>{publisherBookName}</a>
                </PhysicalBookLink>
            )}

          {this.state.audioBookRelated && this.state.audioBookRelated['hydra:member'].length > 0 ? <AudioBookSingleCarousel title='Related' data={this.state.audioBookRelated['hydra:member']} /> : null}

          {(this.props.audioBook?.relatedArticles['hydra:member'].length > 0 ||
            this.props.audioBook?.relatedInterviews['hydra:member'].length > 0 ||
            this.props.audioBook?.relatedDiscussions['hydra:member'].length > 0) &&
              <ContainerRelated className="container">
                <Headline className='font-italic'>Related</Headline>

                {this.props.audioBook?.relatedArticles['hydra:member'].map((article, index)=>(
                  <ArticleItem 
                        key={`article-${index}`}
                        image={article.image}
                        alt={article.alt}
                        author={article.author ? `${article.author.firstName} ${article.author.lastName}` : null}
                        category={article.category ? article.category.name : null}
                        title={article.title}
                        slug={article.slug}
                        type={article.type ? article.type.title : null}
                        articleType='articles'
                  />
                ))}

                {this.props.audioBook?.relatedInterviews['hydra:member'].map((interview, index)=>(
                  <ArticleItem 
                        key={`interview-${index}`}
                        image={interview.image}
                        alt={interview.alt}
                        author={interview.author ? `${interview.author.firstName} ${interview.author.lastName}` : null}
                        category={interview.category ? interview.category.name : null}
                        title={interview.title}
                        slug={interview.slug}
                        type={interview.type ? interview.type.title : null}
                        articleType='interviews'
                  />
                ))}

                {this.props.audioBook?.relatedDiscussions['hydra:member'].map((discussion, index)=>(
                  <ArticleItem 
                        key={`discussion-${index}`}
                        image={discussion.image}
                        alt={discussion.alt}
                        author={discussion.author ? `${discussion.author.firstName} ${discussion.author.lastName}` : null}
                        category={discussion.category ? discussion.category.name : null}
                        title={discussion.title}
                        slug={discussion.slug}
                        type={discussion.type ? discussion.type.title : null}
                        articleType='discussions'
                  />
                ))}
              </ContainerRelated> 
          }
        </>)
      }
      </ContainerOrder>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userReducer.user,
  widgetPlayer: state.bookReducer.widgetPlayer,
  audioBook: state.bookReducer.currentBook,
  cart: state.cartReducer.cart,
  bookReducer: state.bookReducer,
})

const mapDispatchToProps = dispatch => {
  return {
      onGetBook: (book) => dispatch(bookActionTypes.onGetBook(book)),
      onSwitchWidgetPlayer: () => dispatch(bookActionTypes.onSwitchWidgetPlayer()),
      onAddCart: (cart) => dispatch(userActionTypes.onAddCart(cart)),
      onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
      onAddCartItem: (cartItem) => dispatch(cartActionTypes.onAddCartItem(cartItem)),
      onGetCurrentBook: (book) => dispatch(bookActionTypes.onGetCurrentBook(book)),
      onRemoveCurrentBook: () => dispatch(bookActionTypes.onRemoveCurrentBook()),
      onRemoveBookFromCart: (bookType) => dispatch(bookActionTypes.onRemoveBookFromCart(bookType)),
      onAddBookToCart: (bookType) => dispatch(bookActionTypes.onAddBookToCart(bookType)),
      removeInCartItems: () => dispatch(bookActionTypes.removeInCartItems()),
      onAddSingleBookToLibrary: (libraryItem) => dispatch(libraryActionTypes.onAddSingleBookToLibrary(libraryItem)),
      onAddBookToLibrary: () => dispatch(userActionTypes.onAddBookToLibrary()),
      onAddAudioFiles: (audioFiles, download) => dispatch(bookActionTypes.onAddAudioFiles(audioFiles, download)),
      onTurnOnWidgetPlayer: () => dispatch(bookActionTypes.onTurnOnWidgetPlayer())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Audiobook);