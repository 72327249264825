import styled from 'styled-components/macro';

export const AudiobookInCardContainer = styled.div`
    position: absolute;
    top: -10px;
    right: 45px;
    z-index: 1;

    ${(props) => props.theme.breakpoints.down('md')} {
      ${props => {
            if (!props.small) {
                return `
                    right: 0;
                `
            }
        }}
    }

    ${props => {
        if (props.small) {
            return `
                top: -6px;
                right: -4px;
            `
        }
    }}
`;