import React from "react";

import { Discount, Gift, Image, MembershipCardContainer, Price, Title, Type } from './style';

import logo from '../../images/Spiracle-small.svg';

import { subscriptionPlans } from 'consts/consts';

function MembershipCard({plan, price, originalPrice, gift, giftEmail, discountCode, discountPercent}) {
    const pricesNotEqual = price !== originalPrice

    const subscription = Object.values(subscriptionPlans).find((item) => item.plan === plan);
    const name = subscription?.name;
    const type = subscription?.type;

    return (
        <MembershipCardContainer>
            <Image>
                <img src={logo} alt="Spiracle logo" />
            </Image>
            <div>
                <Title>Spiracle <span>subscription</span></Title>
                <Type>{`${name} ${type}`}</Type>
                {/* <Price><span>£</span>{price}</Price> */}
                <Price>
                    <span className='mr-8'><span>£</span>{price}</span>
                    {pricesNotEqual ? <span className='bracket'>(</span> : null}
                    {pricesNotEqual ? <span className={`${pricesNotEqual ? 'old-price' : ''}`}><span>£</span>{originalPrice}</span> : null}
                    {pricesNotEqual ? <span className='bracket'>)</span> : null}
                </Price>
            </div>
            {gift ? 
                <Gift>
                    <i title={giftEmail} className="icon-gift no-hover"></i>
                </Gift> 
                : null}
            {discountCode ? 
                <Discount>
                    <i title={`${discountCode} - ${discountPercent}%`} className="icon-discount-2 no-hover font-18"></i>
                </Discount> 
                : null}
        </MembershipCardContainer>
    )
}


export default MembershipCard;