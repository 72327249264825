import './App.scss';

import * as cartActionTypes from './store/actions/cartActions';
import * as libraryActionTypes from './store/actions/libraryActions';
import * as userActionTypes from './store/actions/userActions';

import { BrowserRouter,Redirect, Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import TagManager from 'react-gtm-module';

import ApiService from './services/api.service';
import ArticlesList from './pages/Articles/ArticlesList/Articles';
import ArticlesSingle from './pages/Articles/ArticlesSingle/ArticlesSingle';
import AudioBookPlayer from './pages/AudioBookPlayer/AudioBookPlayer';
import AudioBooks from './pages/AudioBooks/AudioBooks';
import Audiobook from './pages/Audiobook/Audiobook';
import AudiobookEdition from 'pages/AudiobookEdition/AudiobookEdition';
import ChangePassword from './pages/Auth/ChangePassword/ChangePassword';
import ClaimBook from 'pages/Auth/ClaimBook/ClaimBook';
import ConfirmUser from './pages/Auth/ConfirmUser/ConfirmUser';
import Faq from './pages/InfoPages/FAQ/Faq';
import ForgotPassword from './pages/Auth/ForgotPassword/ForgotPassword';
import InfoPages from './pages/InfoPages/InfoPages/InfoPages';
/* Layouts */
import LayoutMain from "layout/Main/MainLayout";
import LayoutRedemption from "layout/Redemption/RedemptionLayout";
import LayoutPlayer from "layout/Player/PlayerLayout";
import LayoutRetailer from 'layout/Retailer/RetailerLayout';
import Library from './pages/Library/Library';
import Loader from './shared/Loader/Loader';
import Login from './pages/Auth/Login/Login';
// import Membership from './pages/Membership/Membership';
import MembershipConfirmation from './pages/Membership/MembershipConfirmation';
import MembershipConfirmationGift from './pages/Membership/MembershipConfirmationGift';
import MembershipConfirmationGiftXmas from 'pages/Membership/MembershipConfirmationGiftXmas';
import MembershipConfirmationTrial from './pages/Membership/MembershipConfirmationTrial';
import MembershipDetails from './pages/Membership/MembershipDetails';
import Mercury from 'shared/Mercury/Mercury';
import Murmurations from './pages/Murmurations/Murmurations';
import NewsletterSignup from './pages/Settings/NewsletterSignup/NewsletterSignup';
import OrderConfirmation from './pages/Order/OrderConfirmation';
import OrderListHistory from './pages/Order/OrderListHistory';
import Page403 from './pages/403/Page403';
import Page404 from './pages/404/Page404';
import Page500 from './pages/500/Page500';
import PageMeta from './shared/PageMeta/PageMeta';
import Payment from 'pages/Ecommerce/Payment/Payment';
import PaymentProblem from './pages/Order/PaymentProblem';
// import PromotionPayment from './pages/Promotion/PromotionPayment';
import PromotionXmasGift from 'pages/Promotion/PromotionXmasGift';
import PromotionXmasFree from 'pages/Promotion/PromotionXmasFree';
import PromotionXmas from 'pages/Promotion/PromotionXmas';
import Publisher from './pages/Publisher/Publisher';
import Publishers from './pages/Publishers/Publishers';
import Register from './pages/Auth/Register/Register';
import RetailerLoginPage from 'pages/Retailer/RetailerLoginPage';
import CardActivationPage from 'pages/Retailer/CardActivationPage';
import RedeemPage from 'pages/Redeem/RedeemPage';
import RedeemConfirmationPage from 'pages/Redeem/RedeemConfirmationPage';
import RedeemLoginPage from 'pages/Redeem/RedeemLoginPage';
import RedeemSignUpPage from 'pages/Redeem/RedeemSignUpPage';
import RedeemErrorPage from 'pages/Redeem/RedeemErrorPage';
import RedeemWelcomePage from 'pages/Redeem/RedeemWelcomePage';
import Redemption from './pages/Redemption/Redemption';
import RedemptionConfirmation from 'pages/Redemption/RedemptionConfirmation';
import RedemptionError from 'pages/Redemption/RedemptionError';
import RedemptionLogin from 'pages/Redemption/RedemptionLogin';
import RedemptionSignup from 'pages/Redemption/RedemptionSignUp';
import RedemptionWelcome from 'pages/Redemption/RedemptionWelcome';
import ResetPassword from './pages/Auth/ResetPassword/ResetPassword';
/* Styles */
import { ThemeProvider as SCThemeProvider } from "styled-components";
import ScrollTop from './shared/ScrollTop/ScrollTop';
import SearchArticles from './pages/Search/SearchArticles';
import SearchBooks from './pages/Search/SearchBooks';
import SecondEdition from 'pages/SecondEdition/SecondEdition';
import Settings from './pages/Settings/Settings/Settings';
import ShoppingBag from './pages/ShoppingBag/ShoppingBag';
import { Theme } from "styles/Theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import OrderConfirmationIssue from 'pages/Order/OrderConfirmationIssue';
// import XmasMembership from 'pages/Membership/XmasMembership';
import Browse from 'pages/Browse/Browse';
import Homepage2 from 'pages/Homepage/Homepage2';
import AstonishingMemoirs from 'pages/AstonishingMemoirs/AstonishingMemoirs';
import WritersDilemma from 'pages/WritersDilemma/WritersDilemma';
import MembershipRedesign from 'pages/Membership/MembershipRedesign';
import RetailerRedirect from './shared/RetailerRedirect/RetailerRedirect';
import AudiobooksInCard from 'pages/AudiobooksInCard/AudiobooksInCard';
import config from 'config';

class App extends Component {

  state = {
      isLoading: true,
      infoPages: [],
      jwtToken: null,
      uuid: null,
    }

  componentDidMount() {
    if(config.apiGateway.URL.includes('spiracleaudiobooks.com')) {
      // google tag manager
      const tagManagerArgs = {
        gtmId: 'GTM-T6PBK8KC'
      }
      TagManager.initialize(tagManagerArgs)
    }

    const token = localStorage.getItem('token');
    const params = new URLSearchParams();
    params.append('page', 1);
    params.append('itemsPerPage', 10);

    if (token) {
        ApiService.getUser().then(
          user => {
            this.props.onSetUser(user.data);
            this.setState({
              jwtToken: user.data.jwtMercuryToken,
              uuid: user.data.uuid
            })
            ApiService.getLibrary(params).then(
              library => {
                this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                if (user.data.cart) {
                  ApiService.getCartItems(user.data.cart.uuid).then(
                    cart => {
                      this.props.onGetCartItems(cart.data['hydra:member'])
                      this.setState({
                        isLoading: false
                      })
                    }
                  )
                } else {              
                  this.setState({
                    isLoading: false
                  })
                }
              }
            )
          }
      ).catch(
        e => {
          this.setState({
            isLoading: false
          })
          // don't know why this was here
          // it will refresh the page every
          // time you fail on login
          // if (e.response && e.response.status === 401) {
          //   window.location.reload();
          // }
          if (e.response && e.response.status >= 500) {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('tokenRefreshedDate');
          }
        }
      )
    } else {
      //add cart items for anonymous
      let cartId = cookie.load('cartId')
      if (cartId) {
        if (!window.location.href.includes('shopping-bag')) {
          ApiService.getCartItems(cartId).then(
            cart => {
              this.props.onGetCartItems(cart.data['hydra:member'])
              this.setState({
                isLoading: false
              })
            }
          ) 
        } else {
          this.setState({
            isLoading: false
          });
        }
      } else {
        this.setState({
          isLoading: false
        });
      }
    }
  }

  render() {
    let content 
    const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
        <Route
            {...rest}        
            render={(props) => (
          
            <Layout {...props}>
                <Component {...props}/>    
            </Layout>
        )}
      />
    );


    if (this.state.isLoading) {
      content = <main><Loader /></main>
    } else {
      content = (
        <ThemeProvider theme={Theme}>
          <SCThemeProvider theme={Theme}>

              <PageMeta>
                  <title>Spiracle</title>
                  <meta name="description" content="Ambitious, imaginative, original audiobooks, selected by authors and small publishers, fed by years of delighted reading." />
                  <meta name="keywords" content="spiracle audiobooks" />
              </PageMeta>
              
              <ScrollTop />
              <RetailerRedirect />
    
              <Switch>
                <Redirect from="/membership" to="/subscription" />
                <Redirect from="/info/contact-support" to="/info/support" />
                
                <AppRoute
                    exact
                    path="/"
                    component={Homepage2}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/login"
                    component={Login}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/sign-up"
                    component={Register}
                    layout={LayoutMain}
                />
                <Route
                    exact
                    path="/app-sign-up"
                    component={Register}
                />
                <AppRoute
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/reset-password/:uuid"
                    component={ResetPassword}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/confirm/:token"
                    component={ConfirmUser}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/settings"
                    component={Settings}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/newsletter-signup"
                    component={NewsletterSignup}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path={`/info/:slug`}
                    // render={(props) => <InfoPages {...props} />}
                    component={InfoPages}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/change-password"
                    component={ChangePassword}
                    layout={LayoutMain}
                />
                <AppRoute exact path="/faq" component={Faq} layout={LayoutMain} />
                <AppRoute
                    exact
                    path={["/articles", "/interviews", "/discussions"]}
                    component={ArticlesList}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path={[
                        "/articles/:slug",
                        "/interviews/:slug",
                        "/discussions/:slug",
                    ]}
                    component={ArticlesSingle}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path={["/audiobooks", "/ebooks"]}
                    component={AudioBooks}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path={["/audiobooks/:slug", "/ebooks/:slug"]}
                    component={Audiobook}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path='/editions'
                    component={AudiobookEdition}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path='/subscriber-selection'
                    component={SecondEdition}
                    layout={LayoutMain}
                />
                <AppRoute exact path={["/player/:slug", "/sample/:slug"]} component={AudioBookPlayer} layout={LayoutPlayer} />
                 <AppRoute
                    exact
                    path="/murmurations"
                    component={Murmurations}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/publishers"
                    component={Publishers}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/publishers/:slug"
                    component={Publisher}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/shopping-bag"
                    component={ShoppingBag}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    //new successful payment
                    path="/payment-successful"
                    component={OrderConfirmation}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    //new successful payment
                    path="/payment-successful-problem"
                    component={OrderConfirmationIssue}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/payment-problem"
                    component={PaymentProblem}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/orders"
                    component={OrderListHistory}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/payment"
                    component={Payment}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/search-articles"
                    component={SearchArticles}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/search-books"
                    component={SearchBooks}
                    layout={LayoutMain}
                />
                {/* <AppRoute
                  exact
                  path="/payment-successful"
                  component={PaymentSucceed}
                  layout={LayoutMain}
                /> */}
                <AppRoute
                    exact
                    path="/access-denied"
                    component={Page403}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/404"
                    component={Page404}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/subscription"
                    component={MembershipRedesign}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/subscription-details"
                    component={MembershipDetails}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/subscription-successful"
                    component={MembershipConfirmation}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/subscription-successful-gift"
                    component={MembershipConfirmationGift}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/subscription-successful-trial"
                    component={MembershipConfirmationTrial}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/library"
                    component={Library}
                    layout={LayoutMain}
                />
                <AppRoute
                  exact
                  path={["/get-book", "/get-subscription"]}
                  component={ClaimBook}
                  layout={LayoutMain}
                />
                {/* <AppRoute
                  exact
                  path="/promo/:slug"
                  component={PromotionPayment}
                  layout={LayoutMain}
                /> */}
                 <AppRoute
                  exact
                  path={"/gift"}
                  component={PromotionXmasGift}
                  layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/xmas-successful-gift"
                    component={MembershipConfirmationGiftXmas}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/free-trial"
                    component={PromotionXmasFree}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/xmas"
                    component={PromotionXmas}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/server-error"
                    component={Page500}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/reseller"
                    component={RetailerLoginPage}
                    layout={LayoutRetailer}
                />
                <AppRoute
                    exact
                    path="/activation"
                    component={CardActivationPage}
                    layout={LayoutRetailer}
                />
                <AppRoute
                    exact
                    path="/redemption"
                    component={RedeemPage}
                    layout={LayoutRedemption}
                />
                <AppRoute
                    exact
                    path="/redemption/confirmation"
                    component={RedeemConfirmationPage}
                    layout={LayoutRedemption}
                />
                <AppRoute
                    exact
                    path="/redemption/login"
                    component={RedeemLoginPage}
                    layout={LayoutRedemption}
                />
                <AppRoute
                    exact
                    path="/redemption/sign-up"
                    component={RedeemSignUpPage}
                    layout={LayoutRedemption}
                />
                <AppRoute
                    exact
                    path="/redemption/error"
                    component={RedeemErrorPage}
                    layout={LayoutRedemption}
                />
                <AppRoute
                    exact
                    path="/redemption/welcome"
                    component={RedeemWelcomePage}
                    layout={LayoutRedemption}
                />
                <AppRoute
                    exact
                    path="/redemption/:spisin"
                    component={Redemption}
                    layout={LayoutRedemption}
                />
                <AppRoute
                    exact
                    path="/redemption/:spisin/confirmation"
                    component={RedemptionConfirmation}
                    layout={LayoutRedemption}
                />
                <AppRoute
                    exact
                    path="/redemption/:spisin/error"
                    component={RedemptionError}
                    layout={LayoutRedemption}
                />
                <AppRoute
                    exact
                    path="/redemption/:spisin/signup"
                    component={RedemptionSignup}
                    layout={LayoutRedemption}
                />
                <AppRoute
                    exact
                    path="/redemption/:spisin/login"
                    component={RedemptionLogin}
                    layout={LayoutRedemption}
                />
                <AppRoute
                    exact
                    path="/redemption/:spisin/welcome"
                    component={RedemptionWelcome}
                    layout={LayoutRedemption}
                />
                <AppRoute
                    exact
                    path="/audiobooks-in-a-card"
                    component={AudiobooksInCard}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/browse"
                    component={Browse}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/astonishing-memoirs"
                    component={AstonishingMemoirs}
                    layout={LayoutMain}
                />
                <AppRoute
                    exact
                    path="/writers-dilemma"
                    component={WritersDilemma}
                    layout={LayoutMain}
                />
                <AppRoute component={Page404} layout={LayoutMain} />
              </Switch>

          </SCThemeProvider>
        </ThemeProvider>
      );
    }


    return (
      <React.Fragment>
        <BrowserRouter basename="/">
            {content}
        </BrowserRouter>
        {/* mercury needs to be here 
        to always listen for changes
        on user, but prevent making 
        refreshing page */}
        <Mercury />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
      onGetCartItems: (cartItems) => dispatch(cartActionTypes.onGetCartItems(cartItems)),
      onGetLibraryItems: (libraryItems, totalLibraryItems) => dispatch(libraryActionTypes.onGetLibraryItems(libraryItems, totalLibraryItems)),
  }
}

export default connect(null, mapDispatchToProps)(App);
