import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function RetailerRedirect() {
    const location = useLocation();
    const user = useSelector(state => state.userReducer.user);
 
    useEffect(() => {
        if (!user?.outlet) {
            const redirect = location.pathname.endsWith('/activation') || location.pathname.endsWith('/activation/');
            if (redirect) {
                window.location.pathname = '/';
            }
        } else {
            const redirect = !location.pathname.endsWith('/activation') && !location.pathname.endsWith('/reseller') && !location.pathname.endsWith('/server-error');
            if (redirect) {
                window.location.pathname = '/activation';
            }
        }
    }, [location.pathname, user]);

    return null;
}

export default RetailerRedirect;