import styled from "styled-components/macro";

export const Dash = styled.span``;

export const StyledBanner = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 76;
    padding: 6px 10px;
    background: #F1E5A3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    letter-spacing: 0.72px;
    transition: all 0.3s ease-in-out;

    &.hide {
        ${(props) => props.theme.breakpoints.down('sm')} {
            transform: translateY(-350%);
        }
    }

    ${(props) => props.theme.breakpoints.up('md')} {
        flex-direction: row;
        padding: 6px 20px;
        height: 63px;
        line-height: 63px;
        font-size: 18px;
    }

    .show-banner-payment-failed & {
        top: 61px;

        ${(props) => props.theme.breakpoints.up('lg')} {
            top: 59px;
        }
    }

    img {
        height: 35px;

        ${(props) => props.theme.breakpoints.up('md')} {
            height: 49px;
            margin-left: auto;
        }
    }

    a {
        text-decoration: underline;

        &:hover {
            color: #721b00;
        }

        ${(props) => props.theme.breakpoints.up('lg')} {
            margin-right: 110px;
        }
    }

    i {
        position: absolute;
        top: 10px;
        right: 10px;

        ${(props) => props.theme.breakpoints.up('md')} {
            position: static;
            margin-left: auto;
        }
    }

    ${Dash} {
        margin: 0 2px;

        ${(props) => props.theme.breakpoints.up('md')} {
            margin: 6px 5px 0;
        }
    }
`;

