import { Amount, Background, Cover, StyledPayment, Title, Subtitle, PageTitle } from './style';
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import CheckoutForm from 'components/UI/Ecommerce/CheckoutForm/CheckoutForm';
import Discount from 'components/UI/Discount/Discount';
// import Select from 'components/UI/Select/Select';
import ChooseMembershipType from 'components/UI/MembershipGift/ChooseMembershipType';
import Cookies from 'js-cookie';
import { Elements } from "@stripe/react-stripe-js";
import PageMeta from '../../../shared/PageMeta/PageMeta';
import { connect } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
// import membershipImage from '../../../images/membership-image.svg';
import config from '../../../config';
import { subscriptionPlans } from 'consts/consts';

class Payment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoadingDiscount: false,
        }

        this.stripePromise = loadStripe(config.stripe.STRIPE_PUBLISH_KEY);
    }

    componentDidMount() {
        const cart = this.props.cart.length
        const subscription = this.props.location?.state?.subscription;
        const updateCard = this.props.location?.state?.update;
        const promotion = this.props.promotion;
        if (!cart && !subscription && !updateCard && !promotion) {
            this.props.history.push('/')
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.cart !== this.props.cart) {
            const cart = this.props.cart.length
            if (!cart) {
                this.props.history.push('/')
            }
        }
    }

    pullDiscount = (data) => {
        this.setState({
            isLoadingDiscount: data.isLoadingDiscount
        })
    }

    render() {
        let bookTypeOne;
        if (this.props.cart.length > 0) {
            if (this.props.cart[this.props.cart.length - 1].audioBook) {
                bookTypeOne = 'audioBook'
            } else {
                bookTypeOne = 'eBook'
            }
            var lastCartItemImage = this.props.cart[this.props.cart.length - 1][bookTypeOne].cover;
            var lastCartItemImageAlt = this.props.cart[this.props.cart.length - 1][bookTypeOne].coverAlt && this.props.cart[this.props.cart.length - 1][bookTypeOne].coverAlt;
        }

        let bookTypeTwo;
        if (this.props.cart.length > 1) {
            if (this.props.cart[this.props.cart.length - 2].audioBook) {
                bookTypeTwo = 'audioBook'
            } else {
                bookTypeTwo = 'eBook'
            }
            var beforeLastCartItemImage = this.props.cart[this.props.cart.length - 2][bookTypeTwo].cover;
            var beforeLastCartItemImageAlt = this.props.cart[this.props.cart.length - 2][bookTypeTwo].coverAlt && this.props.cart[this.props.cart.length - 2][bookTypeTwo].coverAlt;
        }

        let totalCostShoppingBag = this.props.location && this.props.location.state && this.props.location.state.totalCostShoppingBag

        let paymentAmount = 0
        if (this.props.cart) {
            if (this.props.cart.length) {
                this.props.cart.forEach((book) => {
                    let bookInfo                    
                    if (book.audioBook) {
                        bookInfo = book.audioBook
                    } else {
                        bookInfo = book.eBook
                    }
                    paymentAmount = paymentAmount + bookInfo.price
                })
            }
        }

        let paymentAmountBookDiscount = paymentAmount * ((100 - this.props.discount) / 100)
        const paymentAmountBookDiscountCeil = Math.ceil(paymentAmountBookDiscount);

        const appearance = {
            theme: 'stripe',
        };
        const customFonts = [{
            src: 'url(https://ed-spiracle-public.s3.amazonaws.com/fonts/gill-sans-nova-book.ttf)',
            family: 'Gill Sans Nova',
            style: 'normal',            
            weight: '400',
        }];

        const options = {
            appearance,
            fonts: customFonts
        };

        let membership = (this.props.location && this.props.location.state && this.props.location.state.subscription) || this.props.xmas;
        let subscription = this.props.location && this.props.history.location.state && this.props.history.location.state.subscription;
        let updateCard = this.props.location && this.props.location.state && this.props.location.state.update;
        const xmasPlan = this.props.xmasPlan;

        let amountMembership;
        let amountMembershipFixed;
        let nameMembership;
        let typeMembership;
        let priceMembership;

        if (xmasPlan) {
            subscription = Object.values(subscriptionPlans).find((item) => item.plan === xmasPlan);
        }

        if (subscription) {
            amountMembership = subscription.price * ((100 - this.props.discount) / 100);
            amountMembershipFixed = amountMembership.toFixed(2);
            nameMembership = subscription.name;
            typeMembership = subscription.type2.toLowerCase();
            priceMembership = subscription.price;
        }

        return (
            <>
            {this.props.promotion ? null :
                <PageMeta>
                    <title>Payment | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
            } 
            <StyledPayment oneImage={this.props.cart.length === 1 || membership} membership={membership} promotion={this.props.promotion}>
                {!updateCard && !this.props.freeTrial && 
                <Background>
                    <PageTitle>Checkout</PageTitle>
                    {this.props.membershipPromo ? null : 
                        membership ? (
                            this.props.xmasGift ? (
                                <h2>Give a Spiracle Membership</h2>
                            ) : 
                            <>
                                {/* <Cover>
                                    <img src={membershipImage} alt='Spiracle logo' />
                                </Cover> */}

                                <Subtitle>Spiracle Premium Membership</Subtitle>

                                <Title>{nameMembership}</Title>
                                
                                <Amount className={`${this.props.discount && !this.state.isLoadingDiscount ? 'old-price' : ''}`}><span>£</span>
                                    {`${priceMembership} / ${typeMembership}`}
                                </Amount>
                                {this.props.discount && !this.state.isLoadingDiscount ? (
                                    <Amount className={`${this.props.discount ? 'new-price' : ''}`}><span>£</span>
                                        {`${amountMembershipFixed} / ${typeMembership}`}
                                    </Amount>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <Cover>
                                    {this.props.cart.length > 1 && <img src={beforeLastCartItemImage} alt={beforeLastCartItemImageAlt} />}
                                    {this.props.cart.length > 0 && <img src={lastCartItemImage} alt={lastCartItemImageAlt} />}
                                </Cover>

                                {this.props.user && this.props.user.currentSubscription && !this.props.discount && (paymentAmount !== totalCostShoppingBag) ? (
                                    <>
                                        <Amount className='old-price'><span>£</span>{paymentAmount}</Amount>
                                        <Amount className='new-price'><span>£</span>{totalCostShoppingBag}</Amount>
                                    </>
                                ) : null}

                                {this.props.user && !this.props.user.currentSubscription && !this.props.discount ? (
                                    <Amount><span>£</span>{paymentAmount}</Amount>
                                ) : null}

                                {this.props.user && this.props.user.currentSubscription && this.props.discount && (paymentAmount !== totalCostShoppingBag) ? (
                                    <>
                                        <Amount className='old-price'><span>£</span>{paymentAmount}</Amount>
                                        <Amount className='old-price'><span>£</span>{totalCostShoppingBag}</Amount>
                                    </>
                                ) : null}

                                {!this.props.user ? (
                                    <>
                                        <Amount className={`${this.props.discount ? 'old-price' : ''}`}><span>£</span>{paymentAmount}</Amount>
                                    </>
                                ) : null}

                                {this.props.discount ? (
                                    <>
                                        {this.props.user && !this.props.user.currentSubscription && <Amount className='old-price'><span>£</span>{paymentAmount}</Amount>}
                                        <Amount className='new-price'><span>£</span>{paymentAmountBookDiscountCeil}</Amount>
                                    </>
                                ) : null}
                            </>
                        )
                    }

                    {/* {this.props.xmas && <Select className='mb-30' label='Membership type*'  />} */}
                    {this.props.xmas && <ChooseMembershipType className='mb-40' />}
                    {!updateCard && <Discount pullDiscount={this.pullDiscount} membership={membership || this.props.membershipPromo || this.props.xmas} plan={subscription?.plan} membershipPromoCode={this.props.membershipPromoCode} />}
                </Background>
                }
                    <Elements 
                        options={options} 
                        stripe={this.stripePromise} 
                    >
                        <CheckoutForm 
                            user={this.props.user} 
                            cartUuid={Cookies.get('cartId')} 
                            membershipPromo={this.props.membershipPromo} 
                            membershipPromoCode={this.props.membershipPromoCode} 
                            xmas={this.props.xmas}
                            xmasGift={this.props.xmasGift}
                            xmasPlan={this.props.xmasPlan}
                            cart={this.props.cart}
                            isLoadingDiscount={this.state.isLoadingDiscount}
                            freeTrial={this.props.freeTrial}
                        />
                    </Elements> 
            </StyledPayment>
            </>
        )
    }
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart,
    user: state.userReducer.user,
    discount: state.discountReducer.relativeDiscount,
  })

export default connect(mapStateToProps)(withRouter(Payment));