import styled  from "styled-components/macro";

export const Image = styled.div`
`;

export const StyledAudioBook = styled.div`
    min-width: 320px;
    width: 320px;
    margin: auto;
    background: ${(props) => props.showBg && '#F9F7ED'};
    box-shadow: ${(props) => props.showBg && '0 4px 4px rgba(0, 0, 0, 0.1)'};
    padding: ${(props) => props.showBg ? '35px 45px' : '0 45px'};
    margin-bottom: ${(props) => props.showBg ? '0' : '-40px'};

    ${(props) => props.theme.breakpoints.up('lg')} {
        margin: initial;
        min-width: 330px;
        width: 330px;
        margin-bottom: 0;
    }

    ${(props) => props.theme.breakpoints.up('xl')} {
        padding: ${(props) => props.showBg ? '55px 75px' : '0 75px'};
        min-width: 600px;
        width: 600px;
    }

    ${Image} {
        margin-bottom: 40px;
    }

    img {
        display: block;
        width: 100%;
    }
    
    h2 {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.03em;
        margin-bottom: 0;
    }
`