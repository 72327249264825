import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { StyledBanner } from './style';

function BannerPaymentFailed({className}) {
    const [show, setShow] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState();

    const user = useSelector(state => state.userReducer.user);

    useEffect(() => {
        const hasCurrentSubscription = user?.currentSubscription;
        const subscriptionFailed = user?.subscriptions && user.subscriptions['hydra:member'].find((item) => item.paymentFailed);
        const showBanner = subscriptionFailed ? true : false;

        if (!hasCurrentSubscription) {
            setSubscriptionId(subscriptionFailed?.id)

            setShow(showBanner);

            if (showBanner) {
                document.body.classList.add('show-banner-payment-failed');
            } else {
                handleHide();
            }
        } else {
            handleHide();
        }
    }, [user?.subscriptions, user?.currentSubscription])

    const handleHide = () => {
        setShow(false);
        document.body.classList.remove('show-banner-payment-failed');
    }

    return (
        show ?
            <StyledBanner className={`${className} font-secondary`}>
                <i className='icon-alert font-22 mr-5'></i>
                <span>Your subscription payment failed. To continue using Spiracle Premium, please 
                    <Link 
                        id='gtm-banner-update-payment-details' 
                        to={{
                            pathname: '/payment',
                            state: { update: 'card', id: subscriptionId }
                        }} 
                        className='font-secondary ml-5'
                    >
                        update your payment details.
                    </Link>
                </span>
                <i id='gtm-close-banner-payment-failed' className='icon-close font-22' onClick={handleHide}></i>
            </StyledBanner> : null
    )
}

export default BannerPaymentFailed