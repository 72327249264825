import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import PageMeta from '../../shared/PageMeta/PageMeta';

import AudioBook from 'components/UI/Redemption/AudioBook/AudioBook';

import { Buttons, Paragraph, ParagraphSmall,  StyledContent, StyledRedemptionContainer, Title } from './style';


class RedemptionConfirmation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            spisin: this.props.match.params.spisin
        }
    }

    componentDidMount() {
        if (!this.props.redemptionAudioBook?.spiSIN) {
            this.props.history.push(`/redemption/${this.state.spisin}`)
        }
    }

    render() {
        return (
            <StyledRedemptionContainer>
                <PageMeta>
                    <title>Redemption Success | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

               <AudioBook data={this.props.redemptionAudioBook} showBg/>

                <StyledContent>
                    <Title>Congratulations</Title>
                    <Paragraph>{this.props.redemptionAudioBook?.title} <span className='font-secondary'>is ready for you</span>.</Paragraph>

                    <Buttons>
                        <Link 
                            id='gtm-redemption-login'
                            to={`/redemption/${this.state.spisin}/login`}
                            className='c-btn c-btn__black font-secondary font-14 letter-spacing-15 font-uppercase text-center'>
                                Log in
                        </Link>
                        <Link 
                            id='gtm-redemption-signup'
                            to={`/redemption/${this.state.spisin}/signup`}
                            className='c-btn c-btn__ghost font-secondary font-14 letter-spacing-15 font-uppercase text-center'>
                                Create an account
                        </Link>
                    </Buttons>

                    <ParagraphSmall className='mt-50'>
                        Your online Library <i className='icon-library font-14 font-black'></i> stores all your audiobooks.
                        You can listen to them directly via the online Spiracle Player, or download the Spiracle Player App for <span>iOS</span> or <span>Android</span>. 
                        Happy listening!
                        Grow your collection with new audiobooks from {this.props.redemptionBookshop} and Spiracle Audiobooks.</ParagraphSmall>
                </StyledContent>
            </StyledRedemptionContainer>
        )
    }
}

const mapStateToProps = state => ({
    redemptionAudioBook: state.redemptionReducer.redemptionAudioBook,
    redemptionCode: state.redemptionReducer.redemptionCode.code,
    redemptionBookshop: state.redemptionReducer.redemptionCode.bookshop,
})

export default connect(mapStateToProps)(RedemptionConfirmation);