import { Link } from 'react-router-dom';
import classes from './ContactUs.module.scss';

function ContactUs() {
    return (
        <div className={`${classes.ContactUs} h-flex`}>
            <h2 className='font-36 font-italic mb-30'>Have more questions?</h2>
            <Link id='gtm-subscription-page-contact-us' to='/info/support' className='sa-button-red sa-button-rounded'>Contact us</Link>
        </div>
    )
}
export default ContactUs