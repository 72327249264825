import React, { Component } from "react";
import HeaderRetailer from 'layout/Header/HeaderRetailer';
import PropTypes from "prop-types";

class LayoutRetailer extends Component {
    componentDidMount() {
        document.body.classList.remove('show-banner');
    }
    
    render() {
        const { children } = this.props;

        return (
            <>
                <HeaderRetailer />
                <main className="full-height">
                    {children}
                </main>
            </>
        );
    }
}

LayoutRetailer.propTypes = {
    children: PropTypes.any,  
};


export default LayoutRetailer
