import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import DownloadIcons from 'shared/DownloadIcons/DownloadIcons';

import AudioBook from 'components/UI/Redemption/AudioBook/AudioBook';

import { Buttons, Paragraph, ParagraphSmall,  StyledContent, StyledRedemptionContainer, Title } from './style';

function RedeemAudioBookWelcome() {
    const user = useSelector(state => state.userReducer.user);
    const redemptionState = useSelector(state => state.redemptionReducer);
    const redemptionAudioBook = redemptionState.redemptionAudioBook;
    const redemptionBookshop = redemptionState.redemptionCode.bookshop;
    const code = redemptionState.redemptionCode.code;

    const history = useHistory();

    useEffect(() => {
        if (!code) {
            history.push('/redemption')
        }
    }, [history, code])

    return (
        <StyledRedemptionContainer>
            <AudioBook data={redemptionAudioBook} showBg />

                <StyledContent>
                    <Title>Welcome {user?.firstName}</Title>
                    <Paragraph>{redemptionAudioBook?.title} <span className='font-secondary'>is now in your Library. Just tap on the title when you’re ready to listen</span>.</Paragraph>

                    <ParagraphSmall>Most Spiracle Editions are available from {redemptionBookshop} and <span>other bookshops around the UK</span>. 
                        When you purchase in this way, we split the income between the bookshop and the publisher.
                        If you purchase audiobooks directly from Spiracle, we split the income with the publisher. 
                    </ParagraphSmall>

                    <Buttons className='mt-50'>
                        <Link id='gtm-redemption-welcome-library' to='/library' className="c-btn c-btn__ghost font-secondary font-14 letter-spacing-15 font-uppercase text-center">View your library <i className='icon-library font-12 font-black'></i></Link>
                    </Buttons>

                    <ParagraphSmall className='mt-50 mb-15'><span>Explore More Titles</span>: Spiracle offers a curated collection of audiobooks from literary publishers. </ParagraphSmall>
                    <Buttons>
                        <Link id='gtm-redemption-welcome-collection' to='/audiobooks' className="c-btn c-btn__ghost font-secondary font-14 letter-spacing-15 font-uppercase text-center mb-50">View the collection</Link>
                    </Buttons>

                    <DownloadIcons />

                    <ParagraphSmall className='mt-10 mb-15'>Discover new voices, free audiobooks, articles, and events in Spiracle’s Murmurations area.</ParagraphSmall>
                    <Buttons>
                        <Link id='gtm-redemption-welcome-murmurations' to='/murmurations' className="c-btn c-btn__ghost font-secondary font-14 letter-spacing-15 font-uppercase text-center">Murmurations</Link>
                    </Buttons>
                </StyledContent>
                
            </StyledRedemptionContainer>
    )
}

export default RedeemAudioBookWelcome;