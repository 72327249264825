import styled from "styled-components/macro";

export const StyledDiscount = styled.div`
    > button {
        border: none;
        outline: none;
        background: transparent;
        display: block;
        width: max-content;
        margin: 0 auto;
        cursor: pointer;

        &.show {
            i {
                transform: rotate(180deg);
            }
        }

        span {
            text-transform: uppercase;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 2px;
            text-decoration: underline;
        }

        i {
            font-weight: 700;
            pointer-events: none;
        }
    }

    > div {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 15px;
        margin-top: 20px;

        ${(props) => props.theme.breakpoints.down("sm")} {
            flex-direction: column;
            align-items: center;
        }

        input {
            width: 320px;
        }

        button {
            font-style: italic !important;
            font-weight: 500 !important;
            padding-left: 30px !important;
            padding-right: 30px !important;
            background: transparent;
            white-space: nowrap;
            cursor: pointer;

            &[disabled] {
                pointer-events: none;
            }
        }
    }
`;

export const PromotionDiscountText = styled.div`
    height: 51px;
    text-align: center;
    font-size: 18px;
`;