import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import ReactHtmlParser from 'react-html-parser';

import AudioBookListItem from 'components/UI/AudioBook/AudioBookListItem';

import { StyledIntro, Subtitle, Title, Paragraph, Buttons, StyledSlider, Slide, Audiobook, Content, Description } from './style';

function Intro({ data, title }) {
    const user = useSelector(state => state.userReducer.user);
    const subscription = user?.currentSubscription;

    const settings = {
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        swipeToSlide: true,
        dots: true,
    };

    return (
        <StyledIntro>
            <div>
                <Subtitle className='font-uppercase'>The sound of great writing</Subtitle>
                <Title className='font-italic'>{ReactHtmlParser(title)}</Title>
                <Paragraph>
                    {subscription ? 
                        'Explore this month’s Current Highlights, or gift a Premium Membership to your family and loved ones!' : 
                        user ?
                            'Purchase individual audiobooks or upgrade to Premium Membership which includes free audiobooks every month, discounted prices, special events and more!' :
                                'Sign up for a free account and discover audiobooks, articles and podcasts. Join the Spiracle community of book lovers. Buy individual audiobooks or upgrade to Premium Membership which includes free audiobooks every month, discounted prices, special events and more!'}
                </Paragraph>

                <Buttons>
                    {subscription ? 
                        <>
                            <Link id='gtm-homepage-browse-audiobooks-has-subscription' to='/browse' className='sa-button-red sa-button-rounded'>Browse audiobooks</Link>
                            <Link id='gtm-homepage-gift-membership-has-subscription' to='/gift?plan=3' className='sa-button-ghost sa-button-rounded'>Gift Premium Membership</Link>
                        </> : user ?
                            <>
                                <Link id='gtm-homepage-browse-audiobooks-user' to='/browse' className='sa-button-red sa-button-rounded'>Browse audiobooks</Link>
                                <div>
                                    <Link id='gtm-homepage-trial-user' to='/free-trial' className='sa-button-ghost sa-button-rounded'>Start 30-day Premium trial</Link>
                                    {/* <p className='font-11 font-secondary font-uppercase text-center mt-7 mb-0'>No credit card required</p> */}
                                </div>
                            </> :  
                                <>
                                    <Link id='gtm-homepage-join-spiracle-for-free' to='/subscription' className='sa-button-red sa-button-rounded'>Discover membership</Link>
                                    <div>
                                        <Link id='gtm-homepage-trial' to='/free-trial' className='sa-button-ghost sa-button-rounded'>Start 30-day Premium trial</Link>
                                        {/* <p className='font-11 font-secondary font-uppercase text-center mt-7 mb-0'>No credit card required</p> */}
                                    </div>
                                </>}
                </Buttons>
            </div>


        {data?.length?
            <StyledSlider>
                <Slider {...settings}>
                    {data?.map((item, index) => (
                        item.visible ? <Slide key={`item-${index}`}>
                            <Audiobook>
                                <AudioBookListItem 
                                    title={item.audioBook?.title}
                                    authors={item.audioBook?.authors}
                                    imgSrc={item.audioBook?.cover}
                                    imgAlt={item.audioBook?.coverAlt}
                                    price={item.audioBook?.price}
                                    subscriptionPrice={item.audioBook?.subscriptionPrice}
                                    eBook={item.audioBook?.eBook}
                                    link={`${item.audioBook?.entityType}/${item.audioBook?.slug}`} 
                                    horizontal={window.innerWidth < 768 ? true : false}
                                    availableAsCard={item.audioBook?.availableAsCard}
                                />
                            </Audiobook>
                            <Content className='text-center'>
                                <Subtitle className='font-uppercase'>{item.title}</Subtitle>
                                <Description>
                                    {ReactHtmlParser(item.description)}
                                </Description>
                                <Subtitle className='font-uppercase'>
                                    – {item.author}
                                </Subtitle>
                            </Content>
                        </Slide> : null
                    ))}
                </Slider>
            </StyledSlider>
            :null
        }
        </StyledIntro>
    )
}

export default Intro;