import React, { Component } from 'react';
import { connect } from 'react-redux';

import Collapse from '@material-ui/core/Collapse';

import { debounce } from '../../../shared/Functions/Debounce';
import ApiService from '../../../services/api.service'
import MembershipGiftButton from './MembershipGiftButton';

import { RecipientDetails } from './style';

class SendMembershipAsGift extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            invalidName: true,
            nameError: false,
            message: '',
            emailError: '',
            invalidEmail: true,
            haveSubscription: (this.props.currentSubscription?.status === 'active' || this.props.currentSubscription?.status === 'trialing') ? true : false,
            checked: (this.props.currentSubscription?.status === 'active' || this.props.currentSubscription?.status === 'trialing' || this.props.xmasGift) ? true : false,
            alreadyHaveSubscriptionMessage: 'Already have a subscription'
        }
        this.debounceEmail = debounce(this.checkEmail, 400);
        this.debounceMessage = debounce(this.setMessage, 400);

        this.props.pullData({checked: this.state.checked, invalidEmail: this.state.invalidEmail, email: this.state.email, name: this.state.name, invalidName: this.state.invalidName, message: this.state.message})
    }

    checkEmail = () => {
        // check if valid email
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(regex.test(String(this.state.email).toLowerCase())) {
            const data = {
                email: this.state.email
            }

            ApiService.checkGiftSubscription(data).then(
                r => {
                    if (r.data.purchased) {
                        this.setState({
                            emailError: this.state.alreadyHaveSubscriptionMessage,
                            invalidEmail: true
                        }, () => {
                            this.props.pullData({checked: this.state.checked, invalidEmail: this.state.invalidEmail, email: this.state.email, name: this.state.name, invalidName: this.state.invalidName, message: this.state.message})
                        })
                    } else {
                        this.setState({
                            emailError: '',
                            invalidEmail: false
                        }, () => {
                            this.props.pullData({checked: this.state.checked, invalidEmail: this.state.invalidEmail, email: this.state.email, name: this.state.name, invalidName: this.state.invalidName, message: this.state.message})
                        })
                    }
                }
            )
        }
    }

    setEmail = e => {
        // check if valid email
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (e && e.target) {
            this.setState({
                email: e.target.value
            }, () => {
                if(regex.test(String(e.target.value).toLowerCase())) {
                    this.debounceEmail();
                }
            })
        }
    }
    
    setMessage = e => {
        this.setState({
            message: e.target.value
        }, () => {
            this.props.pullData({checked: this.state.checked, invalidEmail: this.state.invalidEmail, email: this.state.email, name: this.state.name, invalidName: this.state.invalidName, message: this.state.message})
        })
    }

    handleEmailBlur = e => {
        // check if valid email
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regex.test(String(e.target.value).toLowerCase())) {
            this.setState({
                emailError: 'Invalid email',
                invalidEmail: true
            }, () => {
                this.props.pullData({checked: this.state.checked, invalidEmail: this.state.invalidEmail, email: this.state.email, name: this.state.name, invalidName: this.state.invalidName, message: this.state.message})
            })
        } else {
            if (this.state.emailError !== this.state.alreadyHaveSubscriptionMessage) {
                this.setState({
                    emailError: '',
                    invalidEmail: false
                }, () => {
                    this.props.pullData({checked: this.state.checked, invalidEmail: this.state.invalidEmail, email: this.state.email, name: this.state.name, invalidName: this.state.invalidName, message: this.state.message})
                })
            }
        }
    }

    handleNameBlur = e => {
        this.setState({
            invalidName: e.target.value.length < 1 ? true : false,
            nameError: e.target.value.length < 1 ? true : false
        }, () => {
            this.props.pullData({checked: this.state.checked, invalidEmail: this.state.invalidEmail, email: this.state.email, name: this.state.name, invalidName: this.state.invalidName, message: this.state.message})
        })
    }

    handleEmailFocus = e => {
        if (this.state.emailError !== this.state.alreadyHaveSubscriptionMessage) {
            this.setState({
                emailError: ''
            })
        }
    }

    handleNameFocus = e => {
        this.setState({
            nameError: false,
        })
    }

    handleNameChange = e => {
        this.setState({
            name: e.target.value,
            invalidName: e.target.value.length < 1 ? true : false,
            nameError: e.target.value.length < 1 ? true : false,
        })
    }

    handleCheckboxChange = e => {
        // if you have subscription, you cannot uncheck it!
        if (!this.state.haveSubscription) {
            this.setState({
                checked: !this.state.checked
            }, () => {
                this.props.pullData({checked: this.state.checked, invalidEmail: this.state.invalidEmail, email: this.state.email, name: this.state.name, invalidName: this.state.invalidName, message: this.state.message})
            })
        }
    }

    render() {
        return (
            <RecipientDetails className="mb-30">
                {!this.props.xmasGift && this.state.haveSubscription ? 
                    <MembershipGiftButton 
                        checked={this.state.checked}
                        haveSubscription={this.state.haveSubscription}
                        onChange={() => this.handleCheckboxChange()} 
                    />
                    :
                    null
                }

                <Collapse in={this.state.checked} collapsedSize={0}>

                    <div>
                        <label>{this.props.xmasGift ? "Recipient's email*" : "Email address of recipient"}</label>
                        <input  
                            className={`bg-white ${this.state.emailError && 'invalid'}`}
                            type="eMail"
                            name="eMailReceiver"
                            id="eMailReceiver" 
                            onChange={(e) => this.setEmail(e)}
                            onBlur={(e) => this.handleEmailBlur(e)}
                            onFocus={(e) => this.handleEmailFocus(e)}
                            value={this.state.email}
                            autoComplete="off"
                        />       
                        <div className="c-error-message mt-5">
                            {this.state.emailError ? <div>{this.state.emailError}</div> : null}
                        </div>                                    
                    </div>

                    {this.props.xmasGift ? 
                        <div className='mt-20'>
                            <label>Recipient's first name*</label>
                            <input  
                                className={`bg-white ${this.state.nameError && 'invalid'}`}
                                type="text"
                                name="nameReceiver"
                                id="nameReceiver" 
                                onChange={(e) => this.handleNameChange(e)}
                                onBlur={(e) => this.handleNameBlur(e)}
                                onFocus={(e) => this.handleNameFocus(e)}
                                value={this.state.name}
                                autoComplete="off"
                            />
                            <div className='input-description'>We will address the recipient by this name</div>                                         
                        </div>
                        :
                        null
                    }
                    <div className="mt-20">
                        <label>Gift message {this.props.xmasGift ? <span className='label-optional'>(optional)</span> : null}</label>
                        <textarea 
                            className="bg-white"                        
                            name="Message"
                            id="message"                             
                            onChange={(e) => this.setMessage(e)}
                            value={this.state.message}                 
                        />                                                
                    </div>
                </Collapse>
            </RecipientDetails>
        )
    }
}

const mapStateToProps = state => ({
    currentSubscription: state.userReducer.user?.currentSubscription,
})

export default connect(mapStateToProps)(SendMembershipAsGift);
