import React, { Component } from 'react'

export default class SkeletonSubscription extends Component {
    render() {
        return (
            <div className="c-skeleton__subscription margin-center">
                {/* <div className="c-skeleton__subscription--top margin-center">
                    <div className="c-skeleton__subscription--header mt-5 margin-center"></div>
                    <div className="c-skeleton__subscription--buttons mt-35 margin-center">
                        <div className="c-skeleton__subscription--button"></div>
                        <div className="c-skeleton__subscription--button"></div>
                    </div>
                    <div className="c-skeleton__subscription--link mt-20 margin-center"></div>
                </div> */}
                <div className="c-skeleton__subscription--bottom margin-center">
                    <div className="c-skeleton__subscription--title mt-45"></div>
                    <div className="c-skeleton__subscription--subscription mt-30"></div>
                    <div className="c-skeleton__subscription--subscription mt-30"></div>
                </div>
            </div>
        )
    }
}