import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CoverImage from 'shared/CoverImage/CoverImage';
import Grid from "@material-ui/core/Grid";
import ReactHtmlParser from 'react-html-parser';
import { StyledEditions, StyledLink, DetailsHolder, TextHolder } from "components/UI/AudiobookEdition/style";
import AudioBookListItem from 'components/UI/AudioBook/AudioBookListItem';
import { Link } from "react-router-dom";

class AudiobookEditionItem extends Component {
    render() {
        return (
            <StyledEditions 
                style={this.props.style} 
                bookBgColor={this.props.bookBgColor}           
                containerBgColor={this.props.containerBgColor}
                textColor={this.props.textColor}
            >
                <Grid container>
                    <Grid item xs={12} lg={5} xl={6}>                                        
                        <StyledLink to={this.props.link}>
                            <CoverImage 
                                src={this.props.cover} 
                                alt={this.props.coverAlt} 
                                title={this.props.title}                                                 
                            />   
                        </StyledLink>                                     
                    </Grid>
                    <Grid item xs={12} lg={7} xl={6}>  
                        <DetailsHolder extended={this.props.extended}>                      
                            <TextHolder>{ReactHtmlParser(this.props.summary)}</TextHolder>                                                           
                            <AudioBookListItem                             
                                title={this.props.title}
                                authors={this.props.authors}
                                imgSrc={this.props.cover}
                                imgAlt={this.props.coverAlt}
                                price={this.props.price}
                                subscriptionPrice={this.props.subscriptionPrice}
                                link={this.props.link}
                                eBook={this.props.eBook}
                                isEdition
                                bookBgColor={this.props.bookBgColor}
                                containerBgColor={this.props.containerBgColor}
                                textColor={this.props.textColor}
                                horizontal
                                availableAsCard={this.props.availableAsCard}
                            /> 
                        </DetailsHolder>                                                
                    </Grid>
                    {this.props.extended && 
                        <Grid item xs={12}> 
                            <Link id='gtm-audiobook-edition-visit-spiracle-edition' to={"/editions"} className="c-btn c-btn__brown font-primary font-22">
                                Visit the Spiracle <span className="font-italic">edition</span>
                            </Link>
                        </Grid>
                    }
                </Grid>
            </StyledEditions>
        );
    }
}

AudiobookEditionItem.propTypes = {
    link: PropTypes.string,
    cover: PropTypes.string,
    coverAlt: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
};

export default AudiobookEditionItem;

