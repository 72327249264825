import PageMeta from 'shared/PageMeta/PageMeta';
import RedeemAudioBook from 'components/UI/Redeem/RedeemAudioBook';

function RedeemPage() {
    return (
        <>
            <PageMeta>
                <title>Redeem Audiobook | Spiracle</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>

            <RedeemAudioBook />
        </>
    )
}
export default RedeemPage