import styled  from "styled-components/macro";

export const CurrentCard = styled.div``;
export const Description = styled.span``;
export const User = styled.span``;

export const MembershipGiftButtonContainer = styled.div`
    text-align: center;

    input[type="checkbox"] {
        display: none;

        & + label {
            cursor: pointer;
            text-transform: none;
            letter-spacing: 0;
            margin: 0 0 35px;
            font-family: 'JJannon';
            font-size: 16px;

            i {
                font-size: 16px;
                margin-left: 10px;

                &.icon-checked {
                    display: none;
                }

                &.icon-not-checked {
                    display: inline-block;
                }
            }
        }

        &:checked {
            & + label {
                i {
                    &.icon-checked {
                        display: inline-block;
                    }

                    &.icon-not-checked {
                        display: none;
                    }
                }
            }
        }
    }
`

export const Title = styled.div`
    position: relative;
    font-family: 'Gill Sans Nova';
    max-width: calc(100% - 27px);
    display: flex;
    cursor: pointer;
    transition: .3s ease;

    ${(props) => props.theme.breakpoints.down("md")} {
        flex-wrap: wrap;
    }

    &:hover {
        color: #721b00;
    }

    &.show {
        .icon-arrow {
            transform: translateY(-50%) rotateZ(90deg);
        }
    }

    .icon-arrow {
        position: absolute;
        top: 50%;
        right: -27px;
        transform: translateY(-50%);
        transition: .3s ease;
    }

    ${Description} {
        min-width: max-content;
        margin-right: 8px;
    }

    ${User} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const Details = styled.div`
    max-height: 0;
    overflow: hidden;
    transition: .3s ease;

    &.show {
        max-height: 600px;
    }

    .old-price {
        text-decoration: line-through;
    }

    ${CurrentCard} {
        letter-spacing: 0.10em;
    }
`;

export const RecipientDetails = styled.div`
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
`;

/**
 * Choose Membership type
 */
export const Name = styled.span``;
export const Price = styled.span``;
export const Type = styled.span``;

export const StyledChooseMembershipType = styled.div`
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;

    > label {
        display: block;
        font-family: 'Gill Sans Nova';
        font-size: 12px;
        font-weight: 400;
        line-height: 17.21px;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 16px;
    }
`;

export const RadioContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6px;
`;

export const Radio = styled.div`
    position: relative;
    border: 1px solid #000;
    height: 88px;

    &:nth-of-type(7) {
        grid-column: 1/3;
    }

    input {
        display: none;

        &:checked + label {
            background: #46507C;
            color: #fff;

            &::before {
                content: '';
                position: absolute;
                bottom: 8px;
                right: 8px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #F9F5E9;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 16px;
                right: 12px;
                width: 10px;
                height: 5px;
                border-bottom: 2px solid #46507C;
                border-left: 2px solid #46507C;
                transform: rotate(-45deg);content: '';
                position: absolute;
                bottom: 16px;
                right: 12px;
                width: 10px;
                height: 5px;
                border-bottom: 2px solid #46507C;
                border-left: 2px solid #46507C;
                transform: rotate(-45deg);
            }

            ${Type} {
                color: #fff;
            }
        }
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px 14px;
        cursor: pointer;
    }

    ${Name} {
        font-size: 24px;
        font-style: italic;
        letter-spacing: 0.72px;
    }

    ${Price} {
        font-family: 'Gill Sans Nova';
        font-size: 24px;
        font-weight: 300;
    }

    ${Type} {
        color: #730000;
        font-family: 'Gill Sans Nova';
        font-size: 18px;
        font-weight: 300;
        text-transform: lowercase;
        margin-left: 3px;
    }
`;