import styled from "styled-components/macro";

export const Title = styled.div``;
export const ImageHolder = styled.div``;
export const ContentHolder = styled.div``;
export const Author = styled.div``;
export const Price = styled.div``;
export const Gift = styled.div``;
export const Discount = styled.div``;

export const StyledAudiobookItem = styled.div`
    font-family: Gill Sans Nova;
    background-color: ${(props) => props.isEdition ? props.bookBgColor ? props.bookBgColor : "#F3EAE3" : "#f9f7ed"};
    color: ${(props) => props.isEdition ? props.textColor ? props.textColor : "#721b00" : "#000000"};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;   
    padding: ${(props) => props.horizontal ? "5px" : "6px"};    
    width: 100%;
    display: flex;
    flex-direction: ${(props) => props.horizontal ? "row" : "column"};
    transition: .3s ease-in-out;
    position: relative;
    opacity: ${(props) => props.disabled &&  '60%' };
    pointer-events: ${(props) => (props.disabled || props.promotion)  && 'none'};

    ${(props) => props.theme.breakpoints.up("md")} {        
        ${props => {
            if (!props.mobileOnly) {
                return `
                    padding: ${props.horizontal ? "6px" : "6px 6px 1px"};
                `
            }
        }}    
    }

    &.carousel-book-item {
        width: 96.64%;
        max-height: 500px;

        ${(props) => props.theme.breakpoints.down("sm")} {        
            width: 100%;
        }

        &--full-width {
            width: 100%;
        }

        &--slider {
            ${(props) => props.theme.breakpoints.down("sm")} {        
                width: calc(100% - 6px);
            }
        }

        &--only-one {
            ${(props) => props.theme.breakpoints.down("sm")} {        
                max-width: 171px;
                max-height: 305px;
                margin: 0 auto;
            }
        }
    }

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);    
    }  
    
    ${ImageHolder} {
        flex: ${(props) => props.horizontal ? "110px 0 0" : null};

        ${(props) => props.theme.breakpoints.up("md")} {            
            ${props => {
                if (!props.mobileOnly) {
                    return `
                        flex: ${props.horizontal ? "140px 0 0" : null};
                    `
                }
            }}    
        }
    }

    ${ContentHolder} {
        position: relative;  
        height: ${(props) => !props.horizontal && "145px"};
        display: flex;
        flex-direction: column;
        justify-content: space-between;           
        padding: ${(props) => props.horizontal ? "0 10px 6px 15px" : "7px 12px 4px"};   
        text-align: left;
        min-width: ${(props) => props.horizontal && "0"};   
        flex:  ${(props) => props.horizontal && "1 0 0"};       

        ${(props) => props.theme.breakpoints.up("md")} {            
            ${props => {
                if (!props.mobileOnly) {
                    return `
                        display: block; 
                        height: ${!props.horizontal && "160px"};                              
                        padding: ${props.horizontal ? "0 12px 6px 20px" : "20px 12px 12px"}; 
                        
                   `
                }
            }}    
        }
    }

    ${Title} {
        font-family: Gill Sans Nova;    
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.03em;                   
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        padding-top: ${(props) => props.horizontal && "3px"};     
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
        width: ${(props) => props.horizontal && !props.mobileOnly && props.isFree && "calc(100% - 35px)"};   
        
        ${(props) => props.theme.breakpoints.up("md")} {
            ${props => {
                if (!props.mobileOnly) {
                    return `
                        font-size: 20px;
                        line-height: 26px;
                        letter-spacing: 0.02em;
                        flex: ${props.horizontal ? "none" : "35px 0 0"};             
                        text-align: left;
                        white-space: nowrap;
                        display: block;
                    `
                }
            }}            
        }
    }

    ${Author} {
        font-size: 14px;
        font-style: italic;
        line-height: 17px;
        letter-spacing: 0.03em;
        position: absolute;
        top: ${(props) => props.horizontal ? "45px" : "51px"};
        width: ${(props) => props.horizontal && !props.mobileOnly && props.isFree ? "calc(100% - 60px)" : "calc(100% - 24px)"};            
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 0 3px 3px; // fix eaten letter
        margin: 0 0 -3px -3px; // fix eaten letter

        ${(props) => props.theme.breakpoints.up("md")} {
            ${props => {
                if (!props.mobileOnly) {
                    return `
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: 0.02em;
                        flex: ${!props.horizontal && "35px 0 0"};
                        margin: ${!props.horizontal ? "16px 0 18px" : "25px 0 27px"};
                        position: static;
                    `
                }
            }}         
        }

        @media screen and (min-width: 1260px) {
            margin: ${(props) => props.mobileOnly ? '0 0 18px' : '25px 0 27px'}; 
        }
    }

    ${Price} {
        display: ${(props) => props.horizontal ? "flex" : "block"};
        flex-direction: ${(props) => props.horizontal ? "row" : "column"};
        font-size: 16px;
        line-height: 24px;        
        font-weight: 300;      
        width: 100%;
        transition: .3s ease-in-out;

        ${(props) => props.theme.breakpoints.up("sm")} {
            font-size: ${(props) => props.horizontal ? '16px' : props.mobileOnly ? '18px' : '20px'};
        }

        ${(props) => props.theme.breakpoints.down("sm")} {
            font-size: 15px;
        }

        div + div {                
            margin: ${(props) => props.horizontal ? "0 0 0 20px" : "10px 0 0"};
        }

        .old-price {
            text-decoration: line-through;
        }

        .bracket {
            vertical-align: text-top;
        }

        .price-with-subscription {
            color: #721b00;
            display: ${(props) => props.horizontal ? 'inline' : 'block'};

            &::before {
                content: ${(props) => props.horizontal ? '"/"' : '""'};
                margin-left: ${(props) => props.horizontal ? '6px' : '0'};
                margin-right: ${(props) => props.horizontal ? '3px' : '0'};
            }

            @media screen and (min-width: 1260px) {
                display: ${(props) => props.mobileOnly && !props.horizontal ? 'block' : 'inline'};

                &::before {
                    content: ${(props) => props.mobileOnly && !props.horizontal ? '""' : '"/"'};
                    margin-left: ${(props) => props.mobileOnly  && !props.horizontal? '0' : '6px'};
                    margin-right: ${(props) => props.mobileOnly && !props.horizontal ? '0' : '3px'};
                }
            }

            @media screen and (max-width: 376px) {
                font-size: 15px;
            }

            span {
                &:nth-of-type(2) {
                    font-size: 12px;

                    @media screen and (max-width: 570px) {
                        font-size: 10px;
                    }
                }
            }
        }

        i {
            font-size: 14px;
            margin-right: 8px;

            ${(props) => props.theme.breakpoints.down("sm")} {
                font-size: 12px;
            }
        }

        ${(props) => props.theme.breakpoints.up("md")} {    
            ${props => {
                if (!props.mobileOnly) {
                    return `
                        display: flex;
                        flex-direction: row;
                        font-size: 20px;           
                        line-height: 24px;
                        flex: 0 0 25px;            

                        div + div {
                            margin-left: 23px;
                            margin-top: 0;
                        }

                        i {
                            font-size: 16px;
                            
                        }
                    `
                }
            }}                      
        }
    }

    ${Gift} {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    ${Discount} {
        position: absolute;
        bottom: 0px;
        right: 0px;
    }
`;


