import React, { Component } from 'react';
import { DialogContainer, StyledConfirmationDialog, StyledChangeSubscriptionDialog } from './style';
import handApple from '../../images/hand-apple.webp';

class ConfirmationDialog extends Component {
    render() {
        return (
        this.props.changeSubscription ? 
            <StyledConfirmationDialog className='popup-content'>                
                <StyledChangeSubscriptionDialog className='c-popup'>
                    <i onClick={this.props.handleClose} className="icon-close-small"></i>

                    <h2>Change your subscription</h2>

                    <h3>Monthly subscriber</h3>

                    <p>
                        As a monthly subscriber, you can change your subscription at any time. 
                        Be sure to have selected your audiobook choices for the current month and then, go to your Account and <span>cancel your current subscription</span>. 
                        You will receive an email confirming you have cancelled this subscription. 
                        You can <span>then start a new subscription</span> at the payment level and frequency that you prefer.
                    </p>

                    <button className='c-btn c-btn__ghost c-btn__ghost--square' onClick={this.props.onClickFirstButton}>Go to your Account</button>

                    <h3>Annual subscriber</h3>

                    <p>
                        As an Annual Subscriber, your subscription is set for a year. 
                        If you wish to upgrade to the next level, please contact us <br />
                        via <a href="mailto:support@spiracleaudiobooks.com">support@spiracleaudiobooks.com</a>
                    </p>
                    
                </StyledChangeSubscriptionDialog>
            </StyledConfirmationDialog>
            : this.props.cancelSubscription ? 
            <StyledConfirmationDialog className='popup-content'>                
                <StyledChangeSubscriptionDialog className='c-popup'>
                    <i onClick={this.props.handleClose} className="icon-close-small"></i>

                    <h2>Are you sure you want to <br /> Cancel your current <br /> Premium Membership?</h2>

                    <p>
                        Your current Premium Membership will run its full course whether annual or monthly to the end of the current payment term. <br />
                        You can rest assured that it will not automatically renew. <br />
                        However, when it is complete, you will be able to change your Membership to a different tier. <br />
                        Meanwhile, please confirm you would like to cancel your current Premium Membership. <br />
                    </p>

                    <button className='c-btn c-btn__ghost c-btn__ghost--square' onClick={this.props.onClickFirstButton}>Cancel Subscription</button>

                    <p>
                        If you have any questions, please do not hesitate to contact us. <br />
                        <a href="mailto:support@spiracleaudiobooks.com">support@spiracleaudiobooks.com</a>
                    </p>
                </StyledChangeSubscriptionDialog>
            </StyledConfirmationDialog> :
            <StyledConfirmationDialog className="popup-content" withImage={this.props.withImage}>                
                <DialogContainer className={`c-popup ${this.props.deleteAccount && "c-popup--delete-account"}`}>
                    <i onClick={this.props.handleClose} className="icon-close-small"></i>
                    <div className="c-popup__header">
                        <h3 className={`font-secondary font-12 font-400 ${this.props.withImage ? 'mb-8' : 'mb-30'} text-center letter-spacing-15 font-uppercase`}>{this.props.title}</h3>
                    </div>
                    {this.props.withImage && <div>
                        <img src={handApple} alt="" />
                    </div>}
                    <div>
                        <p>{this.props.text}</p>
                    </div>
                    <div className={`c-popup__action ${this.props.deleteAccount && "c-popup__action--delete-account"}`}>
                        <button
                            id={`${this.props.deleteAccount ? 'gtm-settings-dialog-delete-account-yes' : this.props.audioBook ? 'gtm-audiobook-dialog-add-to-library' : 'gtm-subscription-dialog-cancel-subscription'}`}
                            onClick={this.props.onClickFirstButton} 
                            className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase ${this.props.firstButtonPrimary && 'c-btn__ghost--square--full'}`}
                        >
                            {this.props.firstButtonLabel}
                        </button>
                        {this.props.secondButtonLabel && <button 
                            id={`${this.props.deleteAccount ? 'gtm-settings-dialog-delete-account-cancel' : this.props.audioBook ? 'gtm-audiobook-dialog-cancel' : ''}`}
                            onClick={this.props.onClickSecondButton} 
                            className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase ${this.props.secondButtonPrimary && 'c-btn__ghost--square--full'}`}
                        >
                            {this.props.secondButtonLabel}
                        </button>}
                    </div>
                    {this.props.deleteAccount && <div className="c-popup__quote">
                        {this.props.quote}
                    </div>}
                </DialogContainer>
            </StyledConfirmationDialog>
        );
    }
}

export default ConfirmationDialog;