import CardActivation from 'components/UI/Retailer/CardActivation/CardActivation'
import PageMeta from 'shared/PageMeta/PageMeta'

function CardActivationPage() {
    return (
        <>
            <PageMeta>
                <title>Activate Card | Spiracle</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>
            <CardActivation />
        </>
    )
}
export default CardActivationPage